import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  useMediaQuery, Card, CardContent, Box, Tooltip, Divider, Breadcrumbs, Link
} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import api from "../../../../../../services/api";
import useStyles from "../../../../../../style/style";
import {CharmHome} from "../../../../../../icons/Icons";
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomSelect from "../../../../../../components/CustomSelect";
import BreadcrumbsComponent from "../../../../../../components/BreadcrumbsComponent/BreadcrumbsComponent";
import SearchFilterQuestion from "../../../../../../components/SearchFilterQuestion/SearchFilterQuestion";

const useStylesLocal = makeStyles(theme => ({
  root: {
  },
  textField: {
    marginLeft: theme.spacing(1),
  },
  subtitle: {
    padding: '16px',
    fontSize: '15px',
  },
}));

const QuestionToolbar = props => {
  const { className, onClickSearch, searchText,
                        history, ...rest } = props;
  const [courses, setCourses] = useState([]);
  const [objects, setObjects] = useState([]);
  const [courseSelect, setCourseSelect] = useState([]);
  const [objectSelect, setObjectSelect] = useState([]);
  const [typeEvaluationSelect, setTypeEvaluationSelect] = useState([]);
  const [typeEvaluation, setTypeEvaluation] = useState([]);
  const [yearSelect, setYearSelect] = useState([]);
  const [years, setYears] = useState([]);
  /* S - suas questões
     T - todas as questões
   */
  const [valueSelect, setValueSelect] = React.useState('S');
  const [enableFilter, setEnableFilter] = React.useState(false);

  // Media query para verificar se a tela é menor que 600px (ponto de interrupção padrão)
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const breadcrumbItems = [
    { href: '/', icon: <CharmHome />, label: 'Início' },
    { label: 'Questões' }
  ];

  const optionsQuestionsPerPagge = [
    { id: 5, description: '5' },
    { id: 10, description: '10' },
    { id: 20, description: '20' },
    { id: 30, description: '30' },
    { id: 40, description: '40' },
    { id: 50, description: '50' },
    { id: 100, description: '100' },
  ];

  const onClickNewCourse = e => {
    history.push('/question-details');
  }

  const onChangeTypeOfEvaluation = (e) =>{
    setTypeEvaluationSelect(e.target.value);
    searchText[0] = {
      ...searchText[0],
      fk_type_of_evaluation_id: e.target.value
    };
  }

  const onChangeYear = (e) =>{
    setYearSelect(e.target.value);
    searchText[0] ={
        ...searchText[0],
        year: parseInt(e.target.value)
    };
  }


  const onChangeCourse = (e) =>{
    setCourseSelect(e.target.value);
    searchText[0] ={
        fk_course_id: e.target.value,
        fk_object_id: 0,
        fk_type_of_evaluation_id: 0,
        year: 0,
        totalPerPage: 10,
        value: searchText[0].value ? searchText[0].value : 'S'
    };
    if(e.target.value == 0){
      setObjects([]);
      setYears([]);
      setTypeEvaluation([]);
      setEnableFilter(false);
    } else {
      setEnableFilter(true);
    }
  }

  const onClean = () =>{
    setCourseSelect(0);
    searchText[0] ={
      fk_course_id: 0,
      fk_object_id: 0,
      fk_type_of_evaluation_id: 0,
      totalPerPage: 10,
      year: 0,
      value: "S"
    };
    setEnableFilter(false);
  }

  const onChangeObject = (e) =>{
    setObjectSelect(e.target.value);
    //searchText[0] = {"fk_object_id" : e.target.value};
    searchText[0] ={
        ...searchText[0],
        fk_object_id: e.target.value // Atualiza apenas o campo desejado
    };

  }

  async function loadCourses(){
    try {
      const response = await api.get('all/courses-user');
      setCourses([...response.data]);

    } catch (error) {

    }
  }

  async function loadObjects(){
    try {
      const fk_course_id = courseSelect;
      const data = {
        fk_course_id
      }
      const response = await api.get('all/objects?fk_course_id='+courseSelect);

      setObjects([...response.data]);

    } catch (error) {

    }
  }

  async function loadTypeOfEvaluation(){
    try {
      const fk_course_id = courseSelect;
      const data = {
        fk_course_id
      }
      const response = await api.get('all/type-of-evaluations-by-area?fk_course_id='+courseSelect);

      setTypeEvaluation([...response.data]);

    } catch (error) {

    }
  }

  async function loadYear(){
    try {
      const fk_course_id = courseSelect;
      const data = {
        fk_course_id
      }
      const response = await api.get('all/year-by-area?fk_course_id='+courseSelect);

      setYears(response.data);

    } catch (error) {

    }
  }

  const handleChangeSelect = (e) => {
    setValueSelect(e.target.value);
    searchText[0] ={
      ...searchText[0],
      value: e.target.value // Atualiza apenas o campo desejado
    };
  };

  const handleChangeQuestionsPerPage = (e) => {
    searchText[0] ={
      ...searchText[0],
      totalPerPage: e.target.value // Atualiza apenas o campo desejado
    };
  };

  useEffect(() => {
    loadCourses();
    searchText[0] ={
      fk_course_id: 0,
      fk_object_id: 0,
      fk_type_of_evaluation_id: 0,
      year: 0,
      totalPerPage: 10,
      value: "S"
    };
  }, []);

  useEffect(() => {
    if(localStorage.getItem('@Questione-search-course') != null){
      setCourseSelect(localStorage.getItem('@Questione-search-course'));
    }
  }, [courses]);

  useEffect(() => {
    if(courseSelect != 0) {
      setEnableFilter(true);
      loadObjects();
      loadTypeOfEvaluation();
      loadYear();
    }

  }, [courseSelect]);


  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };


  return (
      <div
          {...rest}
          className={clsx(classesGeneral.root, className)}>
        <BreadcrumbsComponent items={breadcrumbItems} />
        <Card >
          <CardContent>
          <Box display="flex" style={{marginBottom: '20px'}}>
            <Box display="flex" justifyContent="flex-start">
              <div style={{
                backgroundColor: '#e6f7ff',  // Azul claro
                color: '#005b99',            // Azul mais escuro para o texto
                padding: '10px 20px',        // Espaçamento interno
                borderLeft: '5px solid #005b99', // Barra à esquerda para destaque
                fontSize: '1.5em',           // Aumentar o tamanho da fonte
                fontWeight: 'bold',          // Negrito para chamar atenção
                borderRadius: '8px',         // Bordas arredondadas para suavizar o visual
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Sombra leve
                marginBottom: '20px'         // Espaçamento abaixo do título
              }}>
                {'Banco de Questões Geral'}
              </div>
            </Box>
            {!isSmallScreen &&
                <Box display="flex" sx={{flexGrow: 1}} justifyContent="flex-end">
                  <Button
                      color="primary"
                      variant="contained"
                      onClick={onClickNewCourse}
                      className={classesGeneral.buttons}
                      style={{height: '35px'}}>
                    Nova Questão
                  </Button>
                </Box>}
          </Box>
            <SearchFilterQuestion
                onClickSearch={onClickSearch}
                showUnfinishedQuestions={true}
                />
            {/*<Card className={classes.root}>
            <CardContent>

              <div>
                <Box
                    display="flex"
                    flexWrap="wrap"
                    flexDirection="row"
                    alignItems="center"
                    style={{marginTop: '10px'}}>
                  <Box style={{ flex: '1 1 200px', marginBottom: '5px' }}>
                    <CustomSelect
                        text="Área"
                        value={searchText[0] ? searchText[0].fk_course_id : 0}
                        textOption="Todas as áreas"
                        onChange={onChangeCourse}
                        visibility={true}
                        width="100%"
                        valuesMap={courses}
                    />
                  </Box>

                  <Box style={{ flex: '1 1 200px', marginLeft: isSmallScreen ? '0px' : '10px', marginBottom: '5px' }}>
                    <CustomSelect
                        text="Conteúdo"
                        value={searchText[0] ? searchText[0].fk_object_id : 0}
                        onChange={onChangeObject}
                        visibility={enableFilter}
                        textOption="Todos os conteúdos"
                        width="100%"
                        valuesMap={objects}
                    />
                  </Box>

                  <Box style={{ flex: '1 1 200px', marginLeft: isSmallScreen ? '0px' : '10px', marginBottom: '5px' }}>
                    <CustomSelect
                        text="Banca"
                        value={searchText[0] ? searchText[0].fk_type_of_evaluation_id : 0}
                        onChange={onChangeTypeOfEvaluation}
                        visibility={enableFilter}
                        textOption="Todos"
                        width="100%"
                        valuesMap={typeEvaluation}
                    />
                  </Box>

                  <Box style={{ flex: '1 1 200px', marginLeft: isSmallScreen ? '0px' : '10px', marginBottom: '5px' }}>
                    <CustomSelect
                        text="Ano"
                        value={searchText[0] ? searchText[0].year : 0}
                        onChange={onChangeYear}
                        visibility={enableFilter}
                        textOption="Todos"
                        width="100%"
                        valuesMap={years}
                    />
                  </Box>
                </Box>
              </div>


              <Box display="flex" justifyContent={isSmallScreen ? "center" : "flex-start"} flexDirection={isSmallScreen ? "column" : "row"} style={{marginTop: '5px'}}>
                <Box display="flex" style={{flex: '1 1 30px', marginBottom: '5px'}}>
                  <div className="mb-3" style={{display: 'flex'}}>
                    <label htmlFor="type-of-evaluation" className="form-label" style={{marginTop: '6px'}}>
                      {"Total por Página"}
                    </label>
                    <select
                        id={"select-total-page"}
                        className="form-select"
                        value={searchText[0] ? searchText[0].totalPerPage : 10}
                        onChange={handleChangeQuestionsPerPage}
                        aria-describedby={"select-total-page"}
                        style={{width: "100px", marginLeft: '10px'}}>
                      <option value={0}>Selecione</option>
                      {optionsQuestionsPerPagge && optionsQuestionsPerPagge.map((type) => (
                          <option key={type.id ? type.id : type} value={type.id ? type.id : type}>
                            {type.description ? type.description : type}
                          </option>
                      ))}
                    </select>
                  </div>
                </Box>
                <Box display="flex" style={{ flex: '1 1 100px', marginTop: isSmallScreen ? '0px' : '10px' }}>

                  <div   className="form-check" >
                    <input
                        className="form-check-input"
                        type="radio"
                        name="opcao"
                        id="optionNotFinished"
                        value="N"
                        checked={searchText[0] ? searchText[0].value === 'N' : false}
                        onChange={handleChangeSelect}
                    />
                    <label className="form-check-label" htmlFor="optionNotFinished">
                      Não finalizadas
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                        className="form-check-input"
                        style={{marginLeft: '5px'}}
                        type="radio"
                        name="opcao"
                        id="optionYourQuestions"
                        value="S"
                        checked={searchText[0] ? searchText[0].value === 'S' : false}
                        onChange={handleChangeSelect}
                    />
                    <label className="form-check-label" htmlFor="optionYourQuestions">
                      {isSmallScreen ? "Suas" : "Suas questões"}
                    </label>
                  </div>

                  <div className="form-check">
                    <input
                        className="form-check-input"
                        type="radio"
                        style={{marginLeft: '5px'}}
                        name="opcao"
                        id="optionAllQuestions"
                        value="T"
                        checked={searchText[0] ? searchText[0].value === 'T' : true}
                        onChange={handleChangeSelect}
                    />
                    <label className="form-check-label" htmlFor="optionAllQuestions">
                      {isSmallScreen ? "Todas" : "Todas as questões"}
                    </label>
                  </div>
                </Box>
              </Box>


                <Box display="flex" justifyContent={isSmallScreen ? "center" : "center"} style={{marginTop: '10px'}}>

                    <Button variant="contained" color="primary" className={classesGeneral.buttons} onClick={onClickSearch}>
                      {isSmallScreen ? "Filtrar" : "Filtrar questões"}
                    </Button>
                  <div style={{marginLeft: '10px'}}>
                    <Button variant="contained"className={classesGeneral.buttons} onClick={onClean}>
                      {isSmallScreen ? "Limpar" : "Limpar Filtro"}
                    </Button>
                  </div>
                </Box>

            </CardContent>
          </Card>*/}
          </CardContent>
        </Card>
    </div>
  );
};

QuestionToolbar.propTypes = {
  className: PropTypes.string,
  onClickSearch: PropTypes.func,
  onClickCleanSearch: PropTypes.func,
  searchText: PropTypes.array,
  history: PropTypes.object
};

export default withRouter(QuestionToolbar);
