import React from 'react';
import { Typography, Box } from '@material-ui/core';
import RenderCodeLatex from "../../RenderLatex";


const QuestionPaper = ({ questions }) => {
    const itemsPerPage = 4; // Number of questions per page

    // Split questions into pages
    const pages = [];
    for (let i = 0; i < questions.length; i += itemsPerPage) {
        pages.push(questions.slice(i, i + itemsPerPage));
    }

    const totalQuestions = questions.length;
    const columns = 3;
    const questionsPerColumn = Math.ceil(totalQuestions / columns);

    // Split questions into columns
    const columnsData = [];
    for (let i = 0; i < columns; i++) {
        columnsData.push(
            questions.slice(i * questionsPerColumn, (i + 1) * questionsPerColumn)
        );
    }

    return (
        <>
            {/* Render the pages of questions */}
            {pages.map((page, pageIndex) => (
                <div
                    key={pageIndex}
                    style={{
                        fontFamily: 'Arial, sans-serif',
                        padding: '3cm', // Adiciona padding de 3 cm para simular margens
                        margin: '0 auto',
                        columnCount: 2,
                        columnGap: '10px',
                        width: 'calc(100% - 6cm)', // Ajusta a largura para compensar o padding
                        boxSizing: 'border-box',
                        overflow: 'hidden',
                    }}
                >
                    <Typography
                        variant="h4"
                        gutterBottom
                        style={{
                            textAlign: 'center',
                            marginBottom: '10px',
                            fontWeight: 'bold',
                            fontSize: '18px',
                        }}
                    >
                        Prova
                    </Typography>
                    {page.map((question, index) => (
                        <div
                            key={index}
                            style={{
                                marginBottom: '10px',
                                padding: '5px',
                                breakInside: 'avoid',
                                pageBreakInside: 'auto',
                                width: '100%',
                                boxSizing: 'border-box',
                            }}
                        >
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{
                                    marginBottom: '5px',
                                    fontWeight: 'bold',
                                    textTransform: 'uppercase',
                                    borderBottom: '1px solid #000',
                                    paddingBottom: '5px',
                                    fontSize: '12px',
                                }}
                            >
                                {`Questão ${index + 1 + (pageIndex * itemsPerPage)}`}
                            </Typography>
                            <Box style={{ marginBottom: '5px' }}>
                                <RenderCodeLatex text={question.question.base_text} />
                                <RenderCodeLatex text={question.question.stem} />
                            </Box>
                            <ul style={{ listStyleType: 'none', paddingLeft: '0', margin: '0' }}>
                                {question.question.question_items.map((option, i) => (
                                    <li key={i} style={{ marginBottom: '5px', paddingLeft: '20px', position: 'relative' }}>
                                        <Typography variant="body1" style={{ display: 'flex', alignItems: 'center', fontSize: '12px' }}>
                                            <span style={{ width: '20px', fontWeight: 'bold', marginRight: '10px' }}>
                                                {String.fromCharCode(65 + i)}.
                                            </span>
                                            <RenderCodeLatex text={option.description} />
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            ))}

            {/* Answer Key Page */}
            <div
                style={{
                    fontFamily: 'Arial, sans-serif',
                    padding: '3cm', // Adiciona padding de 3 cm para simular margens
                    margin: '0 auto',
                    width: 'calc(100% - 6cm)', // Ajusta a largura para compensar o padding
                    boxSizing: 'border-box',
                }}
            >
                <Typography
                    variant="h4"
                    gutterBottom
                    style={{
                        textAlign: 'center',
                        marginBottom: '20px',
                        fontWeight: 'bold',
                        fontSize: '18px',
                    }}
                >
                    Gabarito
                </Typography>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    {columnsData.map((columnData, columnIndex) => (
                        <div key={columnIndex} style={{ width: `calc(100% / ${columns} - 20px)` }}>
                            <table style={{ width: '100%', borderCollapse: 'collapse', fontSize: '12px' }}>
                                <thead>
                                <tr>
                                    <th style={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>Questão</th>
                                    <th style={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>Alternativas</th>
                                </tr>
                                </thead>
                                <tbody>
                                {columnData.map((question, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>
                                            {index + 1 + (columnIndex * questionsPerColumn)}
                                        </td>
                                        <td style={{ border: '1px solid #000', padding: '5px', textAlign: 'center' }}>
                                            {question.question.question_items.map((option, i) => (
                                                <span key={i} style={{
                                                    display: 'inline-block',
                                                    width: '25px',
                                                    height: '25px',
                                                    borderRadius: '50%',
                                                    border: '1px solid #000',
                                                    textAlign: 'center',
                                                    lineHeight: '25px',
                                                    marginRight: '5px',
                                                    backgroundColor: 'white'
                                                }}>
                                                    {String.fromCharCode(65 + i)}
                                                </span>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>
                    ))}
                </div>
            </div>

            <style jsx>{`
                @media print {
                    @page {
                        size: A4;
                        margin: 0; /* Remove a margem padrão da página */
                    }
                    body {
                        margin: 0;
                        padding: 0;
                        width: 210mm; /* Largura do A4 em milímetros */
                        height: 297mm; /* Altura do A4 em milímetros */
                    }
                }
            `}</style>
        </>
    );
};

export default QuestionPaper;

