import React, {useEffect, useState} from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from "../../../../services/api";
import {toast} from "react-toastify";
import {Alert} from "@material-ui/lab";

function ContestDialog({ open, handleClose, idAnswer }) {
    const [contestOption, setContestOption] = useState('');
    const [text, setText] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        setContestOption('');
        setText('');
    }, [idAnswer]);

    useEffect(() => {
        // Disable the register button if either field is empty
        setIsDisabled(!contestOption || !text);
    }, [contestOption, text]);

    async function handleRegister() {
        try {
            const data = {
                contest: contestOption,
                description: text,
                fk_answers_id: idAnswer,
            }
            const response = await api.post('evaluation/contest/', data);

            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            } else if(response.status === 200){
                toast.success('Questão contestada.');
            }
        } catch (error) {

        }
        handleClose();
    }

    return (
        <>
            <style>
                {`
                    .modal-dialog {
                        max-width: 600px; /* Adjust max width as needed */
                        margin: 1.75rem auto; /* Centering the modal */
                    }
                `}
            </style>
            <Modal show={open} onHide={handleClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Contestar Questão</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Alert variant="warning">
                        Use este recurso apenas se você acredita que a questão está mal elaborada. Se você tiver dúvidas sobre o conteúdo da questão ou não souber como resolvê-la, não faça a contestação.

                        Caso decida por contestar, selecione o que deseja contestar e certifique-se de fornecer informações claras e precisas para facilitar a análise da sua solicitação.
                    </Alert>
                    <Form>
                        <Form.Group controlId="formContestOption">
                            <Form.Label>Selecione o que deseja contestar</Form.Label>
                            <Form.Control
                                as="select"
                                value={contestOption}
                                onChange={(e) => setContestOption(e.target.value)}
                            >
                                <option value="" disabled>
                                    Selecione
                                </option>
                                <option value="Enunciado">Enunciado</option>
                                <option value="Alternativas">Alternativas</option>
                            </Form.Control>
                        </Form.Group>
                        <Form.Group controlId="formContestText">
                            <Form.Label>Escreva sua contestação</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleRegister}
                        disabled={isDisabled}  // Disable button if conditions are not met
                    >
                        Registrar
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ContestDialog;
