import React from 'react';
import ReactHtmlParser from 'react-html-parser';

const DecompositionDisplay = ({ decomposition }) => {
    return (
        <>
            {decomposition && decomposition.length > 0 ? (
                <div style={{ color: 'blue' }}>
                    {decomposition.length === 1 ? 'Tópico:' : 'Tópicos:'}{" "}
                    {decomposition.map((item, index) => (
                        <span key={index}>
                            {ReactHtmlParser(item.content_decomposition.description)}.
                        </span>
                    ))}
                </div>
            ) : (
                <div style={{ color: 'lightcoral' }}>
                    {'Não possui assuntos associados.'}
                </div>
            )}
        </>
    );
};

export default DecompositionDisplay;
