import React from 'react';
import {
    Box,
    Checkbox,
    Divider,
    FormControlLabel,
    IconButton,
    Link,
    List,
    ListItem,
    Typography
} from '@material-ui/core';
import RenderCodeLatex from "../../../../components/RenderLatex";
import Pagination from "@material-ui/lab/Pagination";
import ContestDialog from "../ContestDialog/ContestDialog";
import TooltipQuestione from "../../../../components/TooltipQuestione/TooltipQuestione";
import Timer from "../../../../components/Timer";

const QuestionItemsTraditionalComponent = ({
                                    current, // Recebe o objeto atual diretamente
                                    handleEliminate,
                                    handleToggle,
                                    handleListItemClick,
                                    disableItens,
                                    eliminatedItems,
                                    alternativeLetters,
                                    classes,
                                   classesGeneral,
                                   page,
                                   handleChangePage,
                                   hideQuestions,
                                   handleDialogOpenContest,
                                   dialogOpenContest,
                                   handleDialogCloseContest,
                                   dateTimeToFinalized,
                                   getExpiryTimestamp,
                                   autoFinishEvaluation,
                                   advanceAutomatically,
                                   handleChangeAdvanceAutomatically
                                }) => {

    return (
        <div>
            {dateTimeToFinalized &&
                <Box display='flex' flexDirection='row' alignItems='center' marginRight='20px' marginTop='5px'
                     justifyContent='center'>
                    <Typography variant="body2" style={{textAlign: 'center', marginTop: '5px'}}>
                        Falta
                    </Typography>
                    <TooltipQuestione description={'Este é o tempo restante para finalizar o simulado.'}
                                      position={'bottom-start'} content={
                        <Timer
                            expiryTimestamp={
                                getExpiryTimestamp(
                                    ((new Date(dateTimeToFinalized.date.replace(' ', 'T'))).getTime() - (new Date()).getTime())
                                )
                            }
                            onExpire={autoFinishEvaluation}
                            setShowTimeDialog={() => {
                            }}
                        />
                    }/>
                </Box>}
            <Box display='flex' justifyContent='center'>
                <TooltipQuestione
                    description={'Se esta opção estiver habilitada, o sistema avançará automaticamente para a próxima questão após o estudante responder.'}
                    position={'bottom-start'} content={
                    <FormControlLabel control={
                        <Checkbox name="advanceAutomatically"
                                  checked={advanceAutomatically}
                                  onChange={handleChangeAdvanceAutomatically}/>}
                                      label="Avançar automaticamente ao responder."/>
                }/>
            </Box>

            {/* Pagination */}
            <Box display='flex' margin='10px' justifyContent='center'>
                <Pagination
                    count={current.length}
                    variant="outlined"
                    page={page}
                    color="primary"
                    onChange={handleChangePage}
                />
            </Box>
            <Divider style={{padding: '3px', marginTop: '10px', marginBottom: '10px'}}
                     className={classesGeneral.paperTitle}/>
            {/* Question header and contest option */}
            <Box>
                {current.length > 0 && hideQuestions === false && (
                    <div style={{margin: '10px'}}>
                        <Box display="flex" justifyContent="space-between">
                            <Box display="flex" sx={{flexGrow: 1}} justifyContent="flex-start">
                                <div style={{
                                    color: '#757575',
                                    fontFamily: 'Verdana',
                                    fontSize: '14px',
                                    marginTop: '7px'
                                }}>
                                    {'Questão    '}
                                </div>
                                &nbsp;
                                <div style={{
                                    color: '#000000',
                                    fontWeight: 'bold',
                                    fontFamily: 'Verdana',
                                    fontSize: '20px',
                                    marginTop: '0px',
                                    textDecoration: 'underline'
                                }}>
                                    {page}
                                </div>
                                &nbsp;
                                <div style={{
                                    color: '#757575',
                                    fontFamily: 'Verdana',
                                    fontSize: '14px',
                                    marginTop: '7px'
                                }}>
                                    {'   de ' + current.length}
                                </div>

                                {/* Contest option */}
                                {current[page - 1].contests.length === 0 ? (
                                    <Link onClick={handleDialogOpenContest}>
                                        <div style={{
                                            color: '#757575',
                                            fontFamily: 'Verdana',
                                            fontSize: '14px',
                                            marginTop: '7px',
                                            marginLeft: '10px'
                                        }}>
                                            {'Deseja contestar esta Questão?'}
                                        </div>
                                    </Link>
                                ) : (
                                    <div style={{
                                        color: '#d32f2f',  // Cor vermelha para chamar mais atenção
                                        fontFamily: 'Verdana, sans-serif',
                                        fontSize: '12px',
                                        marginLeft: '10px',
                                        padding: '2px',  // Adiciona espaçamento interno
                                        paddingRight: '6px',
                                        backgroundColor: '#ffe6e6',  // Fundo claro para destacar
                                        border: '1px solid #d32f2f',  // Borda para evidenciar
                                        borderRadius: '5px',  // Arredonda os cantos
                                        fontWeight: 'bold',  // Negrito para dar destaque
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}>
                                        <span style={{marginRight: '8px'}}>⚠️</span> {/* Ícone de alerta */}
                                        {'Você contestou esta questão!'}
                                    </div>
                                )}
                                <ContestDialog open={dialogOpenContest} handleClose={handleDialogCloseContest}
                                               idAnswer={current[page - 1].id}/>
                            </Box>
                        </Box>

                        {/* Divider */}
                        <Divider style={{padding: '3px', marginTop: '10px', marginBottom: '10px'}}
                                 className={classesGeneral.paperTitle}/>
                    </div>
                )}
            </Box>

            {/* Base text */}
            <div style={{marginLeft: '15px'}}>
                {current && current[page - 1].evaluation_question_without_correct.question_without_correct.base_text && (
                    <RenderCodeLatex
                        text={current[page - 1].evaluation_question_without_correct.question_without_correct.base_text}/>
                )}
            </div>

            {/* Stem */}
            <div style={{marginLeft: '10px', marginTop: '10px'}}>
                {current[page - 1].evaluation_question_without_correct.question_without_correct.stem && (
                    <RenderCodeLatex
                        text={current[page - 1].evaluation_question_without_correct.question_without_correct.stem}/>
                )}
            </div>

            {/* Question items */}
            <div style={{marginTop: '15px'}}>
                {current[page - 1].evaluation_question_without_correct.question_without_correct.question_items_without_correct.map((item, i) => (
                    <div key={item.id}>
                        <Box display="flex" flexDirection="row" style={{width: '100%'}}>
                            {/* Botão de eliminar (tesoura) */}
                            {current[page - 1].answer !== item.id && (
                                <IconButton
                                    aria-label="scissors"
                                    style={{marginRight: '10px'}}
                                    size="small"
                                    onClick={() => handleEliminate(item.id)}
                                >
                                    <i className="fa-solid fa-scissors"></i>
                                </IconButton>
                            )}

                            {/* Lista de alternativas */}
                            <Box sx={{width: '100%'}}>
                                <List
                                    className={classes.lineItemQuestion}
                                    component="nav"
                                    aria-label="secondary mailbox folder"
                                    onClick={handleToggle(item.id)}
                                >
                                    <ListItem
                                        selected={current[page - 1].answer === item.id}
                                        button
                                        onClick={(event) => handleListItemClick(event, current[page - 1].id, item.id)}
                                        style={{
                                            background: disableItens(current[page - 1], item.id) ? '#ffcdd2' : '#f5f5f5',
                                            opacity: eliminatedItems.has(item.id) ? 0.5 : 1, // Reduz a opacidade para itens eliminados
                                        }}
                                        disabled={disableItens(current[page - 1], item.id)}
                                    >
                                        {/* Letra alternativa */}
                                        <div style={{marginRight: '10px', fontSize: '14px', fontWeight: 'bold'}}>
                                            {alternativeLetters[i]})
                                        </div>

                                        {/* Descrição da alternativa */}
                                        <div>
                                            {item.description && <RenderCodeLatex text={item.description}/>}
                                        </div>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                    </div>
                ))}
            </div>

        </div>
    );
};


export default QuestionItemsTraditionalComponent;
