import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Modal, Button, Typography, Box, IconButton } from '@material-ui/core';
import Cropper from 'react-easy-crop';
import { Tooltip, Slider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const ScreenCaptureModal = ({ show, handleClose, onImageCaptured }) => {
    const [stream, setStream] = useState(null);
    const [screenshot, setScreenshot] = useState(null);
    const [croppedImage, setCroppedImage] = useState(null);
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [zoom, setZoom] = useState(1);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
    const [cropSize, setCropSize] = useState({ width: 300, height: 200 }); // Novo estado para tamanho de corte
    const videoRef = useRef(null);

    useEffect(() => {
        if (show) {
            setScreenshot(null);
            setStream(null);
        }
    }, [show]);

    const startCapture = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getDisplayMedia({
                video: {
                    cursor: 'always',
                },
            });
            setStream(mediaStream);
            videoRef.current.srcObject = mediaStream;
            videoRef.current.play();

            videoRef.current.onloadeddata = async () => {
                const canvas = document.createElement('canvas');
                canvas.width = videoRef.current.videoWidth;
                canvas.height = videoRef.current.videoHeight;
                const context = canvas.getContext('2d');
                context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
                const imgData = canvas.toDataURL('image/png');
                setScreenshot(imgData);

                if (stream) {
                    const tracks = stream.getTracks();
                    tracks.forEach((track) => track.stop());
                    setStream(null);
                }
            };
        } catch (err) {
            console.error('Erro ao capturar a tela: ' + err);
        }
    };

    const getCroppedImg = useCallback((imageSrc, crop) => {
        return new Promise((resolve, reject) => {
            const image = new Image();
            image.src = imageSrc;
            image.onload = () => {
                const canvas = document.createElement('canvas');
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                canvas.width = crop.width;
                canvas.height = crop.height;
                const ctx = canvas.getContext('2d');

                ctx.drawImage(
                    image,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                canvas.toBlob((blob) => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    const url = window.URL.createObjectURL(blob);
                    resolve(url);
                }, 'image/jpeg');
            };
            image.onerror = (error) => reject(error);
        });
    }, []);

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImageUrl = await getCroppedImg(screenshot, croppedAreaPixels);
            setCroppedImage(croppedImageUrl);
            onImageCaptured(croppedImageUrl); // Passa a imagem cortada para o componente pai
        } catch (e) {
            console.error(e);
        }
    }, [croppedAreaPixels, screenshot, onImageCaptured]);

    const handleWidthChange = (event, newWidth) => {
        setCropSize((prev) => ({ ...prev, width: newWidth }));
    };

    const handleHeightChange = (event, newHeight) => {
        setCropSize((prev) => ({ ...prev, height: newHeight }));
    };

    return (
        <Modal
            open={show}
            onClose={handleClose}
            aria-labelledby="capture-modal-title"
            aria-describedby="capture-modal-description"
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 600,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 4,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <div style={{fontWeight: 'bold'}}>
                        Captura de Tela
                    </div>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                {!screenshot ? (
                    <>
                        <Button variant="contained" color="primary" onClick={startCapture} className="mb-3">
                            Iniciar Captura
                        </Button>
                        {stream && (
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <video ref={videoRef} style={{ maxWidth: '100%' }} />
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div style={{ position: 'relative', width: '100%', height: 400 }}>
                            <Cropper
                                image={screenshot}
                                crop={crop}
                                zoom={zoom}
                                aspect={cropSize.width / cropSize.height}
                                cropSize={cropSize}
                                onCropChange={setCrop}
                                onZoomChange={setZoom}
                                onCropComplete={onCropComplete}
                            />
                        </div>
                        <Box display="flex" justifyContent="space-between" mt={3}>
                            <Box display="flex">
                                <Tooltip title="Diminuir o zoom" arrow>
                                    <Button
                                        variant="contained"
                                        onClick={() => setZoom((prevZoom) => Math.max(prevZoom - 0.1, 1))}
                                    >
                                        -
                                    </Button>
                                </Tooltip>
                                <Tooltip title="Aumentar o zoom" arrow>
                                    <Button
                                        variant="contained"
                                        onClick={() => setZoom((prevZoom) => prevZoom + 0.1)}
                                        sx={{ ml: 2 }}
                                    >
                                        +
                                    </Button>
                                </Tooltip>
                            </Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={showCroppedImage}
                                sx={{ mt: 3 }}
                            >
                                Cortar Imagem
                            </Button>
                        </Box>
                        <Box mt={3}>
                            <Typography gutterBottom>Largura de corte:</Typography>
                            <Slider
                                value={cropSize.width}
                                min={50}
                                max={500}
                                step={10}
                                onChange={handleWidthChange}
                                aria-labelledby="width-slider"
                            />
                            <Typography gutterBottom>Altura de corte:</Typography>
                            <Slider
                                value={cropSize.height}
                                min={50}
                                max={500}
                                step={10}
                                onChange={handleHeightChange}
                                aria-labelledby="height-slider"
                            />
                        </Box>
                    </>
                )}
            </Box>
        </Modal>
    );
};

export default ScreenCaptureModal;
