import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {

    Paper,
    Chip,
     Box, Divider, Link, CardActionArea, Grid
} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import ReactHtmlParser from 'react-html-parser';
import SubjectIcon from '@material-ui/icons/Subject';
import useStyles from "../../style/style";
import QuestionAnswers from "../QuestionAnswers";
import RenderCodeLatex from "../RenderLatex/RenderCodeLatex";
import DecompositionDisplay from "../DecompositionDisplay/DecompositionDisplay";

const useStylesLocal = makeStyles(theme => ({
    textFoot: {
        color: '#000000', fontFamily: 'Verdana', fontSize: '12px', marginTop: '10px'
    },
    paper: {
        marginBottom: 10,
        '& > *': {
            margin: theme.spacing(2),
        },
        margin: 3,
        padding: 8
    },
    paperCorrect: {
        backgroundColor: '#e2f2e7',
        color: '#212121',
    },
    paperRightFont: {
        color: '#80cbc4',
    },
    paperWrongFont: {
        color: '#ef9a9a',
    },
}));

const QuestionText = ({
                          className,
                          history,
                          question,
                          baseTextShow = false,
                          ...rest
                      }) => {
    const [alternativeLetters] = React.useState(['a', 'b', 'c', 'd', 'e']);
    const [showBaseText, setShowBaseText] = React.useState(baseTextShow);

    const classes = useStylesLocal();
    const classesGeneral = useStyles();

    useEffect(() => {

    }, [showBaseText]);

    const changeShowBaseText = () => {
        setShowBaseText(!showBaseText);
    }

    return (
        <div className={classes.content} style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
            <Link onClick={() => changeShowBaseText()}>
                <div className={classesGeneral.subtitles}>
                    <SubjectIcon/>
                    {showBaseText ? "Ocultar texto associado a questão" : "Mostrar texto associado a questão"}
                </div>
            </Link>
            {showBaseText &&
                <div>
                    <div className={classesGeneral.subtitles}>
                        {"Texto base:"}
                    </div>
                    <div>
                        <RenderCodeLatex text={question.base_text}/>
                    </div>
                </div>}
            <div className={classesGeneral.subtitles}>
                {"Enunciado:"}
            </div>
            <RenderCodeLatex text={question.stem}/>

            {
                question.question_items.length > 0 &&
                <div className={classesGeneral.subtitles}>
                    {"Alternativas:"}
                </div>
            }
            {question.question_items.map((item, i) => (
                <div>
                    {item.correct_item == 1 ?
                        <div>
                            <Box display="flex" flexDirection="row" style={{width: '100%'}}>
                                <Box style={{marginTop: '15px', marginRight: '5px'}} sx={{flexShrink: 1}}>
                                    <Chip label={alternativeLetters[i]}
                                          style={{fontSize: '14px', fontWeight: 'bold', background: "#e2f2e7"}}
                                          size="small"/>
                                </Box>
                                <Box sx={{width: '100%'}}>
                                    <Paper className={clsx(classes.paper, classes.paperCorrect)} elevation={3}
                                           variant="outlined">
                                        <RenderCodeLatex text={item.description}/>
                                    </Paper>
                                </Box>

                            </Box>
                        </div>
                        :
                        <div>
                            <Box display="flex" flexDirection="row" style={{width: '100%'}}>
                                <Box style={{marginTop: '15px', marginRight: '5px'}}>
                                    <Chip label={alternativeLetters[i]}
                                          style={{fontSize: '14px', fontWeight: 'bold', background: "#e1f5fe"}}
                                          size="small"/>
                                </Box>
                                <Box sx={{width: '100%'}}>
                                    <Paper className={clsx(classes.paper)} variant="outlined">
                                        {<RenderCodeLatex text={item.description}/>}
                                    </Paper>
                                </Box>
                            </Box>
                        </div>}
                </div>
            ))}
            <QuestionAnswers
                question={question}/>

            <div style={{marginTop: '30px'}}></div>
            {
                <DecompositionDisplay decomposition={question.decomposition}/>
            }
            {
                question.skill !== null ?
                    <div className={classes.textFoot}>
                        Competência: {" " + question.skill.description}
                    </div>
                    : null
            }
            <div className={classes.textFoot}>
                {question.fk_user_id == localStorage.getItem("@Questione-id-user") && question.generatedByLLM == true ?
                    "Esta questão foi gerada por Inteligência Artificial revisada e validada por você." :
                    question.fk_user_id == localStorage.getItem("@Questione-id-user") && "Esta questão foi cadastrada por você."}
            </div>
            {/* question.keywords[0] ?
                <div className={classes.textFoot}>
                    Palavra(s)-chave:
                    {" " + question.keywords.map(item => (
                        ReactHtmlParser (item.keyword) + '. '
                    ))}
                </div> : null*/}
            {/*
                  <div className={classes.textFoot}>
                      Assuntos:
                      {" "}
                  </div>
              )*/}
            <Divider style={{
                padding: '2px',
                marginTop: '20px',
                marginBottom: '15px',
                backgroundColor: '#e1f5fe'
            }}/>
        </div>
    );
};

QuestionText.propTypes = {
    className: PropTypes.string,
    question: PropTypes.object,
};

export default withRouter(QuestionText);
