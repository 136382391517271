import React, { useEffect, useState } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Switch, TextField, Collapse, List, ListItem, FormControlLabel, Box, Typography
} from '@material-ui/core';

export default function DialogReleaseResults({
                                                 open,
                                                 handleClose,
                                                 saveData,
                                                 show_results,
                                                 date_release_results,
                                                 time_release_results,
                                                 release_preview_question
                                             }) {
    // Estados locais para controlar os valores do formulário
    const [checkedShowResult, setCheckedShowResult] = useState(show_results || false);
    const [releaseDate, setReleaseDate] = useState(date_release_results || '');
    const [releaseTime, setReleaseTime] = useState(time_release_results || '');
    const [checkedReleasePreviewQuestion, setCheckedReleasePreviewQuestion] = useState(release_preview_question || false);

    // useEffect para atualizar os estados locais quando os props mudarem
    useEffect(() => {
        setCheckedShowResult(show_results);
        setReleaseDate(date_release_results);
        setReleaseTime(time_release_results);
        setCheckedReleasePreviewQuestion(release_preview_question);
    }, [show_results, date_release_results, time_release_results, release_preview_question]);

    // Funções de manipulação para os campos de formulário
    const handleChangeShowResult = (event) => {
        setCheckedShowResult(event.target.checked);
    };

    const handleChangeReleaseDate = (event) => {
        setReleaseDate(event.target.value);
    };

    const handleChangeReleaseTime = (event) => {
        setReleaseTime(event.target.value);
    };

    const handleChangeReleasePreviewQuestion = (event) => {
        setCheckedReleasePreviewQuestion(event.target.checked);
    };

    // Função para salvar os dados
    const handleSave = () => {
        const data = {
            show_results: checkedShowResult,
            date_release_results: releaseDate,
            time_release_results: releaseTime,
            release_preview_question: checkedReleasePreviewQuestion
        };
        saveData(data); // Chama a função de salvar com os dados
        setReleaseDate(date_release_results || '');
        setReleaseTime(time_release_results || '');
        handleClose();  // Fecha o diálogo
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{
                <Typography variant="subtitle1" color="#000000" component="p">
                    {'Liberar visualização dos Resultados'}
                </Typography>}</DialogTitle>
            <DialogContent>
                <div style={{
                    padding: '10px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '4px',
                    color: '#555',
                    fontSize: '14px',
                    lineHeight: '1.6',
                    marginBottom: '16px',
                    fontStyle: 'italic'
                }}>{'Caso esta opção esteja habilitada, todos os estudantes terão acesso' +
                    ' ao resultado deste simulado. Você pode configurar uma data e hora programada' +
                    ' para que os estudantes tenham acesso aos resultados, e se o estudante' +
                    ' poderá visualizar as questões completas ou não.'}</div>
                <Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={checkedShowResult}
                                onChange={handleChangeShowResult}
                                name="show_results"
                                color="primary"
                            />
                        }
                        label="Liberar o resultado"
                    />

                    <Collapse in={checkedShowResult} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <TextField
                                    type="date"
                                    label="Data da liberação"
                                    InputLabelProps={{ shrink: true }}
                                    margin="dense"
                                    name="date_release_results"
                                    onChange={handleChangeReleaseDate}
                                    value={releaseDate}
                                    variant="outlined"
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <TextField
                                    type="time"
                                    label="Hora da liberação"
                                    InputLabelProps={{ shrink: true }}
                                    margin="dense"
                                    name="time_release_results"
                                    onChange={handleChangeReleaseTime}
                                    value={releaseTime}
                                    variant="outlined"
                                    fullWidth
                                />
                            </ListItem>
                            <ListItem>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkedReleasePreviewQuestion}
                                            onChange={handleChangeReleasePreviewQuestion}
                                            name="release_preview_question"
                                            color="primary"
                                        />
                                    }
                                    label="Liberar visualização completa das questões"
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleSave} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
