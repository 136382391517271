import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Card, List, ListItem, Button, CircularProgress,
  Backdrop, Box, Divider, Link, Paper, IconButton, Chip, FormControlLabel, Checkbox, useMediaQuery, Typography
} from '@material-ui/core';
import api from "../../../services/api";
import { toast } from 'react-toastify';
import {DialogQuestione} from "../../../components";
import Timer from "../../../components/Timer";

import './styles.css';
import useStyles from "../../../style/style";
import Pagination from '@material-ui/lab/Pagination';
import GamificationPanel from "../../../components/GamificationPanel/GamificationPanel";
import TooltipQuestione from "../../../components/TooltipQuestione";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import RenderCodeLatex from "../../../components/RenderLatex/RenderCodeLatex";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Instructor from "./Instructor/Instructor";
import Notification from "./Notification/Notification";
import ContestDialog from "./ContestDialog/ContestDialog";
import QuestionItemsTraditionalComponent from "./QuestionItemsTraditionalComponent/QuestionItemsTraditionalComponent";
import AdaptiveQuestionItemsComponent from "./AdaptiveQuestionItemsComponent/AdaptiveQuestionItemsComponent";
import LoadingDialog from "../../../components/LoadingDialog/LoadingDialog";

const useStylesLocal = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  lineQuestion: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    margin: 30
  },
  lineItemQuestion: {
    width: '100%'
  },
  buttons: {
    marginRight: 10
  },
  chipRed:{
    backgroundColor: '#e57373',
    color: '#ffffff',
    marginRight: 10
  },
  hide: {
    opacity: 0,
  },
  selected: { /* Aumenta a especificidade */
    color: '#e57373'
  }
}));

const DoEvaluation = props => {
  const { className, history, ...rest } = props;
  const { codeAplication } = props.match.params;
  const [application, setApplication] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [questionsNotAnswers, setQuestionsNotAnswers] = useState([]);

  const [enableButtonStart, setEnableButtonStart] = useState(true);
  const [enableDialogAlert, setEnableDialogAlert] = useState(false);
  const [hideQuestions, setHideQuestions] = useState(false);

  const [totalAnswers, setTotalAnswers] = useState(0);
  const [dateTimeToFinalized, setDateTimeToFinalized] = useState(null);
  const [dateServer, setDateServer] = useState(new Date());
  const [dialogFinish, setDialogFinish] = useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [refresh, setRefresh] = React.useState(0);
  const [showTimeDialog, setShowTimeDialog] = useState({
    show: false,
    message: '',
  });
  const timer = React.useRef();
  const [alternativeLetters] = React.useState(['a', 'b', 'c', 'd', 'e']);
  const [arrayAnimations] = React.useState(['boy_running', 'clock', 'coffee', 'hamburger', 'location',
    'paperplane', 'profile', 'quiz', 'web_design']);

  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const [page, setPage] = React.useState(1);

  const [dialogHelpOne, setDialogHelpOne] = useState(false);
  const [dialogHelpTwo, setDialogHelpTwo] = useState(false);
  const [dialogHelpThree, setDialogHelpThree] = useState(false);
  const [dialogHelpCollegeStudents, setDialogHelpCollegeStudents] = useState(false);
  const [advanceAutomatically, setAdvanceAutomatically] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState(null);

  const [isExpandedInstructor, setIsExpandedInstructor] = useState(true);

  const [dialogOpenContest, setDialogOpenContest] = useState(false);
  const [dialogNoMoreQuestion, setDialogNoMoreQuestion] = useState(false);
  const [eliminatedItems, setEliminatedItems] = useState(new Set());
  const [loadingNextQuestion, setLoadingNextQuestion] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleEliminate = (itemId) => {
    setEliminatedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(itemId)) {
        newSet.delete(itemId); // Remove o item se já estiver eliminado
      } else {
        newSet.add(itemId); // Adiciona o item ao conjunto de eliminados
      }
      return newSet;
    });
  };

  const handleDialogOpenContest = () => {
    setDialogOpenContest(true);
  };

  const handleDialogCloseContest = () => {
    setDialogOpenContest(false);
    updateEvaluation();
  };


  const handleChangeAdvanceAutomatically = (event, value) => {
    setAdvanceAutomatically(event.target.checked);

  }

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const handleBack = () => {
    history.goBack(); // Navega para a página anterior
  };

  function getExpiryTimestamp(timestampTime) {
    // const { date } = dateServer;
    // const time = new Date(date.replace(' ', 'T'));

    const time = new Date();

    time.setSeconds(time.getSeconds() + Math.floor(timestampTime / 1000));

    return time;
  }

  async function listApplication() {
    try {
      const response = await api.get('evaluation/get-application/'+codeAplication);

      if(response.status == 200){
        if(response.data.status == 0){
          toast.error('A avaliação está desabilitada.');
          history.push('/home');
          return ;
        }
        setApplication(response.data);
      } else if (response.status == 202) {
        toast.error(response.data.message);
        history.push('/home');
        return ;
      } else {
        toast.error('Ocorreu um erro ao buscar a avaliação.');
        history.push('/home');
        return ;
      }
    } catch (error) {

    }

  }

  async function startEvaluation(event){
    setOpenBackdrop(true);
    timer.current = setTimeout(() => {
      updateEvaluation();
    }, 100);
    event.preventDefault();

  }

  async function updateEvaluation(){
    try {
      let response;
      if(!application.adaptive_assessment) {
        response = await api.post('evaluation/start/' + codeAplication);
      } else {
        response = await api.post('evaluation/adaptive-start/' + codeAplication);
      }

      if (response.status === 202) {
        setOpenBackdrop(false);
        if(response.data.message){
          toast.error(response.data.message);
          if (response.data.closed) {
            history.push('/home');
          }
        }
      } else if(response.status == 200){
        if(response.data.status == 0){
          toast.error('Avaliação está desabilitada.');
          history.push('/home');
          return ;
        }
        setAnswers(response.data[0]);

        let totalAlreadyAnswer = 0;
        let arrayQuestionNotAnswers = [];
        response.data[0].forEach((item, i) => {
          if (item.answer) {
            totalAlreadyAnswer = totalAlreadyAnswer +  1;
          } else {
            arrayQuestionNotAnswers.push(i+1);
          }
        });
        setTotalAnswers(totalAlreadyAnswer);
        setQuestionsNotAnswers(arrayQuestionNotAnswers);

        setDateTimeToFinalized(response.data.date_time_to_finalized);
        setDateServer(response.data.date_time_to_finalized);

        setRefresh(Date.now());
        setEnableButtonStart(false);
        setOpenBackdrop(false);
      }
    } catch (error) {

    }
  }

  async function autoFinishEvaluation() {
    try {
      const response = await api.put('evaluation/finish/'+codeAplication, {
        finished_automatically: 1,
      });

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      } else if(response.status == 200){
        toast.error('Tempo para responder a avaliação esgotado!');
        history.push('/student-class/'+application.fk_class_id);
      }
    } catch (error) {

    }
    setDialogFinish(false);
  }

  async function finshEvaluation(){
    try {
      const response = await api.put('evaluation/finish/'+codeAplication);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
          history.goBack();
        }
      } else if(response.status == 200){
        toast.success('Avaliação respondida com sucesso!');
        history.push('/student-class/'+application.fk_class_id);
        //history.goBack();
      }
    } catch (error) {

    }
    setDialogFinish(false);
  }

  async function suspiciousActivityAssessment(){
    try {
      /*
        S - Saiu da avaliação
       */
      const type = 'OUT';
      const data = {
        type
      }

      const response = await api.post('evaluation/log-evaluation/'+codeAplication, data);

      if(response.status == 200){
        setNotificationMessage('Atividade suspeita registrada!');
      }
    } catch (error) {

    }
    setDialogFinish(false);

  }

  useEffect(() => {
    //updateEvaluation();
  }, [refresh]);

  useEffect(() => {

  }, [refresh, page, questionsNotAnswers]);

  useEffect(() => {

    if (enableDialogAlert === true && !application.adaptive_assessment){
      suspiciousActivityAssessment();
    }

  }, [enableDialogAlert]);

  useEffect(() => {
    listApplication();
  }, []);

  async function handleSubmitAnswer (event, answerId, suitable = -1) {

    try {
      setLoadingSubmit(true);
      const answer_id = answerId;
      const data = {
        answer_id, suitable
      }
      const response = await api.put('evaluation/adaptive/finished-answer/'+codeAplication, data);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }  else if(response.status == 200){
        listApplication();
      }

    } catch (error) {

    } finally {
      setTimeout(() => {
        setLoadingSubmit(false); // Finaliza o carregamento após meio segundo
      }, 600);
    }
    updateEvaluation();
  }
  async function handleListItemClick (event, answerId, itemQuestionSelected) {

    setEliminatedItems((prev) => {
      const newSet = new Set(prev);
      newSet.delete(itemQuestionSelected); // Remove o item dos eliminados
      return newSet;
    });

    try {

      const id = answerId;
      const answer =   itemQuestionSelected;
      const data = {
        id, answer
      }
      const response = await api.put('evaluation/answer/'+codeAplication, data);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
          if (response.data.closed) {
            history.push('/home');
          }
        }
      }  else if(response.status == 200){
        const values = answers;
        let totalAlreadyAnswer = 0;
        let arrayQuestionNotAnswers = [];
        values.forEach(function logArrayElements(element, index, array) {
          if(element.id == answerId){
            element.answer = itemQuestionSelected;
          }

          if (element.answer) {
            totalAlreadyAnswer = totalAlreadyAnswer + 1;
          } else {
            arrayQuestionNotAnswers.push(index+1);
          }
        });
        setQuestionsNotAnswers(arrayQuestionNotAnswers);
        setTotalAnswers(totalAlreadyAnswer);
        setAnswers(values);

        if(advanceAutomatically && page < values.length){
          setNotificationMessage('Avançado para a próxima questão...');
          setTimeout(() => {
            setPage(page+1);
            setNotificationMessage(null);
          }, 500);

        }
        setRefresh(Date.now());
      }

    } catch (error) {

    }

  };

  const handleToggle = (value) => () => {

  };

  const onClickOpenDialogHelpOne = () => {
    setDialogHelpOne(true);
  }
  const onClickCloseDialogHelpOne = () => {
    setDialogHelpOne(false);
  }

  const onClickCloseDialogHelpTwo = () => {
    setDialogHelpTwo(false);
  }

  const onClickOpenDialogHelpTwo = () => {
    setDialogHelpTwo(true);
  }

  const onClickCloseDialogHelpThree = () => {
    setDialogHelpThree(false);
  }

  const onClickOpenDialogHelpThree = () => {
    setDialogHelpThree(true);
  }

  const onClickCloseDialogHelpCollegeStudents = () => {
    setDialogHelpCollegeStudents(false);
  }

  const onClickOpenDialogHelpCollegeStudents = () => {
    setDialogHelpCollegeStudents(true);
  }

  const onClickOpenDialogFinsh = () => {
    setDialogNoMoreQuestion(false);
    setDialogFinish(true);
  }

  const onClickCloseDialogFinish = () => {
    setDialogFinish(false);
  }

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  function disableItens (answer, idItem){
    let value_return = false;
    if(answer.help_for_student){

      answer.help_for_student.forEach(function(help, i) {
        if(help.description_id != 'help_from_university_students'){
          if(help.fk_answer_deleted_id === idItem){
            value_return = true;
          }
        }
      });
    }
    return value_return;
  }

  async function helpFromUniversityStudents(answerId){
    try {
      const response = await api.post('class/student/gamification/help-from-university-students/'+answerId);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }  else if(response.status == 200){
        toast.success('Uau, você arrebentou! Conseguiu arrancar a sabedoria dos cérebros brilhantes do universo Questione! 🎉🎓💡');
        const values = answers;
        values.forEach(function logArrayElements(element, index, array) {
          if(element.id == answerId){
            var novaArray = [response.data];


            element.help_for_student.pop();
            element.help_for_student.pop();
            element.help_for_student.pop();
            element.help_for_student = novaArray[0];
          }
        });
        setAnswers(values);



        setRefresh(Date.now());
      }

    } catch (error) {

    }
    setDialogHelpOne(false);
    setDialogHelpTwo(false);
    setDialogHelpThree(false);
    setDialogHelpCollegeStudents(false);
  };

  async function removeAlternative(answerId, totalItems){
    try {
      const total_items_to_remove =  totalItems;
      const data = {
        total_items_to_remove
      }
      const response = await api.post('class/student/gamification/remove-alternative/'+answerId, data);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }  else if(response.status == 200){
        if(total_items_to_remove == 1)
          toast.success('Ei, você é um verdadeiro mestre das respostas! Deu um adeus para uma alternativa e agora ela está na lista negra, toda vermelhinha e desabilitada! 🚫🔴😄');
        else
          toast.success('Bravo! Você fez um desaparecimento mágico de '+totalItems+' opções na questão. Agora elas estão em modo invisível, pintadas de vermelho! 🎩✨🔴');

        const values = answers;
        values.forEach(function logArrayElements(element, index, array) {
          if(element.id == answerId){
            var novaArray = [response.data];


            element.help_for_student.pop();
            element.help_for_student.pop();
            element.help_for_student.pop();
            element.help_for_student = novaArray[0];
          }
        });
        setAnswers(values);



        setRefresh(Date.now());
      }

    } catch (error) {

    }
    setDialogHelpOne(false);
    setDialogHelpTwo(false);
    setDialogHelpThree(false);
    setDialogHelpCollegeStudents(false);
  };

  async function logEvaluation(type, answerId){
    try {
      const data = {
        type
      }
      const response = await api.post('evaluation/log-evaluation/'+codeAplication, data);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }  else if(response.status == 200){

      }

    } catch (error) {

    }

  };

  (function () {

    var hidden = "hidden";
    var visibilityChange;
    var timeoutId = null; // Para armazenar o ID do temporizador
    var delay = 4000; // 10 segundos em milissegundos

    if (hidden in document) {
      document.addEventListener("visibilitychange", handleVisibilityChange);
    } else if ((hidden = "mozHidden") in document) {
      document.addEventListener("mozvisibilitychange", handleVisibilityChange);
    } else if ((hidden = "webkitHidden") in document) {
      document.addEventListener("webkitvisibilitychange", handleVisibilityChange);
    } else if ((hidden = "msHidden") in document) {
      document.addEventListener("msvisibilitychange", handleVisibilityChange);
    }

    // Detect window focus/blur (minimize or switch to another app/window)
    window.addEventListener("focus", handleFocusChange);
    window.addEventListener("blur", handleFocusChange);

    function handleVisibilityChange() {
      if (document[hidden]) {
        functionHidden();
      } else {
        functionVisible();
      }
    }

    function handleFocusChange(event) {
      if (event.type === "blur") {
        functionHidden();
      } else if (event.type === "focus") {
        functionVisible();
      }
    }

    function functionVisible() {
      // Cancela o temporizador se o estudante voltar antes de 4 segundos
      if (timeoutId) {
        clearTimeout(timeoutId);
        timeoutId = null;
      }
      if (!enableButtonStart) {
        setHideQuestions(false);
      }
    }

    function functionHidden() {
      if (!enableButtonStart && !application.adaptive_assessment) {
        setHideQuestions(true);
        timeoutId = setTimeout(function () {
          setEnableDialogAlert(true);
        }, delay);
        // logEvaluation('O');
      }
    }

  })();


  const truncateText = (text, maxLength) => {
    if (text.length > maxLength && isSmallScreen) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  async function loadNextQuestion(){
    try {
      setLoadingNextQuestion(true);

      const response = await api.post('evaluation/adaptive/next-question/' + application.id_application);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
        if(response.data.more_questions){
          setDialogNoMoreQuestion(true);
        }
      } else if(response.status === 200){
        setAnswers(response.data[0]);
        setRefresh(new Date());
      }
    } catch (error) {

    } finally {
      setTimeout(() => {
        setLoadingNextQuestion(false); // Finaliza o carregamento após meio segundo
      }, 600);
    }
  }

  return (
      <div className="do-evaluation-screen">
        <Backdrop className={classes.backdrop} open={openBackdrop} onClick={handleCloseBackdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box display='flex' alignItems='center' marginBottom='20px'>
          <IconButton onClick={handleBack} color='primary'>
            <ArrowBackIcon />
            <div style={{fontSize: '18px', fontWeight: 'bold'}}>
              Voltar
            </div>
          </IconButton>
        </Box>
        { application.id ?
            <div className={classes.root}>
              <Card className={classes.root} style={{padding: '15px'}}>
                <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"  // Permite que os itens se movam para a linha abaixo quando necessário
                    justifyContent="space-between"
                    alignItems="flex-start">
                  <Box
                      display="flex"
                      flexDirection="column"
                      style={{ padding: '20px' }}
                      flexBasis={{ xs: '100%', md: 'auto' }}  // Flexível em telas pequenas
                      flexGrow={1}>
                    <div className={classesGeneral.paperTitleTextBold} style={{marginLeft: '15px'}}>
                      {'Simulado: '+truncateText(application.description, 20)}
                    </div>
                    {application.evaluation && application.evaluation.practice !== 1 && (
                        <div className={classesGeneral.paperTitleTextBold} style={{marginLeft: '15px'}}>
                          <i className="fa-solid fa-chalkboard-user" style={{marginRight: '10px'}}></i>
                          {'Professor(a): ' + truncateText(application.evaluation.user.name, 18)}
                        </div>
                    )}
                    <div className={classesGeneral.paperTitleText} style={{marginLeft: '15px', marginBottom: '5px'}}>
                      <i className="fa-solid fa-user" style={{marginRight: '10px'}}></i>
                      {'Aluno(a): ' + truncateText(localStorage.getItem("@Questione-name-user"), 29)}
                    </div>
                    {application && application.class && (
                        <div className={classesGeneral.paperTitleText} style={{marginLeft: '15px', marginBottom: '10px'}}>
                          <i className="fa-solid fa-users" style={{marginRight: '10px'}}></i>
                          {'Turma: ' +truncateText(application.class.description, 15) + " ("+ application.class.id_class + ')'}
                        </div>
                    )}
                    {application && application.fk_bank_id &&
                        <div
                            className={classesGeneral.paperTitleTextBold}
                            style={{color: '#b26a00', marginLeft: '15px', marginBottom: '10px'}}
                        >
                          {'Este é um SIMULADO ADAPTATIVO.'}
                        </div>
                    }
                    {!application.adaptive_assessment && answers.length !== 0 ? (
                        <div className={classesGeneral.paperTitleText} style={{marginLeft: '15px', marginBottom: '10px'}}>
                          Total respondidas:
                          <b> {totalAnswers+'/'+answers.length}</b>
                        </div>
                    ):
                        <div className={classesGeneral.paperTitleText} style={{marginLeft: '15px', marginBottom: '10px'}}>
                          Você já respondeu <b>{application.totalAnswerByUserAdaptive === 1 ? application.totalAnswerByUserAdaptive + " questão." : application.totalAnswerByUserAdaptive + " questões."}</b>
                        </div>
                    }

                    {!enableButtonStart && application.adaptive_assessment && (
                        <Box display="flex" justifyContent="left" marginTop="20px">
                          <div
                              style={{
                                marginRight: '3px',
                                marginLeft: '13px',
                                fontSize: '15px',
                                backgroundColor: '#e57373', // vermelho suave
                                color: 'white',
                                padding: '8px 15px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                marginBottom: '30px'
                              }}
                              onClick={onClickOpenDialogFinsh}
                              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e53935')} // cor um pouco mais escura ao passar o mouse
                              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#e57373')}>
                            Entregar Simulado
                          </div>
                          <div
                              style={{
                                marginRight: '3px',
                                marginLeft: '13px',
                                fontSize: '15px',
                                backgroundColor: '#75aefc', // vermelho suave
                                color: 'white',
                                padding: '8px 15px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                marginBottom: '30px'
                              }}
                              onClick={handleBack}
                              onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#3a7cf7')} // cor um pouco mais escura ao passar o mouse
                              onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#75aefc')}>
                            Pausar Simulado
                          </div>
                        </Box>)
                    }


                    {questionsNotAnswers.length !== 0 && !application.adaptive_assessment &&
                        <div className={classesGeneral.paperTitleText}
                             style={{marginLeft: '15px', marginBottom: '10px'}}>
                          Falta responder:
                          {questionsNotAnswers.length <= 10 ?
                              questionsNotAnswers.map((item, i) => (
                                  <Link
                                      style={{
                                        color: '#f44336',
                                        fontFamily: 'Verdana',
                                        fontSize: '14px',
                                        marginLeft: '5px'
                                      }}
                                      onClick={() => handleChangePage(null, item)}>
                                    {item}
                                  </Link>
                              )) : questionsNotAnswers.length + " questões."}
                        </div>
                    }
                  </Box>
                  {/* Painel gamificado alinhado à direita */}
                  {(application.class && application.class.gamified_class) ? (
                      <Box
                          flexBasis={{ xs: '100%', md: 'auto' }}  // Flexível em telas pequenas
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="flex-start"
                          style={{ padding: '20px' }}>
                        <GamificationPanel
                            refresh={refresh}
                            gamified_class={application.class.gamified_class}
                            classId={application.class.id}
                        />
                      </Box>
                  ) : null}
                </Box>

                { enableButtonStart &&
                    <Box display='flex' justifyContent='center' >
                      <TooltipQuestione description={"Você poderá iniciar/continuar sua avaliação clicando neste botão."}
                                        position={"top"} content={
                        <Button
                            className={classes.buttons}
                            variant="outlined"
                            color="primary"
                            style={{width: '99%', marginTop: '20px', marginLeft: '10px', marginBottom: '15px', marginRight: '20px'}}
                            onClick={startEvaluation}

                            disabled={!enableButtonStart}>
                          {application && application.student_started === 1 ? 'Continuar' : 'Iniciar' }
                        </Button>

                      }/>

                    </Box>}

                {(!enableButtonStart && questionsNotAnswers.length == 0 && !application.adaptive_assessment) &&
                    <Box display='flex'
                         justifyContent='center'
                         alignItems='center'
                         width='100%' >
                      <Link
                          component="button"
                          className={classesGeneral.textRedInfo}
                          onClick={onClickOpenDialogFinsh} disabled={enableButtonStart}>

                        <div
                            style={{
                              marginRight: '3px',
                              marginTop: '5px',
                              fontSize: '15px',
                              backgroundColor: '#f44336', // vermelho suave
                              color: 'white',
                              padding: '8px 15px',
                              borderRadius: '5px',
                              cursor: 'pointer',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              transition: 'background-color 0.3s ease',
                            }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e53935')} // cor um pouco mais escura ao passar o mouse
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f44336')}>
                          Entregar simulado
                        </div>
                      </Link>
                    </Box>}

              </Card>
              {/*<Box display={!enableButtonStart ? 'none' : 'block'} style={{marginTop: '10px'}}>
            <Player
                autoplay
                loop
                src={'/images/animations/'+arrayAnimations[Math.floor(Math.random() * 9)]+'.json'}
                style={{ height: '300px', width: '300px' }}>
              <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
            </Player>
          </Box>*/}

              {!enableButtonStart &&
                  <div>

                    <Box>
                      { (answers.length > 0 && hideQuestions === false) &&
                          <div style={{margin: '10px'}}>


                            <div style={{margin: '10px', marginTop: '20px'}}>
                              {!application.adaptive_assessment ?
                                  <QuestionItemsTraditionalComponent
                                      current={answers}
                                      handleEliminate={handleEliminate}
                                      handleToggle={handleToggle}
                                      handleListItemClick={handleListItemClick}
                                      disableItens={disableItens}
                                      eliminatedItems={eliminatedItems}
                                      alternativeLetters={alternativeLetters}
                                      classes={classes}
                                      classesGeneral={classesGeneral}
                                      page={page}
                                      handleChangePage={handleChangePage}
                                      hideQuestions={hideQuestions}
                                      handleDialogOpenContest={handleDialogOpenContest}
                                      dialogOpenContest={dialogOpenContest}
                                      handleDialogCloseContest={handleDialogCloseContest}
                                      dateTimeToFinalized={dateTimeToFinalized}
                                      getExpiryTimestamp={getExpiryTimestamp}
                                      autoFinishEvaluation={autoFinishEvaluation}
                                      advanceAutomatically={advanceAutomatically}
                                      handleChangeAdvanceAutomatically={handleChangeAdvanceAutomatically}/>
                                  :
                                  <AdaptiveQuestionItemsComponent
                                      current={answers ? answers[0] : null}
                                      handleEliminate={handleEliminate}
                                      handleToggle={handleToggle}
                                      handleListItemClick={handleListItemClick}
                                      disableItens={disableItens}
                                      eliminatedItems={eliminatedItems}
                                      alternativeLetters={alternativeLetters}
                                      classesGeneral={classesGeneral}
                                      handleSubmitAnswer={handleSubmitAnswer}
                                      nextQuestion={loadNextQuestion}
                                      handleBack={handleBack}
                                      finshEvaluation={onClickOpenDialogFinsh}
                                  />
                              }

                              {(application.class && application.class.gamified_class && application.allow_exchange_points && !application.adaptive_assessment) ?
                                  <Box style={{marginTop: '20px'}}>
                                    <Paper className={classesGeneral.paperTitle}>
                                      <Box display='flex' justifyContent={'center'}>
                                        <div className={classesGeneral.paperTitleTextBold}>
                                          {answers[page-1].help_for_student[0] ?
                                              'Você utilizou uma ajuda para esta questão!'
                                              : 'Qual ajuda você deseja para a questão '+page+'?'}
                                        </div>
                                      </Box>
                                    </Paper>
                                    <Paper className={classesGeneral.paperSubtitle} >
                                      <Box display="flex" justifyContent={'center'}>
                                        {answers[page-1].help_for_student[0] ?
                                            <Box>
                                              <div className={classesGeneral.textGreeInfo} style={{textAlign: 'center'}}>
                                                {'Você usou: '+answers[page-1].help_for_student[0].gamification_settings[0].description}
                                              </div>
                                              {answers[page-1].help_for_student[0].totalAnswersByItemQuestion != null ? (
                                                  <div>
                                                    <Box>
                                                      <div className={classesGeneral.paperTitleText} style={{textAlign: 'center'}}>
                                                        {'Dê uma espiada nas opções assinaladas e descubra o percentual de cada uma delas - é hora do show dos números! 🔍📊:'}
                                                      </div>
                                                    </Box>
                                                    <Box justifyContent={'center'}>
                                                      {answers[page-1].help_for_student[0].totalAnswersByItemQuestion.items.map((item, i) => (
                                                          <div>
                                                            {item.total_answers > 0 &&
                                                                (<Box justifyContent={'center'} display='flex' style={{marginTop: '15px', marginRight: '15px'}}>
                                                                  <div className={classesGeneral.paperTitleText} style={{fontWeight: item.total_answer_item > 0 && 'bold', marginRight: '5px'}}>
                                                                    {((item.total_answers/answers[page-1].help_for_student[0].totalAnswersByItemQuestion.total_answers)*100).toFixed(2)+'% marcaram a letra '}
                                                                  </div>
                                                                  <Chip label={alternativeLetters[i]} style={{fontSize: '14px', fontWeight: item.total_answer_item > 0 && 'bold', background:"#e2f2e7"}} size="small"/>
                                                                  {'.'}
                                                                </Box>)}
                                                          </div>
                                                      ))}
                                                    </Box>
                                                  </div>
                                              ) : null}
                                            </Box>
                                            :
                                            <Box display='flex' justifyContent={'center'}>
                                              <TooltipQuestione description={'Clique para remover uma opção incorreta da questão '+page+'.'} position={'top-start'} content={
                                                <Box justifyContent={'center'} style={{marginRight: '20px'}}>
                                                  <IconButton onClick={onClickOpenDialogHelpOne}>
                                                    <img
                                                        alt="Logo"
                                                        src="/images/numero-1.png" width='35px'/>
                                                  </IconButton>
                                                  <div className={classesGeneral.textRedInfo} style={{fontSize: '12px', textAlign:'center'}}>
                                                    {'-10 PR'}
                                                  </div>
                                                </Box>
                                              }/>
                                              <TooltipQuestione description={'Clique para remover duas opções incorretas da questão '+page+'.'} position={'top-start'} content={
                                                <Box justifyContent={'center'} style={{marginRight: '20px'}}>
                                                  <IconButton onClick={onClickOpenDialogHelpTwo}>
                                                    <img
                                                        alt="Logo"
                                                        src="/images/numero-2.png" width='35px'/>
                                                  </IconButton>
                                                  <div className={classesGeneral.textRedInfo} style={{fontSize: '12px', textAlign:'center'}}>
                                                    {'-20 PR'}
                                                  </div>
                                                </Box>
                                              }/>
                                              <TooltipQuestione description={'Clique para remover três opções incorretas da questão '+page+'.'} position={'top-start'} content={
                                                <Box justifyContent={'center'} style={{marginRight: '20px'}}>
                                                  <IconButton onClick={onClickOpenDialogHelpThree}>
                                                    <img
                                                        alt="Logo"
                                                        src="/images/numero-3.png" width='35px'/>
                                                  </IconButton>
                                                  <div className={classesGeneral.textRedInfo} style={{fontSize: '12px', textAlign:'center'}}>
                                                    {'-30 PR'}
                                                  </div>
                                                </Box>
                                              }/>
                                              {(answers[page-1].evaluation_question_without_correct.question_without_correct.totalAnswers > 1) &&
                                                  <TooltipQuestione description={'Clique para visualizar o total de respostas anteriores para esta questão em cada alternativa.'} position={'top-start'} content={
                                                    <Box justifyContent={'center'}>
                                                      <IconButton onClick={onClickOpenDialogHelpCollegeStudents}>
                                                        <img
                                                            alt="Logo"
                                                            src="/images/college_students.png" width='35px'/>
                                                      </IconButton>
                                                      <div className={classesGeneral.textRedInfo} style={{fontSize: '12px', textAlign:'center'}}>
                                                        {'-30 PR'}
                                                      </div>
                                                    </Box>
                                                  }/>}
                                              <DialogQuestione handleClose={onClickCloseDialogHelpOne}
                                                               open={dialogHelpOne}
                                                               onClickAgree={(event) => removeAlternative(answers[page-1].id, 1)}
                                                               onClickDisagree={onClickCloseDialogHelpOne}
                                                               mesage={
                                                                 <div className={classesGeneral.messageDialog}>
                                                                   {'Ao deletar uma opção errada, você vai perder uns pontinhos preciosos dos seus PRs. Pronto para pagar esse tributo à sabedoria? 😅🔥💻'}
                                                                 </div>
                                                               }
                                                               title=
                                                                   {<div className={classesGeneral.titleDialog}>
                                                                     {'Apagar 1 alternativa'}
                                                                   </div>}/>
                                              <DialogQuestione handleClose={onClickCloseDialogHelpTwo}
                                                               open={dialogHelpTwo}
                                                               onClickAgree={(event) => removeAlternative(answers[page-1].id, 2)}
                                                               onClickDisagree={onClickCloseDialogHelpTwo}
                                                               mesage={
                                                                 <div className={classesGeneral.messageDialog}>
                                                                   {'Ao remover duas respostas erradas, você vai desencadear o imposto sobre seus PRs. Vai encarar essa jornada com coragem? 😄📉💼'}
                                                                 </div>
                                                               }
                                                               title=
                                                                   {<div className={classesGeneral.titleDialog}>
                                                                     {'Apagar 2 alternativas'}
                                                                   </div>}/>
                                              <DialogQuestione handleClose={onClickCloseDialogHelpThree}
                                                               open={dialogHelpThree}
                                                               onClickAgree={(event) => removeAlternative(answers[page-1].id, 3)}
                                                               onClickDisagree={onClickCloseDialogHelpThree}
                                                               mesage={
                                                                 <div className={classesGeneral.messageDialog}>
                                                                   {'Se você desaparecer com três opções erradas, seus PRs podem sentir um tremor de medo. Quer prosseguir nessa missão de eliminação? 😅📉💼'}
                                                                 </div>
                                                               }
                                                               title=
                                                                   {<div className={classesGeneral.titleDialog}>
                                                                     {'Apagar 3 alternativas'}
                                                                   </div>}/>
                                              <DialogQuestione handleClose={onClickCloseDialogHelpCollegeStudents}
                                                               open={dialogHelpCollegeStudents}
                                                               onClickAgree={(event) => helpFromUniversityStudents(answers[page-1].id)}
                                                               onClickDisagree={onClickCloseDialogHelpCollegeStudents}
                                                               mesage={
                                                                 <div className={classesGeneral.messageDialog}>
                                                                   {'Você está prestes a mergulhar em um mar de respostas! '+
                                                                       'Aqui temos '+answers[page-1].evaluation_question_without_correct.question_without_correct.totalAnswers+
                                                                       ' delas esperando ansiosamente pelo seu olhar curioso. Vamos dar uma olhada nas porcentagens de cada alternativa? Prepare-se para uma aventura estatística! 🌊📈😄'}
                                                                 </div>
                                                               }
                                                               title=
                                                                   {<div className={classesGeneral.titleDialog}>
                                                                     {'Ajuda dos universitários'}
                                                                   </div>}/>
                                            </Box>
                                        }
                                      </Box>
                                    </Paper>
                                  </Box> : null}
                            </div>
                          </div> }
                    </Box>

                    {hideQuestions === true && !application.adaptive_assessment &&
                        <div style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#ffc107',  // Amarelo alerta
                          color: 'white',
                          padding: '20px 40px',
                          borderRadius: '10px',
                          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                          fontSize: '2.5em',
                          textAlign: 'center',
                        }}>
                          <span role="img" aria-label="alert" style={{fontSize: '3em', marginRight: '15px'}}>⚠️</span>
                          Atividade suspeita detectada
                        </div>}

                    {notificationMessage && !application.adaptive_assessment ? <Notification message={notificationMessage} setMessage={setNotificationMessage} duration={1500} /> :
                        !application.adaptive_assessment ? <Instructor expanded={isExpandedInstructor} onToggle={setIsExpandedInstructor}/> : null}

                    {/*<Box display='flex' margin='10px' marginTop='30px' justifyContent='center'>
                      <Pagination count={answers.length} variant="outlined" page={page} color="primary"
                                  onChange={handleChangePage}/>
                    </Box>*/}
                  </div>}

            </div>
            : null}
        {(!enableButtonStart && questionsNotAnswers.length == 0 && !application.adaptive_assessment) &&
            <Box display='flex'
                 justifyContent='center'
                 alignItems='center'
                 width='100%' >
              <Link
                  component="button"
                  className={classesGeneral.textRedInfo}
                  onClick={onClickOpenDialogFinsh} disabled={enableButtonStart}>

                <div
                    style={{
                      marginRight: '3px',
                      fontSize: '15px',
                      backgroundColor: '#f44336', // vermelho suave
                      color: 'white',
                      padding: '8px 15px',
                      borderRadius: '5px',
                      cursor: 'pointer',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      transition: 'background-color 0.3s ease',
                      marginTop: '30px',
                      marginBottom: '30px'
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e53935')} // cor um pouco mais escura ao passar o mouse
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f44336')}>
                  Entregar simulado
                </div>
              </Link>
            </Box>}

        <DialogQuestione handleClose={onClickCloseDialogFinish}
                         open={dialogFinish}
                         onClickAgree={finshEvaluation}
                         onClickDisagree={onClickCloseDialogFinish}
                         mesage={
                           <div className={classesGeneral.messageDialog}>
                             {'Está pronto para dar um até logo ao simulado? Lembre-se, o conhecimento é uma viagem sem fim! 😄🚀📚'}
                           </div>
                         }
                         title=
                             {<div className={classesGeneral.titleDialog}>
                               {'Finalizar Avaliação'}
                             </div>}/>

        <Dialog
            open={enableDialogAlert}
            onClose={() => setEnableDialogAlert(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <div className={classesGeneral.titleDialog}>
              {'Alerta de atividade suspeita'}
            </div>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className={classesGeneral.messageDialog}>
                {'Você deu um passeio na "Rua das Dúvidas" no meio da sua maratona de avaliação. ' +
                    'Os detetives dos registros do simulado flagraram você em ação! 😄 ' +
                    'Ah, o log da sua travessura foi cuidadosamente arquivado na pasta "Gafes Épicas" do seu histórico de simulado! 🤣'}
              </div>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEnableDialogAlert(false)}>
              {'Fechar'}
            </Button>
          </DialogActions>
        </Dialog>

        <LoadingDialog open={loadingNextQuestion} text="Carregando a próxima questão..." />
        <LoadingDialog open={loadingSubmit} text="Enviando sua resposta..." />

        <DialogQuestione handleClose={() => setDialogNoMoreQuestion(false)}
                         open={dialogNoMoreQuestion}
                         onClickAgree={onClickOpenDialogFinsh}
                         onClickDisagree={() => setDialogNoMoreQuestion(false)}
                         mesage={
                           <div className={classesGeneral.messageDialog}>
                             {'O questione não possui mais questões para você, deseja finalizar?'}
                           </div>
                         }
                         title=
                             {<div className={classesGeneral.titleDialog}>
                               {'Finalizar'}
                             </div>}/>

      </div>

  );

};

DoEvaluation.propTypes = {
  className: PropTypes.string,
};

export default DoEvaluation;