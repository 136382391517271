import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {Tooltip, Grid, Box, Paper, Dialog, DialogContent, DialogTitle, IconButton} from '@material-ui/core';
import { withStyles } from "@material-ui/core/styles";
import useStyles from "../../../../../../style/style";
import RadarChart from "./RadarChart/RadarChart";
import BarChart from "./BarChart/BarChart";
import CloseIcon from "@material-ui/icons/Close";

const useStylesLocal = makeStyles(() => ({
  root: {
    margin: '5px'
  },
  content: {
    padding: 0
  },
  percentageRed: {
    backgroundColor: '#EC0B43',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageOrange: {
    backgroundColor: '#F5A623',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageGreen: {
    backgroundColor: '#5DE2A5',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  paperWrong: {
    width: '88%',
    backgroundColor: '#ef9a9a',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  paperRight: {
    width: '88%',
    backgroundColor: '#80cbc4',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  checkedCancel: {
    color: '#f44336'
  },
  lineQuestion: {
    marginLeft: 20,
  },
  labelRed: {
    backgroundColor: '#EC0B43',
    display: 'block',
    margin: '10px',
    padding: '5px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  dialogContent: {
    padding: 0,
  }
}));

const TooltipCustomized = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const EvaluationApplicationResultsStudents = props => {
  const { className, studentData, objectsData, ...rest } = props;
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [selectedChart, setSelectedChart] = useState(null);

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const [filterSelect, setFilterSelect] = useState(0);
  const [optionsFilterQuestions, setOptionsFilterQuestions] = useState([]);

  const [objectsDataFilter, setObjectsDataFilter] = useState([]);
  const [studentsDataFilter, setStudentsDataFilter] = useState([]);

  const handleOpenDialog = (student, chartType) => {
    setSelectedStudent(student);
    setSelectedChart(chartType);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    //setSelectedStudent(null);
    //setSelectedChart(null);
  };

  const handleChangeFilterQuestion = (event) => {
    setFilterSelect(event.target.value);
  };

  useEffect(() => {
    if(objectsData){
      setOptionsFilterQuestions([{idObject: 0, description: 'Todos'}, ...objectsData]);

      setObjectsDataFilter(objectsData);
      setStudentsDataFilter(studentData);
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(objectsData)) {
      const filterSelectInt = parseInt(filterSelect, 10)
      if(filterSelectInt === 0){
        setOptionsFilterQuestions([{idObject: 0, description: 'Todos'}, ...objectsData]);
        setObjectsDataFilter(objectsData);
        setStudentsDataFilter(studentData);
      } else {
        const filteredData = objectsData.filter(object => object.idObject === filterSelectInt);

        //verifica se os dados filtrados possui objetos associados
        if(filteredData.length > 0) {
          setObjectsDataFilter(filteredData);
          if(filteredData[0].decompositions.length > 0){
            setObjectsDataFilter(filteredData[0].decompositions);
          }
        }

        //filtra objetos a partir dos dados dos alunos
        const filtered = studentData
            .map(student => ({
              ...student,
              content: student.content.filter(contentItem => contentItem.id === filterSelectInt),
            }))
            .filter(student => student.content.length > 0);

        //verifica se tem registros para atualizar o dados dos estudantes
        if(filtered.length >0){
          if(filtered[0].content.length > 0){
            setStudentsDataFilter(filtered);
            if(filtered[0].content[0].decomposition.length >0 ){
              filtered.forEach(student => {
                student.content.forEach(cont => {
                  student.content = cont.decomposition
                  student.percentage_correct = cont.percentage_correct
                });
              });
              setStudentsDataFilter(filtered);
            }
          }

        }

      }

    }

  }, [filterSelect]);

  return (
      <div>
        <div>
          <Box display={"flex"} flexDirection={"column"} style={{marginBottom: '20px'}}>
            <label htmlFor="type-of-evaluation" className="form-label">
              {"Filtrar conteúdo"}
              <i className="fa-solid fa-filter" style={{marginLeft: '10px'}}></i>
            </label>
            <select
                id={"select-order"}
                className="form-select"
                value={filterSelect}
                onChange={handleChangeFilterQuestion}
                aria-describedby={"select-order"}
                style={{width: '270px'}}>
              {optionsFilterQuestions && optionsFilterQuestions.map((type) => (
                  <option key={type.idObject} value={type.idObject}>
                    {type.description ? type.description : type}
                  </option>
              ))}
            </select>
          </Box>
          <Grid container spacing={2}>
            {(studentData && objectsData && objectsData.length > 0 && studentData.length > 0) ? studentsDataFilter.map(student => (
                <Grid key={student.idStudent} item xs={12} sm={6} md={6}>
                  <Paper
                      style={{
                        height: '400px',
                        width: '100%',
                        paddingTop: '10px',
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                      }}
                  >
                    {/* Cabeçalho: nome à esquerda e porcentagem à direita */}
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{
                           padding: '15px',
                           background: student.percentage_correct < 30 ? '#ffcdd2'
                               : student.percentage_correct < 70 ? '#ffe0b2'
                                   : '#c8e6c9',}}>
                      <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                        {student.nameStudent}
                      </div>
                      <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                        {student.percentage_correct + '%'}
                      </div>
                    </Box>

                    {/* Gráfico de Radar */}
                    <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center"
                         onClick={() => handleOpenDialog(student, objectsDataFilter.length < 2 ? 'bar' : 'radar')}>
                      {objectsDataFilter.length < 3 ?
                          <BarChart
                              studentData={student}
                              data={objectsDataFilter}
                              content={`Desempenho de ${student.nameStudent}`}
                              width={90}
                              height={100}
                          />
                          :
                          <RadarChart
                              studentData={student}
                              data={objectsDataFilter}
                              content={`Desempenho de ${student.nameStudent}`}
                              width={350}
                              height={350}
                          />}
                    </Box>
                  </Paper>
                </Grid>
            )) : null}
          </Grid>
        </div>

        <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center"
                 style={{
                   padding: '15px',
                   background: selectedStudent?.percentage_correct < 30 ? '#ffcdd2'
                       : selectedStudent?.percentage_correct < 70 ? '#ffe0b2'
                           : '#c8e6c9',}}>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                {selectedStudent?.nameStudent + ' obteve '+ selectedStudent?.percentage_correct + '% de acerto na prova'}
              </div>
              <IconButton onClick={handleCloseDialog}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent className={classes.dialogContent}>
            <Box flexGrow={1} display="flex" alignItems="center" justifyContent="center">
              {selectedChart === 'bar' ? (
                  <BarChart
                      studentData={selectedStudent}
                      data={objectsDataFilter}
                      content={`Desempenho - Gráfico de Barras`}
                      width={600}
                      height={600}
                  />
              ) : (
                  <RadarChart
                      studentData={selectedStudent}
                      data={objectsDataFilter}
                      content={`Desempenho - Gráfico Radar`}
                      width={600}
                      height={600}
                  />
              )}
            </Box>
          </DialogContent>
        </Dialog>
      </div>
  );
};

EvaluationApplicationResultsStudents.propTypes = {
  className: PropTypes.string,
  studentData: PropTypes.array.isRequired,
  objectsData: PropTypes.array.isRequired,
};

export default EvaluationApplicationResultsStudents;
