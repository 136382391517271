import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination, Tooltip, Button, CircularProgress
} from '@material-ui/core';
import api from '../../../../services/api';
import { toast } from 'react-toastify';
import ObjectToolbar from "./components/ObjectDecompositionToolbar";
import PropTypes from "prop-types";
import {DialogQuestione} from "../../../../components";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  content: {
    padding: 0,
    marginTop: theme.spacing(2)
  },
  inner: {
    minWidth: 1050
  },
  headTable: {
    fontWeight: "bold"
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const ObjectDecompositionTable = props => {
  const { className, history } = props;

  const { codigoObject } = props.match.params;
  const [object, setObject] = useState(null);

  const [objectsDecompositions, setObjectsDecompositions] = useState([]);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [searchTextCourse, setSearchTextCourse] = useState('');
  const [searchTextRegulation, setSearchTextRegulation] = useState('');
  const [open, setOpen] = React.useState(false);
  const [idObjectDelete, setIdObjectDelete] = React.useState(0);

  const [loading, setLoading] = useState(false);

  async function loadObjectDecompositions(page){
    try {
      let url = 'content-decomposition?page='+page+'&fk_knowledge_objects_id='+codigoObject;

      const response = await api.get(url);
      if (response.status == 200) {
        setTotal(response.data.total);
        setObjectsDecompositions(response.data.data);
      } else {
        setTotal(0);
        setObjectsDecompositions(null);
      }

    } catch (error) {

    }
  }

  async function findAObject(id){
    try {
      const response = await api.get('object/show/'+id);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      } else {
        setObject(response.data[0]);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if(codigoObject){
      findAObject(codigoObject);
      loadObjectDecompositions(1);
    } else {
      toast.error('Conteúdo não encontrado.');
    }

  }, []);

  const onClickOpenDialog = (id) => {
    setIdObjectDelete(id);
    setOpen(true);
  }

  const onClickCloseDialog = () => {
    setOpen(false);
    setIdObjectDelete(0);
  }

  async function onDeleteObject(){
    try {
      let url = 'content-decomposition/'+idObjectDelete;
      const response = await api.delete(url);
      if (response.status === 200) {
        toast.success('Assunto excluído.');
        loadObjectDecompositions(page+1);
      } else {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }
    } catch (error) {

    }
    setOpen(false);
  }

  const onClickEdit = (id) => {
    history.push('/object-decomposition-details/'+codigoObject+"/"+id);
  }

  const onClickSearch = (e) => {
    setPage(0);
    loadObjectDecompositions(1);
  }

  const handlePageChange = (event, page) => {
    loadObjectDecompositions(page+1);
    setPage(page);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  async function onGenerateByAI(){
    setLoading(true); // Começa a mostrar o componente de carregamento
    try {
      const response = await api.post('content-decomposition/generate-by-llm?fk_knowledge_objects_id='+codigoObject);

      if (response.status === 200) {
        toast.success('Assuntos gerados por Inteligência Artificial.');
        loadObjectDecompositions(1);
      } else {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }
    } catch (error) {

    } finally {
      setLoading(false); // Para de mostrar o componente de carregamento
    }
    //onClickCloseDialog();
  }

  return (
    <div className={classes.root}>
      <ObjectToolbar onGenerateByAI={onGenerateByAI}/>
      <div className={classes.content}>
        <Card
            className={clsx(classes.root, className)}>
          <CardContent className={classes.content}>
            <PerfectScrollbar>
              {loading ? (
                  <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <CircularProgress />
                  </div>
              ) : (
                <div className={classes.inner}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.headTable}>Descrição</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {objectsDecompositions && objectsDecompositions.map(object => (
                          <TableRow
                              className={classes.tableRow}
                              hover
                              key={object.id}>
                            <TableCell>
                              <div className={classes.nameContainer}>
                                <Typography variant="body1">{object.description}</Typography>
                              </div>
                            </TableCell>
                            <TableCell className={classes.row}>
                              <Tooltip title="Deletar">
                                <Button
                                    className={classes.buttonDelete}
                                    onClick={() => onClickOpenDialog(object.id)}>
                                  <Delete fontSize="medium"/>
                                </Button>
                              </Tooltip>
                              <Tooltip title="Editar">
                                <Button
                                    className={classes.buttonEdit}
                                    onClick={() => onClickEdit(object.id)}>
                                  <Edit fontSize="medium"/>
                                </Button>
                              </Tooltip>
                            </TableCell>
                          </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
                  )}
            </PerfectScrollbar>
          </CardContent>
          <CardActions className={classes.actions}>
            <TablePagination
                component="div"
                count={total}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                page={page}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[10]}
            />
          </CardActions>
        </Card>
      </div>
      <DialogQuestione handleClose={onClickCloseDialog}
                       open={open}
                       onClickAgree={onDeleteObject}
                       onClickDisagree={onClickCloseDialog}
                       mesage={'Deseja excluir o assunto selecionado?'}
                       title={'Excluir assunto'}/>
    </div>
  );
};

ObjectDecompositionTable.propTypes = {
  history: PropTypes.object
};

export default ObjectDecompositionTable;
