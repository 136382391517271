import React from 'react';
import { Dialog, CircularProgress, Typography, Box } from '@material-ui/core';

function LoadingDialog({ open, text }) {
    return (
        <Dialog open={open} PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
                <CircularProgress style={{ marginBottom: '15px' }} />
                <Typography variant="h6" style={{ color: '#fff' }}>
                    {text}
                </Typography>
            </Box>
        </Dialog>
    );
}

export default LoadingDialog;
