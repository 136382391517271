import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Grid,
  Button,
  TextField, Box, Breadcrumbs, Link
} from '@material-ui/core';
import api from "../../../../services/api";
import { toast } from 'react-toastify';
import validate from "validate.js";
import useStyles from "../../../../style/style";
import {CharmHome} from "../../../../icons/Icons";
import BreadcrumbsComponent from "../../../../components/BreadcrumbsComponent/BreadcrumbsComponent";

const schema = {
  description: {
    presence: {allowEmpty: false, message: 'A descrição é obrigatória.'},
    length: {
      minimum: 2,
      maximum: 300,
      message: 'A descrição deve conter no mínimo 4 e no máximo 300 caracteres.'
    }
  }
};

const useStylesLocal = makeStyles(() => ({
  root: {},
  headTable: {
    fontWeight: "bold"
  },
  fab:{
    backgroundColor: '#009688',
    color: '#e0f2f1',
  },
  labelRed: {
    backgroundColor: '#EC0B43',
    display: 'block',
    margin: '10px',
    padding: '5px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
}));

const QuestionBankDetails = props => {
  const { className, history, ...rest } = props;
  const { codigoQuestionBank } = props.match.params;

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const [questionsBank, setQuestionsBank] = useState(null);
  const [refresh, setRefresh] = React.useState(0);


  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const breadcrumbItems = [
    { href: '/', icon: <CharmHome />, label: 'Início' },
    { href: '/questions-bank', label: 'Bancos de questões' },
    {  label: codigoQuestionBank ? 'Editar banco de código '+codigoQuestionBank : 'Novo banco de questões' }
  ];

  async function saveQuestionBankDetails(){
    try {
      const description = formState.values.description;

      const id = formState.values.id;
      const data = {
        description
      }
      let response= {};
      let acao = "";
      if(!id) {
         response = await api.post('questions-bank', data);
         acao = "cadastrado";
      } else {
         response = await api.put('questions-bank/'+id, data);
        acao = "atualizado";
      }
      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        } else if(response.data.errors[0].description){
          toast.error(response.data.errors[0].description);
        }
      } else {
        toast.success('Banco de questões '+acao+'.');
        history.push('/questions-bank');
      }

    } catch (error) {

    }
  }

  async function findAQuestionbank(id){
    try {

      const response = await api.get('questions-bank/show/'+id);
      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      } else {
        setFormState(formState => ({
          values: {
            'description': response.data.description,
            'id': response.data.id
          },
          touched: {
            ...formState.touched,
          }
        }));
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if(codigoQuestionBank){
      findAQuestionbank(codigoQuestionBank);
    } else {
      setQuestionsBank([]);
    }

  }, [refresh]);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: (errors || formState.values.course==0) ? false : true,
      errors: errors || {}
    }));
  }, [formState.values, questionsBank]);

  const handleChange = event => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    });
  };

  const hasError = field =>
      formState.touched[field] && formState.errors[field] ? true : false;

  return (
      <div
          {...rest}
          className={clsx(classesGeneral.root, className)}>
          <BreadcrumbsComponent items={breadcrumbItems} />
        <Card>
          <form
            autoComplete="off">
            <CardHeader
                subheader={<div className={classesGeneral.subtitleList}>{codigoQuestionBank ? 'Editar banco de questões' : 'Novo banco de questões'}</div>}
              title={<div className={classesGeneral.titleList}>{'Banco de Questões'}</div>}/>
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={1}>
                <Grid
                  item
                  md={12}
                  xs={12}>
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                    <TextField
                      fullWidth
                      error={hasError('description')}
                      helperText={
                        hasError('description') ? formState.errors.description[0] : null
                      }
                      label="Descrição"
                      margin="dense"
                      name="description"
                      onChange={handleChange}
                      value={formState.values.description || ''}
                      variant="outlined"
                    />
                  </div>
                  <Divider /><br />
                  <Box display="flex" justifyContent="center">
                    <Button
                        color="primary"
                        variant="outlined"
                        disabled={!formState.isValid}
                        onClick={saveQuestionBankDetails}>
                      Salvar
                    </Button>
                  </Box>
                </Grid>
                <Divider />
              </Grid>
            </CardContent>
            <Divider />
          </form>
        </Card>
      </div>
  );
};

QuestionBankDetails.propTypes = {
  className: PropTypes.string,
};

export default QuestionBankDetails;
