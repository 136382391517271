import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const AlertMessage = ({ message }) => {
    return (
        <div style={{
            backgroundColor: '#fff4e5',   // Fundo laranja suave
            color: '#d35400',            // Texto laranja escuro
            padding: '15px',             // Espaçamento interno
            border: '1px solid #f39c12', // Borda laranja suave
            borderRadius: '8px',         // Bordas arredondadas
            fontSize: '1.2em',           // Tamanho maior da fonte
            textAlign: 'center',         // Centralizar texto
            fontWeight: 'bold',          // Negrito para ênfase
            margin: '20px 0'             // Espaçamento acima e abaixo
        }}>
            <FontAwesomeIcon icon={faExclamationTriangle} style={{ marginRight: '10px' }} />
            {message}
        </div>
    );
};

export default AlertMessage;
