import React, {useState} from 'react';
import { Modal, Button } from 'react-bootstrap';
import EvaluationQuestionCard
    from "../../../../../Professor/Evaluation/EvaluationQuestions/components/EvaluationQuestionCard";

const OverviewQuestionModal = ({ show, order, handleClose, question }) => {
    const [showTable, setShowTable] = useState(false); // Estado para controlar a visibilidade da tabela

    const handleToggleTable = () => {
        setShowTable(!showTable); // Alterna a visibilidade da tabela
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >

            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {'Detalhes da questão '+order}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body
                style={{ maxHeight: '60vh', overflowY: 'auto' }} // Adjust maxHeight as needed
            >
                {question && question.contest.length ?
                    <div style={{marginBottom: '10px'}}>
                        <div
                            onClick={handleToggleTable}
                            style={{
                            color: '#d32f2f',  // Cor vermelha para chamar mais atenção
                            fontFamily: 'Verdana, sans-serif',
                            fontSize: '15px',
                            marginBottom: '10px',
                            padding: '5px',  // Adiciona espaçamento interno
                            backgroundColor: '#ffe6e6',  // Fundo claro para destacar
                            border: '1px solid #d32f2f',  // Borda para evidenciar
                            borderRadius: '5px',  // Arredonda os cantos
                            fontWeight: 'bold',  // Negrito para dar destaque
                            display: 'flex',
                            alignItems: 'center',
                        }}>
                            <span style={{marginRight: '8px'}}>⚠️</span>
                            {question.contest.length === 1 ?
                                question.contest.length + ' aluno contestou esta questão. Clique aqui para visualizar.' :
                                question.contest.length + ' alunos contestaram esta questão. Clique aqui para visualizar.'}
                        </div>
                        {/* Renderiza a tabela se showTable for true */}
                        {showTable && (
                            <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                                <thead>
                                <tr>
                                    <th style={{ border: '1px solid #d32f2f', padding: '8px' }}>Aluno</th>
                                    <th style={{ border: '1px solid #d32f2f', padding: '8px' }}>Local</th>
                                    <th style={{ border: '1px solid #d32f2f', padding: '8px' }}>Contestação</th>
                                </tr>
                                </thead>
                                <tbody>
                                {question.contest.map((contestacao, index) => (
                                    <tr key={index}>
                                        <td style={{ border: '1px solid #d32f2f', padding: '8px' }}>{contestacao.answer.head.user.name}</td>
                                        <td style={{ border: '1px solid #d32f2f', padding: '8px' }}>{contestacao.contest}</td>
                                        <td style={{ border: '1px solid #d32f2f', padding: '8px' }}>{contestacao.description}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                    : null}
                {question ? (
                    <EvaluationQuestionCard
                        question={question}
                        hasApplication={1}
                    />
                ) : (
                    <p>Nenhuma questão selecionada.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default OverviewQuestionModal;
