import React, { useState, useEffect } from 'react';
import {
    Dialog, DialogActions, DialogContent, DialogTitle,
    Button, Switch, Collapse, List, ListItem, FormControlLabel, Typography, Box, Grid
} from '@material-ui/core';
import TooltipQuestione from "../../../../../components/TooltipQuestione";
import ShareIcon from "@material-ui/icons/Share";

export default function DialogShareEvaluation({
                                                  open,
                                                  handleClose,
                                                  saveData,
                                                  share_evaluation = false,
                                                  can_view_student_name = false
                                              }) {
    // Estados locais para controlar os valores do formulário
    const [checkedShareEvaluation, setCheckedShareEvaluation] = useState(share_evaluation);
    const [checkedCanViewStudentName, setCheckedCanViewStudentName] = useState(can_view_student_name);

    // useEffect para atualizar os estados quando os props mudarem
    useEffect(() => {
        setCheckedShareEvaluation(share_evaluation);
        setCheckedCanViewStudentName(can_view_student_name);
    }, [share_evaluation, can_view_student_name]);

    // Funções de manipulação para os switches
    const handleChangeShareEvaluation = (event) => {
        setCheckedShareEvaluation(event.target.checked);
    };

    const handleChangeCanViewStudentName = (event) => {
        setCheckedCanViewStudentName(event.target.checked);
    };

    // Função para salvar os dados
    const handleSave = () => {
        const data = {
            public_results: checkedShareEvaluation,
            can_see_students: checkedCanViewStudentName,
        };
        saveData(data); // Envia os dados para a função de salvar
        handleClose();  // Fecha o diálogo
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="subtitle1" color="textPrimary">
                    Configurações de Compartilhamento de Resultados
                </Typography>
            </DialogTitle>
            <DialogContent>
                <div style={{
                    padding: '10px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '4px',
                    color: '#555',
                    fontSize: '14px',
                    lineHeight: '1.6',
                    marginBottom: '16px',
                    fontStyle: 'italic'
                }}>{'Caso esta opção esteja habilitada, você poderá compartilhar os '+
                    'resultados deste simulado com outros professores por meio de um link. ' +
                    'O link fica disponível no ícone '} <ShareIcon />
                    {' ao lado do botão de habilitar o simulado.'}</div>
                <Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={checkedShareEvaluation}
                                onChange={handleChangeShareEvaluation}
                                name="share_evaluation"
                                color="primary"
                            />
                        }
                        label="Clique para gerar link para compartilhar resultados"
                    />

                    <Collapse in={checkedShareEvaluation} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem>
                                <FormControlLabel
                                    control={
                                        <Switch
                                            checked={checkedCanViewStudentName}
                                            onChange={handleChangeCanViewStudentName}
                                            name="can_view_student_name"
                                            color="primary"
                                        />
                                    }
                                    label="Habilite para permitir visualização do nome dos alunos."
                                />
                            </ListItem>
                        </List>
                    </Collapse>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleSave} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
