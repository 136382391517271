import React from 'react';

const ExplanatoryMessage = ({ message }) => {
    return (
        <div
            style={{
                padding: '10px',
                backgroundColor: '#f0f0f0',
                borderRadius: '4px',
                color: '#555',
                fontSize: '14px',
                lineHeight: '1.6',
                marginBottom: '16px',
                fontStyle: 'italic',
            }}
        >
            {message}
        </div>
    );
};

export default ExplanatoryMessage;
