import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Typography, Link, Divider} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
        <div className={classes.logoImage}>
            <Divider style={{marginTop: '10px', marginBottom: '20px'}}/>

            <Link
                component="a"
                href="https://www.ifce.edu.br/"
                target="_blank">
                <img
                    alt="Logo"
                    src="/images/ifce.png"
                    style={{
                        width: '150px', // Define a largura da imagem
                        height: 'auto'  // Mantém a proporção original da imagem
                    }}/>
            </Link>
            <Typography style={{marginLeft: '10px'}} variant="body1">
                <Link
                    component="a"
                    href="https://www.ifce.edu.br/"
                    target="_blank">
                    IFCE
                </Link>
                . 2024.
            </Typography>
        </div>

    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
