import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import api from "../../services/api";
import {toast} from "react-toastify";

const EmailDialog = ({ show, handleClose, areas }) => {
    const [senderName, setSenderName] = useState('');
    const [selectedArea, setSelectedArea] = useState('');
    const [selectedContent, setSelectedContent] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        // Reset form fields
        setSenderName('');
        setSelectedArea('');
        setSelectedContent('');
        setMessage('');
        handleClose();
    };

    const isFormValid = () => {
        return selectedArea && selectedContent && message; // Check if all fields are filled
    };

    async function send(){

        try {
            const area = areas.find(a => a.id === Number(selectedArea));
            const description_area = area ? area.description : "";
            const content_description = selectedContent;
            const topics = message;
            const data = {
                description_area, content_description, topics
            }
            const response = await api.post('all/request-add-topic', data);

            if (response.status === 200) {
                toast.success('Solicitação enviada.');
            } else {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            }

        } catch (error) {

        }
        handleClose();
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Enviar Solicitação</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="senderName">
                        <Form.Label>Solicitante</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your name"
                            value={localStorage.getItem("@Questione-name-user")}
                            disabled={true}
                        />
                    </Form.Group>

                    <Form.Group controlId="area">
                        <Form.Label>Selecione a Área</Form.Label>
                        <Form.Control
                            as="select"
                            value={selectedArea}
                            onChange={(e) => setSelectedArea(e.target.value)}
                            required
                        >
                            {areas &&
                                areas.map((qb) => (
                                    <option key={qb.id} value={qb.id}>
                                        {qb.description}
                                    </option>
                                ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="content">
                        <Form.Label>Informe o Conteúdo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Informe o nome do conteúdo."
                            value={selectedContent}
                            onChange={(e) => setSelectedContent(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="message">
                        <Form.Label>Tópicos</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Informe quais tópicos você deseja que adicione ao conteúdo, separe-os por ;"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            required
                        />
                    </Form.Group>

                    <div className="d-flex justify-content-end mt-3"> {/* Flexbox for right alignment */}
                        <Button
                            variant="primary"
                            type="submit"
                            disabled={!isFormValid()} // Disable if form is not valid
                            onClick={send}
                        >
                            Enviar
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EmailDialog;
