import React from 'react';

const InfoMessage = ({ text }) => {
    return (
        <div style={{
            display: 'flex', // Exibe o ícone e o texto em linha
            alignItems: 'center', // Centraliza verticalmente o ícone e o texto
            fontSize: '12px', // Texto menor
            color: '#856404', // Cor do texto (marrom escuro)
            backgroundColor: '#fff3cd', // Fundo amarelo claro para destaque
            padding: '8px', // Espaçamento interno
            borderRadius: '4px', // Bordas arredondadas
            marginBottom: '10px', // Espaçamento abaixo da mensagem
        }}>
            <img
                src="https://img.icons8.com/fluency/20/000000/info.png"
                alt="info-icon"
                style={{ marginRight: '8px' }} // Espaçamento à direita do ícone
            />
            {text}
        </div>
    );
};

export default InfoMessage;
