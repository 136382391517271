import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Box, Button, Card, CardContent, MenuItem, TextField, useMediaQuery} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import useStyles from "../../../../../../style/style";
import {CharmHome} from "../../../../../../icons/Icons";
import BreadcrumbsComponent from "../../../../../../components/BreadcrumbsComponent/BreadcrumbsComponent";

const useStylesLocal = makeStyles(theme => ({
  textField: {
    marginLeft: theme.spacing(1),
  },
}));

const QuestionsBankToolbar = props => {
  const { className, onClickSearch, onChangeSearch, onClean, searchText, history, setStatus, ...rest } = props;

  const classes = useStylesLocal();
  const classesGeneral = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const [value, setValue] = useState(1);

  const breadcrumbItems = [
    { href: '/', icon: <CharmHome />, label: 'Início' },
    { label: 'Bancos de Questões' },
  ];

  const onClickRequestUsers = () => {
    history.push('/evaluations-archive');
  }

  const onClickNewBank = e => {
    history.push('/question-bank');
  }

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <BreadcrumbsComponent items={breadcrumbItems} />
      <Card >
        <CardContent>
          <Box display="flex">
            <Box display="flex" justifyContent="flex-start">
              <div style={{
                backgroundColor: '#f0f4ff',  // Azul suave
                color: '#004080',            // Azul escuro para o texto
                padding: '10px 20px',        // Espaçamento interno
                borderLeft: '5px solid #004080', // Barra à esquerda para destaque
                fontSize: '1.5em',           // Aumentar o tamanho da fonte
                fontWeight: 'bold',          // Negrito para chamar atenção
                borderRadius: '8px',         // Bordas arredondadas para suavizar o visual
                boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', // Sombra leve
                marginBottom: '20px'         // Espaçamento abaixo do título
              }}>
                {'Meus Bancos de Questões'}
              </div>
            </Box>
            <Box display="flex" sx={{flexGrow: 1}} justifyContent="flex-end">
              {!isSmallScreen &&
                  <Button
                      color="primary"
                      variant="contained"
                      onClick={onClickNewBank}
                      className={classesGeneral.buttons}
                      style={{height: '35px'}}>
                    <i className="fa-solid fa-database" style={{fontSize: '19px', marginRight: '10px'}}></i>
                    {' Novo Banco'}
                  </Button>}
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-start" style={{marginTop: '25px'}}>

            <TextField
                label="Buscar"
                className={classes.textField}
                helperText="Buscar por descrição"
                margin="dense"
                onChange={onChangeSearch}
                value={searchText}
                style={{ width: '300px' }}
                variant="outlined"
            />
          </Box>
          <Box display="flex" justifyContent={isSmallScreen ? "center" : "center"} style={{marginTop: '10px'}}>

            <Button variant="contained" color="primary" className={classesGeneral.buttons} onClick={onClickSearch}>
              {isSmallScreen ? "Filtrar" : "Filtrar bancos"}
            </Button>
            <div style={{marginLeft: '10px'}}>
              <Button variant="contained"className={classesGeneral.buttons} onClick={onClean}>
                {isSmallScreen ? "Limpar" : "Limpar Filtro"}
              </Button>
            </div>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};

QuestionsBankToolbar.propTypes = {
  className: PropTypes.string,
  onChangeSearch: PropTypes.func,
  onClickSearch: PropTypes.func,
  searchText: PropTypes.string,
  history: PropTypes.object
};

export default withRouter(QuestionsBankToolbar);
