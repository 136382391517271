import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tab,
  Tabs,
  Box,
  Typography,
  AppBar,
  Tooltip,
  LinearProgress,
  Breadcrumbs,
  Link,
  Chip,
  Hidden,
  Button,
  TableContainer,
  Paper,
  IconButton, Collapse, FormControlLabel, Checkbox
} from '@material-ui/core';
import api from "../../../../../services/api";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Done, Close, Block, ExpandLess, ExpandMore} from "@material-ui/icons";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import EvaluationApplicationResultsSkillObjects from "./EvaluationApplicationResultsSkillObjects";
import { toast } from 'react-toastify';
import { removeDestionationPath } from '../../../../../services/navigation';
import useStyles from "../../../../../style/style";
import {CharmHome} from "../../../../../icons/Icons";
import EvaluationQuestionCard
  from "../../../../Professor/Evaluation/EvaluationQuestions/components/EvaluationQuestionCard";
import {formatStudentName} from "../../../../../common/formatStudentName";
import moment from 'moment';
import 'moment/locale/pt-br';
import OverviewQuestionModal from "./OverviewQuestionModal/OverviewQuestionModal";
import CustomSelect from "../../../../../components/CustomSelect";
import {Alert} from "@material-ui/lab";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import QuestionText from "../../../../../components/QuestionText";
import QuestionSkill from "../../../../Professor/Question/QuestionDetails/QuestionSkill";
import RadarChart from "./EvaluationApplicationResultsStudents/RadarChart/RadarChart";
import EvaluationApplicationResultsStudents from "./EvaluationApplicationResultsStudents";

moment.locale('pt-br');

const useStylesLocal = makeStyles(() => ({
  root: {
    margin: 10,
  },
  content: {
    padding: 0
  },
  headStudent: {
    width: '100px ',
    height: '115px',
    backgroundColor: '#FFF',
    color: '#393A68',
    paddingLeft: '12px',
    boxSizing: 'border-box',
    border: '1px solid #f2f2f2',
    lineHeight: '40px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial'
  },
  headPercentage: {
    width: '30px',
    height: '90px',
    backgroundColor: '#FFF',
    color: '#393A68',
    paddingLeft: '12px',
    boxSizing: 'border-box',
    border: '1px solid #f2f2f2',
    lineHeight: '40px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial'
  },
  bodyStudent: {
    maxWidth: '170px',
    width: '100px',
    height: '82px',
    backgroundColor: '#FFF',
    color: '#393A68',
    paddingLeft: '6px',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    border: '1px solid #f2f2f2',
    lineHeight: '20px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial',
  },
  bodyPercentage: {
    width: '20%',
    height: '82px',
    textAlign: 'center',
    backgroundColor: '#FFF',
    color: '#393A68',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    border: '1px solid #f2f2f2',
    lineHeight: '15px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial'
  },
  headQuestion: {
    width: '90px',
    backgroundColor: '#FFF',
    color: '#393A68',
    textAlign: 'center',
    height: '115px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  percentageRed: {
    backgroundColor: '#F14D76',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageOrange: {
    backgroundColor: '#F5A623',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageGreen: {
    backgroundColor: '#5DE2A5',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageNull: {
    backgroundColor: '#90a4ae',
    color: '#fff',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    borderRadius: 4
  },
  answerCorrect: {
    width: '90.0px',
    backgroundColor: '#5DE2A5',
    //display: 'inline-block',
    color: '#ffffff',
    textAlign: 'center',
    height: '82px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '25px',
  },
  answerIncorrect: {
    width: '90.0px',
    backgroundColor: '#F14D76',
    //display: 'inline-block',
    color: '#ffffff',
    textAlign: 'center',
    height: '82px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '25px',
  },
  answerNull: {
    width: '90.0px',
    backgroundColor: '#cfd8dc',
    //display: 'inline-block',
    color: '#393A68',
    textAlign: 'center',
    height: '82px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  paperWrong: {
    width: '88%',
    backgroundColor: '#ef9a9a',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  paperRight: {
    width: '88%',
    backgroundColor: '#80cbc4',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  labelStudent: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`nav-tabpanel-${index}`}
          aria-labelledby={`nav-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
      <Tab
          component="a"
          onClick={(event) => {
            event.preventDefault();
          }}
          {...props}
      />
  );
}

const TooltipCustomized = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const shuffleArray = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  // Enquanto houver elementos para embaralhar...
  while (currentIndex !== 0) {
    // Escolha um elemento restante...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // E troque-o com o elemento atual
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

const EvaluationApplicationResults = props => {
  removeDestionationPath();

  const { className, history, location, ...rest } = props;
  
  const { idApplication, studentClassId } = props.match.params;

  const [ avgCorrectQuestions, setAvgCorrectQuestions ] = useState(0);
  const [ totalVarianceQuestions, setTotalVarianceQuestions ] = useState(0);
  const [ totalVarianceStudents, setTotalVarianceStudents ] = useState(0);
  const [ answerStudentsOriginal, setAnswerStudentsOriginal] = useState(null);
  const [ answerStudents, setAnswerStudents ] = useState(null);
  const [ overviewQuestions, setOverviewQuestions ] = useState(null);
  const [ overviewQuestionsHead, setOverviewQuestionsHead ] = useState([]);
  const [ objects, setObjects ] = useState(null);
  const [ questionsWithouContent, setQuestionsWithouContent ] = useState([]);
  const [ skills, setSkills ] = useState(null);
  const [ expanded, setExpanded] = React.useState(false);
  const [ value, setValueTab] = React.useState(0);

  const [classProfessor, setClassProfessor] = useState(null);

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const [showModalOverviewQuestion, setShowModalOverviewQuestion] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [orderQuestion, setOrderQuesiton] = useState(null);
  const [orderQuestionSelect, setOrderQuesitonSelect] = useState(1);
  const [orderStudentSelect, setOrderStudentSelect] = useState(1);
  const [filterSelect, setFilterSelect] = useState(0);

  const [optionsFilterQuestions, setOptionsFilterQuestions] = useState([]);
  // Dados do aluno
  const [studentData, setStudentData] = useState([]);

  const fakeNames = [
    // Computação
    "Zé das Tarefas", "João Sem Bug", "Maria Variável", "Pedro Byte", "Carmen Debug",
    "Chico Algoritmo", "Bia Looper", "Teca Recursiva",
    // Matemática
    "Logaritmo", "Equação Suprema", "Função Delta", "Isa Tangente",
    "Trigonométrico", "Pi Infinito", "Gabriela Derivada", "Integralzada",
    "Números Primos", "Limite Infinito", "Teorema de Pitágoras", "Ângulo Reto",
    "Divisor de Zeros", "Delta Final", "Beta de Euler",
    // Física
    "Energia Cinética", "Gravidade Forte", "Velocidade da Luz", "Magnetismo Puro",
    "Força de Newton", "Inércia Total", "Campo Elétrico", "Onda Partícula",
    // Química
    "Teca Moleculosa", "Molécula Misteriosa", "Tabela Periódica", "Ácido Base",
    "Reação Exotérmica", "Catalisador", "Liga Metálica", "Eletronegatividade", "Oxidação Forte",
    "Reagente Puro", "Elemento Estável",
    // Geografia
    "Monte Everest", "Cordilheira dos Andes", "Planície Aberta", "Deserto de Gelo", "Cratera Gigante",
    "Latitude Alta", "Clima Tropical",  "Deserto Sahara", "Círculo Polar",  "Oceano Atlântico",
    "Bacia Hidrográfica", "Altitude Máxima", "Coordenada X", "Continente Africano",
    // História
    "Idade Antiga", "Guerreiro Espartano", "Revolução Francesa", "Renascimento Total",
    "Império Romano", "Napoleônico", "Faraó Egípcio", "Mesopotâmico", "Guerra Fria",
    "Reforma Protestante", "Imperador de Aço",
    // Português
    "Crase Certa", "Sujeito Oculto", "Verbo Transitório", "Metáfora Viva",
    "Semântica Forte", "Predicado Verbal", "Parágrafo Perfeito",
    "Concordância Verbal", "Complemento Nominal", "Adjetivado", "Prosa Poética",
    "Metonímia Viva", "Símbolo Literário", "Substantivo Oculto",
    // Biologia
    "Mitocôndria Master", "Citoplasma", "Genótipo Puro", "Clorofila Verde",
    "Ecosistema", "Célula Mãe", "DNA Helicoidal", "Espécie Única",
    "Taxonomia Alta",
    // Artes
    "Escultura Antiga", "Pintura Renascença", "Ritmo Harmônico", "Perspectiva Forte",
    "Cor Complementar", "Sinfonia Completa", "Aquarela Leve", "Teatro Moderno",
    "Abstracionismo", "Classicismo",
    // Filosofia
    "Razão Absoluta", "Idealismo Puro", "Existencialista", "Dialética Filosófica",
    "Ética Virtuosa", "Metafísica Total", "Lógica Formal", "Epistemológico",
    "Sócrates", "Utopista Platônico", "Argumento Lógico", "Paradoxo Simples",
    "Silogismo Infinito", "Pensador Moderno", "Teoria Crítica", "Doutrina Ancestral",
    //Engenharia
    "Resistência Soldada", "Zeca da Solda",
    "Circuito Fechado", "Fluído de Newton", "Servo Motor",
    "Marta Sensorial", "Logic Gate", "Força Mecânica", "Cálculo Estrutural",
  ];


  const optionsOrderQuestions = [
    {
      id: 1,
      description: "Ordem de cadastro"
    },
    {
      id: 2,
      description: "Maior desempenho primeiro"
    },
    {
      id: 3,
      description: "Menor desempenho primeiro"
    },
    {
      id: 4,
      description: "Maior coeficiente de variação"
    },
    {
      id: 5,
      description: "Menor coeficiente de variação"
    }

  ];

  const optionsOrderStudents = [
    {
      id: 1,
      description: "Ordem alfabética"
    },
    {
      id: 2,
      description: "Maior desempenho primeiro"
    },
    {
      id: 3,
      description: "Menor desempenho primeiro"
    },
    {
      id: 4,
      description: "Maior coeficiente de variação"
    },
    {
      id: 5,
      description: "Menor coeficiente de variação"
    },
  ];
  const [hideRealNames, setHideRealNames] = useState(false);

  const handleCheckboxChange = () => {
    setHideRealNames(!hideRealNames);
  };

  const handleChangeFilterQuestion = (event) => {
    setFilterSelect(event.target.value);
  };
  const handleChangeStudentQuestion = (event) => {
    setOrderStudentSelect(event.target.value);
  };

  const handleChangeOrderQuestion = (event) => {
    setOrderQuesitonSelect(event.target.value);
  };

  const handleOpenModalOverviewQuestion = (question, orderQuestion) => {
    setOrderQuesiton(orderQuestion);
    setCurrentQuestion(question);
    setShowModalOverviewQuestion(true);
  };

  const handleCloseModalOverviewQuestion = () => {
    setShowModalOverviewQuestion(false);
  };

  

  async function findResultsSkill(id){
    try {
      const response = await api.get('/evaluation/applications/result-percentage-question-by-skill/'+id);
      if (response.status === 200) {
        setSkills(response.data);
      } else {
        setSkills([]);
      }
    } catch (error) {

    }
  }

  async function loadContents(id){
    try {
      const response = await api.get('/evaluation/applications/content-evaluation/'+id);
      if (response.status === 200) {
        setOptionsFilterQuestions([{id: 0, description: 'Todas'}, ...response.data]);
      } else {
        setOptionsFilterQuestions([]);
      }
    } catch (error) {

    }
  }

  async function findQuestionsWithoutContent(id){
    try {
      const response = await api.get('/evaluation/applications/result-question-without-content/'+id);
      if (response.status === 200) {
        setQuestionsWithouContent(response.data);
      } else {
        setQuestionsWithouContent([]);
      }
    } catch (error) {

    }
  }

  async function findResultsObjects(id){
    try {
      const response = await api.get('/evaluation/applications/result-percentage-question-by-objects/'+id);
      if (response.status === 200) {
        setObjects(response.data);
      } else {
        setObjects([]);
      }
    } catch (error) {

    }
  }

  async function findOverviewQuestions(id){
    try {
      const response = await api.get('/evaluation/applications/result-percentage-question/'+id);

      if (response.status === 200) {
        setOverviewQuestions(response.data[0].questions);

        setOverviewQuestionsHead(response.data[0]);

        setTotalVarianceQuestions(response.data[0].variance_total);

        setOrderQuesitonSelect(1);
      } else {
        setOverviewQuestions([]);
      }
    } catch (error) {

    }
  }

  async function findResults(id){
    try {
      const response = await api.get('/evaluation/applications/result-answer-students/'+id);

      if (response.status === 200) {

        setAnswerStudents(response.data.students);
        setAnswerStudentsOriginal(response.data.students);
        setTotalVarianceStudents(response.data.variance_total);
        setAvgCorrectQuestions(response.data.avg_correct_question);
        setOrderStudentSelect(1);

      } else if (response.status === 202) {
        history.push(`/student-class/${studentClassId}`);
        toast.error(response.data.message);
      } else {
        setAnswerStudents([]);
      }

    } catch (error) {
      
    }
  }

  useEffect(() => {
    if (Array.isArray(overviewQuestions)) {
      const questionsCopy = [...overviewQuestions];
      const filterSelectInt = parseInt(filterSelect, 10)
      if(filterSelectInt === 0){
        questionsCopy.forEach(question => {
          question.hidden = false;
        });
        setOverviewQuestions(questionsCopy);
        answerStudents.forEach(student => {
          student.questions.forEach(questionInAnswer => {
              questionInAnswer.hidden = false;
          });
        });

        return ;
      }

      const filteredQuestions = questionsCopy.filter(question =>
          question.knowledge_objects.some(ko => ko.id === filterSelectInt)
      );

      questionsCopy.forEach(question => {
        question.hidden = !filteredQuestions.includes(question);
      });
      answerStudents.forEach(student => {
        student.questions.forEach(questionInAnswer => {
          // Procurando a questão correspondente em questionsCopy
          const matchingQuestion = questionsCopy.find(question => question.id === questionInAnswer.questionId);

          // Se encontrar a questão correspondente, atualiza o atributo hidden
          if (matchingQuestion) {
            questionInAnswer.hidden = matchingQuestion.hidden;
          }
        });
      });

      setOverviewQuestions(questionsCopy);
    }
  }, [filterSelect]);

  useEffect(() => {
    if (Array.isArray(overviewQuestions)) {
      // Cria uma cópia do array para evitar mutações diretas
      const questionsCopy = [...overviewQuestions];

      const opcao = parseInt(orderQuestionSelect, 10);
      if (opcao === 1) {
        questionsCopy.sort((a, b) => a.order - b.order);
      } else if (opcao === 2) {
        questionsCopy.sort((a, b) => b.percentage_correct - a.percentage_correct); // maior desempenho primeiro
      } else if (opcao === 3) {
        questionsCopy.sort((a, b) => a.percentage_correct - b.percentage_correct); // menor desempenho primeiro
      } else if (opcao === 4) {
        questionsCopy.sort((a, b) => b.cv - a.cv); // menor desempenho primeiro
      } else if (opcao === 5) {
        questionsCopy.sort((a, b) => a.cv - b.cv); // menor desempenho primeiro
      }



      // Atualiza o estado com o array ordenado
      setOverviewQuestions(questionsCopy);

      const sortedQuestionIds = questionsCopy.map(question => question.id);
      answerStudents.forEach(student => {
        student.questions.sort((a, b) => {
          return sortedQuestionIds.indexOf(a.questionId) - sortedQuestionIds.indexOf(b.questionId);
        });
      });
    }
  }, [orderQuestionSelect]);

  useEffect(() => {
    if (Array.isArray(answerStudents)) {
      // Cria uma cópia do array para evitar mutações diretas
      const studentsCopy = [...answerStudents];

      const opcao = parseInt(orderStudentSelect, 10);
      if (opcao === 1) {
        studentsCopy.sort((a, b) => {
          return a.student.localeCompare(b.student);
        });
      } else if (opcao === 2) {
        studentsCopy.sort((a, b) => {
          return b.t_correct - a.t_correct;
        });
      } else if (opcao === 3) {
        studentsCopy.sort((a, b) => {
          return a.t_correct - b.t_correct;
        });
      } else if (opcao === 4) {
        studentsCopy.sort((a, b) => {
          return b.cv - a.cv ;
        });
       } else if (opcao === 5) {
        studentsCopy.sort((a, b) => {
          return a.cv - b.cv;
        });
      }

      // Atualiza o estado com o array ordenado
      setAnswerStudents(studentsCopy);
    }
  }, [orderStudentSelect]);

  useEffect(() => {
    // Converte o array 'content' para o formato desejado
    if (answerStudents != null) {
      const formattedData = answerStudents.map(item => ({
        idStudent: item.id_student,
        nameStudent: item.student,
        percentage_correct: item.percentage_correct,
        content: item.content.map(content => ({
          id: content.idObject,
          description: content.description,
          total_questions: content.total_questions,
          percentage_correct: parseFloat(content.percentage_correct),
          decomposition: content.decompositions.map(decom => ({
            id: decom.decomposition.id,
            description: decom.decomposition.description,
            total_questions: decom.total_questions,
            percentage_correct: parseFloat(decom.percentage_correct)
          }))
        }))

      }));
      setStudentData(formattedData);
    }


  }, [answerStudents]);

  useEffect(() => {
    if(idApplication){
      findResults(idApplication);
      findOverviewQuestions(idApplication);
      findResultsSkill(idApplication);
      findResultsObjects(idApplication);
      findQuestionsWithoutContent(idApplication);
      loadContents(idApplication);
    }

  }, []);

  useEffect(() => {
    if(answerStudents){
      if(hideRealNames) {
        shuffleArray(fakeNames);

        const updatedStudents = answerStudents.map((student, index) => ({
          ...student,
          student: hideRealNames ? fakeNames[index] : student.student
        })).sort((a, b) => a.student.localeCompare(b.student));

        setAnswerStudents(updatedStudents);
      } else {
        setAnswerStudents(answerStudentsOriginal);
        setOrderStudentSelect(0);
        setOrderQuesitonSelect(0);
        setFilterSelect(0);
      }

    }
  }, [hideRealNames]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const handleBack = () => {
    history.goBack();
  };

  const [openIncludeContent, setOpenIncludeContent] = useState(false);
  const [expandedRowQuestionContent, setExpandedRowQuestionContent] = useState(null);

  const handleClickOpenIncludeContent = () => {
    setOpenIncludeContent(true);
  };

  const handleCloseIncludeContent = () => {
    setOpenIncludeContent(false);
    setExpandedRowQuestionContent(null);
    setQuestionSelected(null);
    findQuestionsWithoutContent(idApplication);
    findResultsObjects(idApplication);
  };

  const handleExpandClickQuestioContent = (id) => {
    setExpandedRowQuestionContent(expandedRowQuestionContent === id ? null : id);
  };

  const [questionSelected, setQuestionSelected] = useState(null);

  const handleQuestionSelect = (question) => {
    setQuestionSelected(question);
  };

  return (
      <div className={classesGeneral.root}>
        {studentClassId &&
            <Box display="flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/">
                <Box display="flex">
                  <Box style={{marginTop: '2px', marginRight: '5px'}}>
                    <CharmHome />
                  </Box>
                  <Box>
                    Início
                  </Box>
                </Box>
              </Link>
              <Link color="inherit" onClick={() => history.push('/student-class/professor')}>
                Turmas
              </Link>
              <Link color="inherit" onClick={() => history.goBack()}>
                Turma
              </Link>
              <div color="inherit">
                {overviewQuestionsHead ? 'Resultado do simulado '+overviewQuestionsHead.description_application : 'Resultado do simulado'}
              </div>

            </Breadcrumbs>
          </Box>}
        <Card>

          <CardHeader
              subheader={<div className={classesGeneral.subtitleList}>{'O resultado do simulado permite visualizar os acertos e erros dos estudantes de uma forma geral, por questão e por conteúdo.'}</div>}
              title={<div className={classesGeneral.titleList}>{'Resultado do simulado '}</div>}/>
          <Divider />
          <Card className={classes.root}>
            {overviewQuestionsHead.idApplication!= null ?
              <CardContent>
                {/*<Typography variant="h5" color="textPrimary" component="p">
                  {'Código da aplicação: '+overviewQuestionsHead.idApplication +'.'}
            </Typography>*/}

                <div>
                  <div className={classesGeneral.subtitleList} style={{fontWeight: 'bold'}}>
                    {'Simulado: ' + overviewQuestionsHead.description_application + '.'}
                  </div>
                  <div className={classesGeneral.subtitleList}>
                    {overviewQuestionsHead.class ? 'Professor(a): ' + overviewQuestionsHead.class.user.name + '.' : 'Professor(a): ' + localStorage.getItem("@Questione-name-user") + '.'}
                  </div>
                  <div className={classesGeneral.subtitleList}>
                    {overviewQuestionsHead.class.description != null ?
                        'Turma: ' + overviewQuestionsHead.class.id_class + ' - ' + overviewQuestionsHead.class.description + '.' : null}
                  </div>
                  <div className={classesGeneral.subtitleList}>
                    {overviewQuestionsHead.percentagem_geral_correct_evaluation != 0 &&
                        'Em média ' + overviewQuestionsHead.percentagem_geral_correct_evaluation + '% questões foram respondias corretas.'}
                  </div>
                  <div className={classesGeneral.subtitleList}>
                    {overviewQuestionsHead.qtdQuestions > 1 ?
                        'Este simulado possui ' + overviewQuestionsHead.qtdQuestions + ' questões.' :
                        'Este simulado possui ' + overviewQuestionsHead.qtdQuestions + ' questão.'}
                  </div>
                  {/*<RadarChart data={data} studentData={studentData}  content={"Gráfico radar"} />*/}

                </div>
                {answerStudents == null ? null :
                    !answerStudents[0] ?
                        <span className={classes.percentageRed}>SEM RESULTADO</span>
                        : null}
              </CardContent> : null}
          </Card>
          {answerStudents == null ?
              <LinearProgress color="secondary"    />
              :
            answerStudents[0] ?
            <CardContent className={classes.content}>
              {questionsWithouContent && questionsWithouContent.length > 0 ?
                  <div>
                    <Alert severity="warning" style={{padding: '5px', margin: '10px', fontWeight: 'bold', fontSize: '15px'}}>
                        {`Esta avaliação possui ${questionsWithouContent.length} ${questionsWithouContent.length === 1 ? 'questão' : 'questões'} sem conteúdo/assunto.`}
                      <Link
                          onClick={handleClickOpenIncludeContent}
                          style={{ cursor: 'pointer', marginLeft: '10px' }}>
                        {`Clique aqui para incluir os conteúdos/assuntos pendentes.`}
                      </Link>
                    </Alert>
                    <Dialog
                        open={openIncludeContent}
                        onClose={handleCloseIncludeContent}
                        fullWidth
                        maxWidth="md">
                      <DialogTitle>Incluir Conteúdos Pendentes</DialogTitle>
                      <DialogContent>
                        {questionSelected
                            ?
                            <div>
                              <div style={{border: '2px solid #2771d8', padding: '5px'}}>
                                <div style={{
                                  textAlign: 'center',
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  backgroundColor: '#e3f2fd',  // Azul claro
                                  padding: '10px',
                                  borderRadius: '4px'
                                }}>
                                  Informe os conteúdos/assuntos da questão e clique em salvar:
                                </div>
                                <QuestionSkill idQuestion={questionSelected.id} showArea={false} tabValueChange={0}/>
                              </div>
                              <div style={{border: '2px solid #2771d8', marginTop: '10px', padding: '5px'}}>
                                <div style={{
                                  textAlign: 'center',
                                  fontSize: '16px',
                                  fontWeight: 'bold',
                                  color: '#333',
                                  backgroundColor: '#e3f2fd',  // Azul claro
                                  padding: '10px',
                                  borderRadius: '4px'
                                }}>
                                  Consulte a questão:
                                </div>
                                <QuestionText question={questionSelected} baseTextShow={true}/>
                              </div>
                            </div>
                            :
                            <TableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Lista das Questões</TableCell>
                                    <TableCell></TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>

                                {questionsWithouContent.map((question) => (
                                    <React.Fragment key={question.id}>
                                      <TableRow>
                                        <TableCell>{`Para Classificar a questão ${question.id}, `}
                                            <Link onClick={() => handleQuestionSelect(question)} style={{ cursor: 'pointer' }}>
                                              {' clique aqui.'}
                                            </Link>
                                        </TableCell>
                                        <TableCell>
                                          <IconButton
                                              size="small"
                                              onClick={() => handleExpandClickQuestioContent(question.id)}>
                                            {expandedRowQuestionContent === question.id ? <ExpandLess /> : <ExpandMore />}
                                          </IconButton>
                                        </TableCell>
                                      </TableRow>
                                      <TableRow>
                                        <TableCell colSpan={2}>
                                          <Collapse in={expandedRowQuestionContent === question.id}>
                                            <Typography variant="body2" color="textSecondary" style={{ padding: '16px' }}>
                                              <QuestionText question={question} baseTextShow={true}/>
                                            </Typography>
                                          </Collapse>
                                        </TableCell>
                                      </TableRow>
                                    </React.Fragment>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        }
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleCloseIncludeContent} color="primary">
                          Fechar
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  : null}

                <AppBar position="static">
                  <Tabs
                      variant="fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="nav tabs example">
                    <LinkTab label="Visão Geral" href="/drafts" {...a11yProps(0)} />
                    {/*<LinkTab label="Questões" href="/trash" {...a11yProps(1)} />*/}
                    <LinkTab label="Conteúdos" href="/spam" {...a11yProps(1)} />
                    <LinkTab label="Alunos" href="/spam" {...a11yProps(2)} />
                  </Tabs>
                </AppBar>
              <TabPanel value={value} index={0}>
                <TooltipCustomized
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          {'O coeficiente de variação (CV) é uma medida que mostra o quão distante o desempenho de um aluno está em relação à média de acertos da turma. '}
                          <b>{'Coeficiente de Variação Alto: '}</b>{' Significa que o aluno está muito distante da média, indicando grande variabilidade nas respostas.  '}
                          <br/><b>{'Coeficiente de Variação Baixo: '}</b>{'Indica que o aluno está próximo da média, sugerindo menos variabilidade.'}
                        </Typography>
                        <br/>
                        <Typography color="inherit">
                          <b>{'No cabeçalho da questão, as cores significam:'}</b>
                        </Typography>
                        <span className={classes.percentageRed}>{'0% a 29% de acerto'}</span>
                        <span className={classes.percentageOrange}>{'30% a 69% de acerto'}</span>
                        <span className={classes.percentageGreen}>{'70% a 100% de acerto'}</span>
                        <br/>
                        <Typography color="inherit">
                          <b>{'Nas respostas dos alunos:'}</b>
                        </Typography>
                        <Typography align="left" color="inherit">
                          A letra informa a alternativa que o estudante selecionou e a cor informa se</Typography>
                        <span className={classes.percentageRed}>{'Errou'}</span>
                        <span className={classes.percentageGreen}>{'Acertou'}</span>
                        <span className={classes.percentageNull}>{'ou Não respondeu'}</span>
                      </React.Fragment>
                    }>
                    <label style={{cursor: 'pointer'}} htmlFor="type-of-evaluation" className="form-label">
                      {"Informações sobre esta tela"}
                      <span style={{marginLeft: '10px'}} className="info-icon">
                      <i className="fas fa-info-circle"></i>
                    </span>
                    </label>
                </TooltipCustomized>
                <Box display="flex"
                     flexDirection="row"
                     flexWrap="wrap"
                     gap={2}
                     sx={{
                       width: '100%',
                       maxWidth: '1200px',
                       margin: '0 auto',
                     }}
                >
                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={hideRealNames}
                            onChange={handleCheckboxChange}
                        />
                      }
                      label="Substituir nomes reais por fictícios para os alunos"
                  />
                </Box>
                <Box
                    display="flex"
                    flexDirection="row" // Default to row for larger screens
                    flexWrap="wrap" // Allow wrapping if needed
                    gap={2} // Space between items
                    sx={{
                      width: '100%', // Make the container take the full width of its parent
                      maxWidth: '1200px', // Set a maximum width for the container
                      margin: '0 auto', // Center the container horizontally
                    }}
                >

                  <Box display="flex" flexDirection="column" sx={{ width: '300px', marginRight: '10px' }}>
                    <label htmlFor="select-order-student" className="form-label">
                      Ordem dos alunos
                      <i className="fa-solid fa-sort" style={{ marginLeft: '10px' }}></i>
                    </label>
                    <select
                        id="select-order-student"
                        className="form-select"
                        value={orderStudentSelect}
                        onChange={handleChangeStudentQuestion}
                        aria-describedby="select-order-student"
                        style={{ width: '100%' }} // Full width for responsiveness within the fixed size
                    >
                      {optionsOrderStudents && optionsOrderStudents.map((type) => (
                          <option key={type.id || type} value={type.id || type}>
                            {type.description || type}
                          </option>
                      ))}
                    </select>
                  </Box>
                  <Box display="flex" flexDirection="column" sx={{ width: '300px', marginRight: '10px' }}>
                    <label htmlFor="select-order-question" className="form-label">
                      Ordem das questões
                      <i className="fa-solid fa-sort" style={{ marginLeft: '10px' }}></i>
                    </label>
                    <select
                        id="select-order-question"
                        className="form-select"
                        value={orderQuestionSelect}
                        onChange={handleChangeOrderQuestion}
                        aria-describedby="select-order-question"
                        style={{ width: '100%' }} // Full width for responsiveness within the fixed size
                    >
                      {optionsOrderQuestions && optionsOrderQuestions.map((type) => (
                          <option key={type.id || type} value={type.id || type}>
                            {type.description || type}
                          </option>
                      ))}
                    </select>
                  </Box>
                  {optionsFilterQuestions.length > 2 && (
                      <Box display="flex" flexDirection="column" sx={{ width: '300px' }}>
                        <label htmlFor="select-filter-question" className="form-label">
                          Filtrar questões por conteúdo
                          <i className="fa-solid fa-filter" style={{ marginLeft: '10px' }}></i>
                        </label>
                        <select
                            id="select-filter-question"
                            className="form-select"
                            value={filterSelect}
                            onChange={handleChangeFilterQuestion}
                            aria-describedby="select-filter-question"
                            style={{ width: '100%' }} // Full width for responsiveness within the fixed size
                        >
                          {optionsFilterQuestions && optionsFilterQuestions.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.description || type}
                              </option>
                          ))}
                        </select>
                      </Box>
                  )}
                </Box>

                <Box
                    display="flex"
                    flexWrap="nowrap"
                    p={1}
                    m={1}
                    bgcolor="background.paper">
                  <Box p={1}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.headStudent}>Aluno(a)</TableCell>
                            <TableCell className={classes.headPercentage}>% de Acerto</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {answerStudents.map(result => (
                            <TableRow
                                className={classes.tableRow}
                                hover
                                key={result.fk_user_id}>
                              <TooltipCustomized
                                  title={
                                    <React.Fragment>
                                      <Typography align="center" color="inherit" style={{fontWeight: 'bold'}}>
                                        {formatStudentName(result.student)}
                                      </Typography>
                                      <p>
                                        <Typography color="textPrimary" variant="caption">
                                          {`O estudante iniciou em ${moment(result.hr_start).format('D [de] MMMM [de] YYYY, HH:mm:ss')}.`}
                                        </Typography>
                                      </p>
                                      <p>
                                        <Typography color="textPrimary" variant="caption">
                                          {result.hr_finished != null ?
                                              'O estudante finalizou em ' + moment(result.hr_finished).format('D [de] MMMM [de] YYYY, HH:mm:ss') + '.' :
                                              'A avaliação não foi finalizada pelo estudante.'}
                                        </Typography>
                                      </p>
                                      <p>
                                        {result.hr_finished != null ?
                                            <Typography color="textPrimary" variant="caption">
                                              {'Tempo de prova: ' + result.total_time}
                                            </Typography> : null}
                                      </p>
                                      <p>
                                        {result.finished_automatically === 1 ?
                                            <Typography color="textPrimary" variant="caption">
                                              Finalizada automaticamente
                                            </Typography> : null}
                                      </p>
                                      <p>
                                        {result.log.length > 0 ?
                                            <div>
                                            <i className="fa-duotone fa-solid fa-triangle-exclamation"
                                               style={{color: '#ff9800'}}></i>{' O aluno pode ter saído da tela durante a avaliação.'}
                                            </div>
                                            : null }
                                      </p>
                                    </React.Fragment>
                                  }>
                                <TableCell className={classes.bodyStudent}>
                                  <div className={classes.labelStudent}>
                                    <div>
                                      {result.log.length > 0 && <i className="fa-duotone fa-solid fa-triangle-exclamation"
                                       style={{color: '#ff9800', marginRight: '5px'}}></i>}
                                      {formatStudentName(result.student)}</div>
                                    <Typography color="textSecondary" variant="caption">
                                      {result.finished_automatically === 1 ? 'Finalizada automaticamente' : result.total_time == 'Avaliação não finalizada.' ? result.total_time : 'Tempo: ' + result.total_time}
                                    </Typography>
                                  </div>
                                </TableCell>
                              </TooltipCustomized>
                              <TableCell align="center" style={{margin: '0px'}} className={classes.bodyPercentage}>
                                {result.percentage_correct < 30 ?
                                    <span className={classes.percentageRed}>{result.percentage_correct + '%'}</span>
                                    : result.percentage_correct < 70 ?
                                        <span
                                            className={classes.percentageOrange}>{result.percentage_correct + '%'}</span>
                                        : <span
                                            className={classes.percentageGreen}>{result.percentage_correct + '%'}</span>}
                                <Typography style={{margin: '0px'}} variant="caption" color="textPrimary"
                                            display="block" gutterBottom>
                                  {result.cv && 'CV = ' + result.cv+ '%'}
                                </Typography>
                              </TableCell>

                            </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Box>
                  <PerfectScrollbar>
                    <Box p={1}>
                      <div className={classes.inner}>
                        <Table>
                          <TableHead>
                            <TableRow>
                              {!overviewQuestions ? null :
                                  overviewQuestions.map((result, i) => (
                                      <TableCell
                                          className={classes.headQuestion}
                                          onClick={() => handleOpenModalOverviewQuestion(result, result.order)}
                                          style={{cursor: 'pointer', display: result.hidden ? 'none' : 'table-cell', width: '90px', maxWidth: '90px', minWidth: '90px',}} >
                                        {(result.contest && result.contest.length) ?
                                            <div>
                                              {'Q' + result.order+ ' '}
                                              <i className="fa-duotone fa-solid fa-triangle-exclamation"
                                                   style={{color: '#ff9800'}}></i>{result.contest.length}
                                            </div>
                                            : 'Q' + result.order }
                                        {result.percentage_correct_round < 30 ?
                                            <span
                                                className={classes.percentageRed}>{result.percentage_correct_round + '%'}</span>
                                            : result.percentage_correct_round < 70 ?
                                                <span
                                                    className={classes.percentageOrange}>{result.percentage_correct_round + '%'}</span>
                                                : <span
                                                    className={classes.percentageGreen}>{result.percentage_correct_round + '%'}</span>}
                                        <Typography style={{margin: '0px'}} variant="caption" color="textPrimary"
                                                    display="block" gutterBottom>
                                          {result.cv && 'CV = ' + result.cv+ '%'}
                                        </Typography>
                                      </TableCell>
                                  ))}
                            </TableRow>
                          </TableHead>
                          <OverviewQuestionModal show={showModalOverviewQuestion}
                                                 handleClose={handleCloseModalOverviewQuestion}
                                                 question={currentQuestion} order={orderQuestion}/>
                          <TableBody>
                            {!answerStudents ? null :
                                answerStudents.map(result => (
                                    <TableRow
                                        className={classes.tableRow}
                                        hover
                                        key={result.fk_user_id}>
                                      {result.questions.map(quest => (
                                          !quest || quest.itemSelected == null ?
                                              <TableCell className={classes.answerNull}
                                                         style={{display: quest.hidden ? 'none' : 'table-cell'}}>
                                                <Block/>
                                              </TableCell>
                                              :
                                              quest.correct == 1 ?
                                                  <TableCell className={classes.answerCorrect}
                                                             style={{display: quest.hidden ? 'none' : 'table-cell'}}>
                                                    {quest.ordemQuestion}
                                                    <Done/>

                                                  </TableCell> :
                                                  <TableCell className={classes.answerIncorrect}
                                                             style={{display: quest.hidden ? 'none' : 'table-cell'}}>
                                                    {quest.ordemQuestion}
                                                    <Close/>
                                                  </TableCell>
                                      ))}

                                    </TableRow>
                                ))}
                          </TableBody>
                        </Table>
                      </div>

                    </Box>
                  </PerfectScrollbar>
                </Box>
              </TabPanel>
              {/*visão geral das questões
              <TabPanel value={value} index={1}>
                {!overviewQuestions ? null :
                    overviewQuestions.map((result, i) => (
                        <Box display="flex" style={{marginBottom: '20px'}}>
                            <Hidden xsDown>
                              <Chip label={(i + 1)}
                                    style={{fontSize: '14px',
                                      fontWeight: 'bold',
                                      margin: '8px'}} color="secondary" size="medium"/>
                            </Hidden>
                            <EvaluationQuestionCard
                                question={result}
                                hasApplication={1}
                            />
                          </Box>
                      ))}
                </TabPanel>*/}
                {/* competências e objetos de conhecimento */}
                <TabPanel value={value} index={1}>
                  <EvaluationApplicationResultsSkillObjects
                                skills={skills}
                                objects={objects}/>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <EvaluationApplicationResultsStudents
                      studentData={studentData}
                      objectsData={objects}/>
                </TabPanel>
          </CardContent>
              : null }
       </Card>
      </div>
  );
};

EvaluationApplicationResults.propTypes = {
  className: PropTypes.string,
};

export default EvaluationApplicationResults;
