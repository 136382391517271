import React, { useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    CircularProgress, Typography,
} from '@material-ui/core';
import CameraCapture from "../../utils/CameraCapture";  // Importa CameraCapture em vez de WebcamCapture
import api from "../../services/api";
import { toast } from "react-toastify";
import WebcamCapture from "../../utils/WebcamCapture";

const PhotoDialog = ({ open, onClose, id_application }) => {
    const [useCamera, setUseCamera] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const inputFileRef = useRef(null);

    useEffect(() => {
        if (open) {
            setSelectedImage(null);
            setImageFile(null);
            setUseCamera(false);
        }
    }, [open]);

    const handleSelectFromGallery = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
                setImageFile(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleUseCamera = () => {
        setUseCamera(true);
    };

    const handleDialogClose = () => {
        setUseCamera(false);
        onClose();
    };

    async function handleSendImage() {
        if (!imageFile) {
            toast.error("Nenhuma imagem selecionada!");
            return;
        }

        setLoading(true);

        const formData = new FormData();
        formData.append("gabarito", imageFile);
        formData.append("id_application", id_application);

        try {
            let url = 'evaluation/scan';
            const response = await api.post(url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 202) {
                toast.error(response.data.message);
            } else if (response.status === 200) {

                toast.success('Retorno: ' + response.data);
            }

        } catch (error) {
            toast.error("Erro ao enviar a imagem.");
        } finally {
            setLoading(false);
            handleDialogClose();
        }
    }

    const handleCapture = (imageSrc) => {
        setSelectedImage(imageSrc);

        fetch(imageSrc)
            .then(res => res.blob())
            .then(blob => {
                const file = new File([blob], 'captured-image.jpg', { type: 'image/jpeg' });
                setImageFile(file);
            })
            .catch(err => {
                console.error('Error converting image to blob:', err);
            });
    };

    return (
        <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
            <DialogTitle id="alert-dialog-title">{
                <Typography variant="subtitle1" color="#000000" component="p" style={{ fontWeight: 'bold' }}>
                    {"Ler Cartão-Resposta"}
                </Typography>}</DialogTitle>
            <DialogContent>
                {!useCamera && !selectedImage ? (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ padding: '10px 0', fontSize: '16px' }}
                            onClick={() => inputFileRef.current.click()}>
                            <i className="fa-regular fa-image" style={{ marginRight: '5px' }}></i>
                            Galeria
                            <input
                                ref={inputFileRef}  // Referencia o input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={handleSelectFromGallery}
                            />
                        </Button>

                        <Button
                            variant="contained"
                            onClick={handleUseCamera}
                            fullWidth
                            style={{ padding: '10px 0', fontSize: '16px' }}>
                            <i className="fa-solid fa-camera" style={{ marginRight: '5px' }}></i>
                            Câmera
                        </Button>
                    </div>
                ) : null}

                {selectedImage && (
                    <div style={{ marginTop: '10px', textAlign: 'center' }}>
                        <Typography variant="subtitle1" color="#000000" component="p">
                            {"Prévia do Cartão-Resposta:"}
                        </Typography>
                        <img
                            src={selectedImage}
                            alt="Prévia"
                            style={{
                                width: '70%',
                                height: 'auto',
                                maxWidth: '100%',
                                maxHeight: '80vh',
                                marginBottom: '10px',
                                aspectRatio: '210 / 297',
                                objectFit: 'contain',
                                border: "2px solid black",
                            }}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSendImage}
                            fullWidth
                            style={{ padding: '10px 0', fontSize: '16px', height: '35px' }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : "Enviar Cartão-Resposta"}
                        </Button>
                    </div>
                )}
                <WebcamCapture onCapture={handleCapture} />
                {/*useCamera && !selectedImage && (
                    <WebcamCapture onCapture={handleCapture} />
                )*/}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDialogClose} color="primary">
                    Cancelar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PhotoDialog;
