import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadarController, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadarController, RadialLinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const RadarChart = ({ data, studentData, content, width = 400, height = 400 }) => {
    // Calcular a média da turma por conteúdo
    const calculateAverage = (data) => {
        const averageMap = new Map();
        data.forEach(item => {
            averageMap.set(item.description, Number(item.percentage_correct));
        });
        return averageMap;
    };

    // Pegar os primeiros 6 caracteres da descrição
    const getFirstSixChars = (description) => {
        return description
            .split(' ')
            .map(word => word[0])
            .join('');
    };

    const averageMap = calculateAverage(data);

    // Preparar os dados para o gráfico
    const chartData = {
        labels: [...averageMap.keys()].map(description => getFirstSixChars(description)),
        datasets: [
            {
                label: 'Média do Aluno',
                data: [...averageMap.keys()].map(description => {
                    const studentItem = studentData.content.find(item => item.description === description);
                    return studentItem ? Number(studentItem.percentage_correct) : 0;
                }),
                backgroundColor: 'rgba(54, 162, 235, 0.2)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
            },
            {
                label: 'Média da Turma',
                data: [...averageMap.keys()].map(description => averageMap.get(description)),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1,
            },
        ],
    };

    // Configurações do gráfico
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: false,
                text: content,
            },
            tooltip: {
                callbacks: {
                    // Customiza o tooltip para exibir o nome completo da descrição
                    label: function (context) {
                        const fullDescription = [...averageMap.keys()][context.dataIndex];
                        const value = context.raw;
                        return `${fullDescription}: ${value}`;
                    },
                },
            },
        },
        scales: {
            r: {
                angleLines: {
                    display: false,
                },
                suggestedMin: 0,
                suggestedMax: 100,
                ticks: {
                    display: true,
                    callback: (value) => {
                        // Exibir apenas 60 e 100 no eixo
                        if (value === 60 || value === 100) {
                            return value;
                        }
                        return '';
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 5,
                bottom: 5,
                left: 5,
                right: 5,
            },
        },
    };

    return (
        <div style={{ width: `${width}px`, height: `${height}px` }}>
            <Radar data={chartData} options={options} />
        </div>
    );
};

export default RadarChart;
