import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  Typography,
  Avatar,
  List,
  ListItem,
  Divider,
  ListItemAvatar,
  ListItemText,
  IconButton,
  Tooltip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions, Button,
} from '@material-ui/core';
import api from '../../../../services/api';
import PropTypes from "prop-types";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { toast } from 'react-toastify';
import {MoreVert} from "@material-ui/icons";
import PeopleListStudents from "./components/PeopleListStudents";
import useStyles from "../../../../style/style";
import AddIcon from "@material-ui/icons/Add";

const useStylesLocal = makeStyles(theme => ({
  root: {


  },
  content: {
    padding: 0,
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatarProfessor: {
    marginRight: theme.spacing(2),
    background: '#2196f3'
  },
  headTable: {
    fontWeight: "bold"
  },
  actions: {
    justifyContent: 'flex-end'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  title: {
    color: '#000',
    marginTop: '50px',
    marginBottom: '10px',
    fontWeight: 'bold',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif'
  },
  itensPeople : {
    color: '#2196f3',
    marginTop: '12px',
    marginBottom: '12px',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif'
  }
}));

const People = props => {
  const { className, history, idClass } = props;
  const pathname = window.location.pathname;
  const studentClassId = pathname.substring(pathname.lastIndexOf('/') + 1);

  const [professor, setProfessor] = useState([]);
  const [students, setStudents] = useState([]);

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  //dialog
  const [open, setOpen] = React.useState(false);
  const [studentEmail, setStudentEmail] = React.useState(null);
  const [refresh, setRefresh] = React.useState(0);


  const handleClickOpen = () => {
    setOpen(true);
    setStudentEmail(null);
  };

  const handleClose = () => {
    setOpen(false);
    setStudentEmail(null);
  };


  const handleNewStudent = () => {
    if(studentEmail == null){
      toast.error('Não foi informado o e-mail do estudante.');
    } else {
      addStudent();
      setOpen(false);
      setStudentEmail(null);
    }
  }

  const handleChangeEmail = event => {
    setStudentEmail(event.target.value);
  }

  async function handleEnableAllStudents(){
    try {
      let id = idClass ? idClass : studentClassId;
      let url = `class/professor/enable-all-students/${id}`;
      const response = await api.post(url);

      if(response.status == 200) {
        toast.success('Todos os estudantes foram habilitados.');
        loadPeoples();
      } else {
        toast.error('Erro ao habilitar os estudantes.');
      }

    } catch (error) {

    }

  }

  async function loadPeoples(){
    try {
      let id = idClass ? idClass : studentClassId;
      let url = `class/student/details/${id}`;
      const response = await api.get(url);

      if(response.status == 200) {

        setProfessor(response.data.professor);
        setStudents(response.data.students);
      } else {
        setProfessor([]);
        setStudents([]);
      }

    } catch (error) {

    }
  }

  async function addStudent(){
    try {
      let url = `class/professor/add-student/?email=${studentEmail}&fk_class_id=${studentClassId}`;
      const response = await api.post(url);

      if(response.status == 200) {
        toast.success(response.data.message);
        setRefresh(Date.now());
      } else {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    loadPeoples();
  }, [refresh]);

  return (
      <div className={classes.root}>
            <div className={classes.content}>
              <div className={classes.title}>
                Professor(a)
              </div>
              <Divider />
              <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                       { professor ? 
                            <ListItem>
                              <ListItemAvatar>
                                <Avatar className={clsx(classes.avatarProfessor, className)}>{professor.id}</Avatar>
                              </ListItemAvatar>
                              <ListItemText>{
                                <div className={classes.itensPeople}>
                                  {professor.name && professor.name}
                                </div>
                              }</ListItemText>
                            </ListItem> 
                          : null }    
              </List>
          </div>


          <div className={classes.content}>
            <Box display="flex">
              <Box flexGrow={1}>
                <div className={classes.title}>
                  Alunos
                </div>
              </Box>
              <Box display="flex" alignItems="flex-end">
                {localStorage.getItem('@Questione-acess-level-user') === "2" && students.length >= 1 &&
                  <Button
                      style={{marginRight:'30px', marginBottom: '5px'}}
                      color="secondary"
                      onClick={handleEnableAllStudents}>Habilitar todos</Button>}
                {students.length == 0 ?
                    <div style={{ marginBottom: '14px', marginRight: '5px', fontFamily: 'Roboto, sans-serif'}}>
                      {'0 alunos'}
                    </div> :
                    students.length == 1 ?
                        <div style={{ marginBottom: '14px', marginRight: '5px', fontFamily: 'Roboto, sans-serif'}}>
                        {students.length+' aluno.'}
                      </div> :
                        <div style={{ marginBottom: '14px', marginRight: '5px', fontFamily: 'Roboto, sans-serif'}}>
                            {students.length+' alunos.'}
                          </div>}

                {localStorage.getItem('@Questione-acess-level-user') === "2" &&
                    <Tooltip title="Adicionar alunos">
                  <IconButton
                      aria-label="copy"
                      onClick={handleClickOpen}>
                    <PersonAddIcon style={{color: '#2196f3'}} />
                  </IconButton>
                </Tooltip>}
              </Box>
            </Box>
            <Divider />
            <List dense sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                     { students ?
                        students.map(std => (
                          <PeopleListStudents std={std} setRefresh={setRefresh}/>
                        ))
                      : null}
            </List>
          </div>

        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">{
            <div>
              <div className={classesGeneral.titleDialog}>
                {'Cadastrar estudante na turma'}
              </div>
              <div className={classesGeneral.messageDialog}>
                {'Para cadastrar um estudante, por favor informe o e-mail.'}
              </div>
            </div>
            }</DialogTitle>
          <DialogContent>

            <TextField
                autoFocus
                margin="dense"
                id="email"
                value={studentEmail}
                onChange={handleChangeEmail}
                label="E-mail do estudante"
                type="email"
                fullWidth/>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
            <Button onClick={handleNewStudent} color="primary">
              Cadastrar estudante
            </Button>
          </DialogActions>
        </Dialog>


      </div>

  );
};

People.propTypes = {
  history: PropTypes.object
};

export default People;
