// src/utils/pdfGenerator.js
import JsBarcode from 'jsbarcode';
import QRCode from 'qrcode';
import { PDFDocument, rgb, StandardFonts } from "pdf-lib";

// Função para gerar um código de barras em base64
const generateBarcodeBase64 = (text) => {
    return new Promise((resolve) => {
        // Cria um canvas temporário para gerar o código de barras
        const canvas = document.createElement('canvas');
        JsBarcode(canvas, text, {
            format: 'CODE128',
            displayValue: false,
            width: 2,
            height: 50,
        });
        resolve(canvas.toDataURL('image/png'));
    });
};

const generateQRCodeBase64 = (text) => {
    return new Promise((resolve, reject) => {
        QRCode.toDataURL(text, {
            errorCorrectionLevel: 'L', // Você pode ajustar o nível de correção de erros
            margin: 1, // Ajuste a margem conforme necessário
        }, (err, url) => {
            if (err) {
                reject(err);
            } else {
                resolve(url);
            }
        });
    });
};
const loadImageBase64 = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
    });
};
export const generateEnemStylePdf = async ({description, totalQuestion, code}) => {

    const drawDashedLine = ({
                                start,
                                end,
                                color,
                                thickness,
                                dashLength = 5,
                                spaceLength = 3
                            }) => {
        const { x: x1, y: y1 } = start;
        const { x: x2, y: y2 } = end;

        const dx = x2 - x1;
        const dy = y2 - y1;
        const length = Math.sqrt(dx * dx + dy * dy);
        const numDashes = Math.floor(length / (dashLength + spaceLength));

        for (let i = 0; i < numDashes; i++) {
            const startX = x1 + i * (dashLength + spaceLength) * (dx / length);
            const startY = y1 + i * (dashLength + spaceLength) * (dy / length);
            const endX = startX + dashLength * (dx / length);
            const endY = startY + dashLength * (dy / length);

            page.drawLine({
                start: { x: startX, y: startY },
                end: { x: endX, y: endY },
                color,
                thickness
            });
        }
    };

    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([595, 842]);
    const { height } = page.getSize();
    const fontSize = 10;

    const boldFont = await pdfDoc.embedFont(StandardFonts.HelveticaBold);


    const barcodeBase64 = await generateQRCodeBase64(code);
    const barcodeImage = await pdfDoc.embedPng(barcodeBase64.split(',')[1]);
    const barcodeImageDims = barcodeImage.scale(0.5); // Ajuste a escala conforme necessário

    const imageUrl = `${process.env.PUBLIC_URL}/images/logo.png`; // Ajuste o caminho da sua imagem
    const imageBase64 = await loadImageBase64(imageUrl);
    const imageBytes = imageBase64.split(',')[1]; // Remove o prefixo data:image/png;base64
    const image = await pdfDoc.embedPng(imageBytes);

    const imageDims = image.scale(0.5); // Escala a imagem conforme necessário

    // Desenha o fundo cinza para a imagem
    page.drawRectangle({
        x: 40, // Ajuste a posição X conforme necessário
        y: height - imageDims.height - 56, // Ajuste a posição Y (mesma da imagem)
        width: imageDims.width + 20,
        height: imageDims.height + 10,
        color: rgb(0.7, 0.7, 0.7), // Cor cinza claro
    });

    // Desenha a imagem no topo da página, sobre o fundo cinza
    page.drawImage(image, {
        x: 50, // Ajuste a posição X conforme necessário
        y: height - imageDims.height - 50, // Ajuste a posição Y (mesma que o retângulo)
        width: imageDims.width,
        height: imageDims.height,
    });

    // Desenha o texto "Nome Completo"
    page.drawText("CARTÃO-RESPOSTA", {
        x: 250,
        y: height - imageDims.height - 50,
        size: fontSize + 6,
        color: rgb(0, 0, 0),
    });

    const initialX = 50;
    const initialY = height - imageDims.height - 130;
    const squareSize = 20; // Tamanho dos quadrados
    const squareSpacing = 0; // Espaçamento entre os quadrados
    const numSquaresPerLine = 25;

    page.drawText("Simulado: "+description, {
        x: initialX,
        y: height - imageDims.height - 80, // Ajuste a posição vertical do texto
        size: fontSize + 2,
        color: rgb(0, 0, 0),
        font: boldFont
    });

    page.drawText("Nome Completo", {
        x: initialX,
        y: height - imageDims.height - 100, // Ajuste a posição vertical do texto
        size: fontSize + 2,
        color: rgb(0, 0, 0),
    });


    for (let line = 0; line < 2; line++) {
        for (let i = 0; i < numSquaresPerLine; i++) {
            const x = initialX + i * (squareSize + squareSpacing);
            const y = initialY - line * (squareSize + squareSpacing);

            page.drawRectangle({
                x: x,
                y: y,
                width: squareSize,
                height: squareSize,
                borderColor: rgb(0, 0, 0),
                borderWidth: 1,
            });
        }
    }

    const signatureLineY = initialY - 50; // Ajuste a posição Y da linha de assinatura

    drawDashedLine({
        start: { x: initialX, y: signatureLineY },
        end: { x: initialX + numSquaresPerLine * (squareSize + squareSpacing), y: signatureLineY },
        color: rgb(0, 0, 0),
        thickness: 1.0,
        dashLength: 5, // Ajuste o comprimento dos traços
        spaceLength: 3  // Ajuste o comprimento dos espaços
    });

    page.drawText("Assinatura do Aluno", {
        x: 240,
        y: signatureLineY - 15,
        size: fontSize + 2,
        color: rgb(0, 0, 0),
    });

    const textYPosition = height - imageDims.height - 250;


    const textLines = [
        "Para todas as marcações neste CARTÃO-RESPOSTA, preencha os círculos completamente",
        "e com nitidez, utilizando caneta esferográfica de tinta preta."
    ];

    // Desenha cada linha do texto
    textLines.forEach((line, index) => {
        page.drawText(line, {
            x: 50,
            y: (height - 235) - index * (fontSize + 5),
            size: fontSize + 2,
            color: rgb(0, 0, 0),
        });
    });

    page.drawImage(barcodeImage, {
        x: initialX,
        y: textYPosition - 45,
        width: barcodeImageDims.width,
        height: barcodeImageDims.height,
    });

    page.drawText(code, {
        x: 100,
        y: textYPosition - 25,
        size: fontSize + 2,
        color: rgb(0, 0, 0),
        font: boldFont
    });


    const startY = height - 340;
    const startX = 40;
    const bubbleSpacing = 18;
    const columnSpacing = 130;
    const bubbleRadius = 3.5;
    const spaceBetweenQuestionAndOptions = 40;
    const extraQuestionSpacing = 10;

    const options = ["A", "B", "C", "D", "E"];
    let questionNumber = 1;

    const numRows = 15;
    const tableHeight = numRows * bubbleSpacing;

    const questionsInCol = 10;
    const colHeight = (questionsInCol * (bubbleSpacing + extraQuestionSpacing)) + 50;

    const totalQuestions = totalQuestion;
    const totalColumns = 4;
    const maxQuestionsPerColumn = Math.ceil(totalQuestions / totalColumns);

    for (let col = 0; col < totalColumns; col++) {
        const colStartX = startX + col * columnSpacing;
        const questionsInCol = col === 0 ? 10 : 10;
        const headerYPosition = startY + 5;
        const numQuestionsInCol = Math.min(maxQuestionsPerColumn, totalQuestions - col * maxQuestionsPerColumn);

        for (let i = 0; i < numQuestionsInCol; i++) {
            const yPosition = startY - i * bubbleSpacing;

            const formattedQuestionNumber = String(questionNumber).padStart(3, '0');;

            page.drawText(`${formattedQuestionNumber} `, {
                x: colStartX,
                y: yPosition,
                size: fontSize + 2,
                color: rgb(0, 0, 0),
            });


            options.forEach((option, index) => {
                const xPosition = colStartX + spaceBetweenQuestionAndOptions + index * bubbleSpacing;
                page.drawCircle({
                    x: xPosition,
                    y: yPosition + 4,
                    size: bubbleRadius * 2,
                    color: rgb(1, 1, 1),
                    borderWidth: 1.1,
                    borderColor: rgb(0, 0, 0),
                });


                page.drawText(option, { x: xPosition - 2, y: yPosition + 3, size: fontSize - 4 });
            });

            questionNumber++;

            if(col == totalColumns){

                page.drawLine({
                    start: { x: colStartX + columnSpacing + 100, y: (yPosition + bubbleSpacing) - 1 }, // Ajuste a posição horizontal
                    end: { x: colStartX + columnSpacing + 100, y: yPosition - bubbleSpacing - bubbleSpacing / 2 }, // Ajuste a posição vertical
                    color: rgb(0, 0, 0),
                    thickness: 5
                });
            }
        }

    }

    const pdfBytes = await pdfDoc.save();
    return pdfBytes;
};
