import React, {useEffect, useState} from 'react';
import {
    Card,
    CardContent,
    Paper,
    Box,
    TablePagination, useMediaQuery, Link, LinearProgress, FormControlLabel, Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from "../../style/style";
import api from "../../services/api";
import {makeStyles} from "@material-ui/styles";
import QuestionItemList from "./QuestionItemList";
import SearchFilterQuestion from "../SearchFilterQuestion/SearchFilterQuestion";
import AlertMessage from "../Messages/AlertMessage";


const useStylesLocal = makeStyles(theme => ({
    textFoot: {
        color: '#000000', fontFamily: 'Verdana', fontSize: '12px', marginTop: '10px'
    },
    paper: {
        marginBottom: 10,
        '& > *': {
            margin: theme.spacing(2),
        },
        margin: 3,
        padding: 8
    },
    paperCorrect: {
        backgroundColor: '#e2f2e7',
        color: '#212121',
    },
    paperRightFont: {
        color: '#80cbc4',
    },
    paperWrongFont: {
        color: '#ef9a9a',
    },
}));

const QuestionList = ({ onQuestionsSelected, onClose, enableSearchQuestionBank = false }) => {
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [courses, setCourses] = useState([]);
    const [selectAll, setSelectAll] = useState(false);

    const classesGeneral = useStyles();
    const classes = useStylesLocal();
    const [questions, setQuestions] = useState(null);

    const [rowsPerPage, setRowsPerPage] = useState(8);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(0);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    //filtro de questões
    const [courseSelect, setCourseSelect] = useState(0);
    const [objectSelect, setObjectSelect] = useState(0);
    const [typeEvaluationSelect, setTypeEvaluationSelect] = useState(0);
    const [yearSelect, setYearSelect] = useState(0);
    const [filterByUser, setFilterByUser] = useState(0);
    const [questionsPerPageSelect, setQuestionsPerPageSelect] = useState(0);
    const [questionBankSelect, setQuestionBankSelect] = useState(null);
    const [objectDecompositionSelect, setObjectDecompositionSelect] = useState(0);

    const handleSelectAll = () => {
        const bool = !selectAll;
        setSelectAll(bool);
        if (bool == true) {
            const allQuestionIds = questions.map((q) => q.id);
            setSelectedQuestions(allQuestionIds); // Selecionar todos os IDs
            onQuestionsSelected(allQuestionIds);
        } else {
            setSelectedQuestions([]); // Desmarcar todos
            onQuestionsSelected([]);
        }
    };

    async function loadQuestions(page){
        try {
            let url = 'question?page='+page;

            if(filterByUser){
                url += '&user=S';
            } else {
                url += '&user=T';
            }

            if(courseSelect && courseSelect > 0){
                url += '&fk_course_id='+courseSelect;
            }

            if(objectSelect && objectSelect > 0){
                url += '&fk_object_id='+objectSelect;
            }

            if(typeEvaluationSelect && typeEvaluationSelect > 0){
                url += '&fk_type_of_evaluation_id='+typeEvaluationSelect;

            }

            if(yearSelect && yearSelect > 0){
                url += '&year='+yearSelect;
            }

            if(questionsPerPageSelect > 0){
                url += '&totalPerPage='+questionsPerPageSelect;
            }

            if(questionBankSelect > 0 && enableSearchQuestionBank){
                url += '&fk_bank_id='+questionBankSelect;
            }

            if(objectDecompositionSelect > 0){
                url += '&fk_object_decomposition_id='+objectDecompositionSelect;
            }

            const response = await api.get(url);
            if(response.status == 200) {
                setTotal(response.data.total);
                setQuestions(response.data.data);
                setRowsPerPage(response.data.per_page);
            } else {
                setQuestions([]);
            }
        } catch (error) {

        }
    }

    const handleSelectQuestion = (id) => {
        const updatedSelection = selectedQuestions.includes(id)
            ? selectedQuestions.filter((questionId) => questionId !== id)
            : [...selectedQuestions, id];
        console.log(updatedSelection);
        setSelectedQuestions(updatedSelection);
        onQuestionsSelected(updatedSelection); // Chama o callback para retornar as questões selecionadas
    };

    useEffect(() => {
        loadQuestions(1);
    }, [courses]);

    useEffect(() => {
        loadQuestions(page+1);
    }, []);

    const handlePageChange = (event, newPage) => {
        loadQuestions(newPage+1);
        setPage(newPage);
    };

    useEffect(() => {
        loadQuestions(1);
    }, [questionBankSelect,
        courseSelect,
        objectSelect,
        objectDecompositionSelect,
        typeEvaluationSelect,
        yearSelect,
        filterByUser,
        questionsPerPageSelect]);

    useEffect(() => {
        if(selectAll === true){
            handleSelectAll();
        }

    }, [page]);

    const onClickSearch = (
        questionBankSelect,
        courseSelect,
        objectSelect,
        objectDecompositionSelect,
        typeEvaluationSelect,
        yearSelect,
        filterByUser,
        questionsPerPageSelect)=> {

        setQuestionBankSelect(questionBankSelect);
        setCourseSelect(courseSelect);
        setObjectSelect(objectSelect);
        setObjectDecompositionSelect(objectDecompositionSelect);
        setTypeEvaluationSelect(typeEvaluationSelect);
        setYearSelect(yearSelect);
        setQuestionsPerPageSelect(questionsPerPageSelect);
        setFilterByUser(filterByUser);
    }

    function truncateString(str, maxLength) {
        if (str.length > maxLength) {
            return str.slice(0, maxLength) + '...';
        }
        return str;
    }

    return (
        <div>
            <Paper style={{ padding: '10px' }}>
                <SearchFilterQuestion
                    enableSearchQuestionBank={enableSearchQuestionBank}
                    onClickSearch={onClickSearch}
                    idBank={questionBankSelect}/>
                <Box display={"flex"} justifyContent={"center"}>
                    {selectedQuestions.length > 0  &&
                            <Link
                                component="button"
                                variant="body2"
                                onClick={onClose}
                                style={{ display: 'block', marginTop: '20px', cursor: 'pointer', color: '#ff5722', fontWeight: 'bold', fontSize: '17px' }}>
                                {truncateString(
                                    `Clique aqui para adicionar ${selectedQuestions.length} ${
                                        selectedQuestions.length === 1 ? 'questão' : 'questões'
                                    } selecionada${selectedQuestions.length === 1 ? '' : 's'} (${selectedQuestions.join(', ')}).`,
                                    100
                                )}
                            </Link>
                        }
                </Box>
            </Paper>

            {questions == null ?
                <LinearProgress color="secondary"    />
                : questions.length === 0 ?
                    <AlertMessage message="0 questões encontradas." />
                    :
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectAll}
                                onChange={handleSelectAll} // Chama o handleSelectAll
                            />
                        }
                        label="Selecionar todas as questões desta página"
                    />
                <TablePagination
                    component="div"
                    count={total}
                    onChangePage={handlePageChange}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[rowsPerPage]}/>

                    {questions.map(question => (
                        <Card key={question.id} style={{ margin: '10px 0' }}>
                            <CardContent>
                                <QuestionItemList
                                    question={question}
                                    selectedQuestions={selectedQuestions}
                                    handleSelectQuestion={handleSelectQuestion}
                                    totalCharactersInText = {150}
                                    showQuestionsDefault={false}
                                    selectAll={selectAll}
                                    currentPage={page}
                                    />
                            </CardContent>
                        </Card>
                    ))}
                    <TablePagination
                        component="div"
                        count={total}
                        onChangePage={handlePageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}/>

                </div>
                }



            {/*<div style={{marginBottom: '20px'}}>
                <QuestionCard
                    question={question}
                    setRefresh={setRefresh}
                    refresh={refresh}
                    id_course={searchText[1].fk_course_id}/>
            </div>*/}
        </div>
    );
};

QuestionList.propTypes = {
    onQuestionsSelected: PropTypes.func.isRequired, // Adiciona propTypes para o callback
};

export default QuestionList;
