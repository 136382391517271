import React, {useEffect, useState} from 'react';
import {Box, Chip, Divider, IconButton, List, ListItem, Paper, useMediaQuery} from '@material-ui/core';
import RenderCodeLatex from "../../../../components/RenderLatex";
import api from "../../../../services/api";
import {toast} from "react-toastify";
import ReactHtmlParser from "react-html-parser";
import clsx from "clsx";
import { makeStyles } from '@material-ui/styles';
import {isSafari} from "react-csv/lib/core";
import {DialogQuestione} from "../../../../components";
import {Button, Modal} from "react-bootstrap";

const useStylesLocal = makeStyles((theme) => ({
    root: {
        margin: 10,
    },
    content: {
        padding: 0
    },
    ml: {
        marginLeft: '8px',
    },
    headQuestion: {
        width: '90.0px',
        backgroundColor: '#FFF',
        color: '#393A68',
        textAlign: 'center',
        height: '80px',
        boxSizing: 'border-box',
        border: '1px solid #F2F2F2',
        minWidth: '80px',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    percentageRed: {
        backgroundColor: '#EC0B43',
        display: 'block',
        margin: '8px',
        padding: '10px',
        textAlign: 'center',
        color: '#fff',
        borderRadius: 4
    },
    percentageGreen: {
        backgroundColor: '#5DE2A5',
        display: 'block',
        margin: '8px',
        padding: '10px',
        textAlign: 'center',
        color: '#fff',
        borderRadius: 4,
    },
    percentageNull: {
        backgroundColor: '#90a4ae',
        color: '#fff',
        display: 'block',
        margin: '8px',
        padding: '10px',
        textAlign: 'center',
        borderRadius: 4
    },
    answerCorrect: {
        width: '90.0px',
        backgroundColor: '#5DE2A5',
        //display: 'inline-block',
        color: '#393A68',
        textAlign: 'center',
        height: '60px',
        boxSizing: 'border-box',
        border: '1px solid #F2F2F2',
        minWidth: '80px',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    answerIncorrect: {
        width: '90.0px',
        backgroundColor: '#F14D76',
        //display: 'inline-block',
        color: '#393A68',
        textAlign: 'center',
        height: '60px',
        boxSizing: 'border-box',
        border: '1px solid #F2F2F2',
        minWidth: '80px',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    answerNull: {
        width: '90.0px',
        backgroundColor: '#cfd8dc',
        //display: 'inline-block',
        color: '#393A68',
        textAlign: 'center',
        height: '70px',
        boxSizing: 'border-box',
        border: '1px solid #F2F2F2',
        minWidth: '80px',
        padding: '12px',
        fontWeight: 'bold',
        fontSize: '14px'
    },
    lineQuestion: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        margin: 30
    },
    lineItemQuestion: {
        width: '100%'
    },
    correct: {
        color: '#80cbc4',
    },
    incorrect: {
        color: '#ef9a9a',
    },
    bgCorrect: {
        background: 'green',
        color: '#ffffff',
    },
    bgIncorrect: {
        background: 'red',
        color: '#ffffff',
    },
    paperWrong: {
        backgroundColor: '#ef9a9a',
        color: '#212121',
    },
    paperWrongFont: {
        color: '#ef9a9a',
    },
    paperRight: {
        backgroundColor: '#80cbc4',
        color: '#212121',
    },
    paperRightFont: {
        color: '#80cbc4',
    },
    tituloCard: {
        fontSize: '15px',
        fontWeight: 'bold'
    },
    paper: {
        marginBottom: 10,
        '& > *': {
            margin: theme.spacing(2),
        },
        margin: 3,
        padding: 8
    },
    paperCorrect: {
        backgroundColor: '#e2f2e7',
        color: '#212121',
    },
}));

const messages = [
    "Pronto para avançar? Estamos preparando uma nova pergunta adaptada ao seu nível de conhecimento! 🚀📚 Deseja continuar?",
    "Vamos continuar a explorar! Sua próxima pergunta está sendo ajustada ao seu ritmo. 😉💡",
    "Uma nova questão está a caminho, pronta para te desafiar ainda mais! Aceita o desafio? 🌟",
    "Sua próxima questão está sendo preparada com base no que você já sabe. Quer continuar? 📖✨",
    "Mais uma pergunta que combina com seu nível de conhecimento. Vamos seguir em frente? 🚀📖",
    "Está pronto para a próxima questão? Estamos preparando algo no ponto certo para você! 🎯📚",
    "Cada pergunta te leva mais longe! Preparando a próxima especialmente para você. Continuar? 🌱💡",
    "Vamos em frente? A próxima questão está sendo ajustada ao seu nível de aprendizado! 🎓✨",
    "Um novo desafio está vindo, ajustado ao seu progresso! Pronto para continuar? 🚀📘",
    "O aprendizado é contínuo! Sua próxima pergunta está quase pronta. Continuar? 🌟📚",
    "Estamos ajustando uma nova questão para você. Preparado para o próximo desafio? 🚀📘",
    "Você está indo muito bem! Vamos para a próxima pergunta? Está sendo adaptada ao seu nível. 😊📚",
    "Sua jornada de aprendizado continua! Uma nova pergunta está sendo preparada. Continuar? 🚀✨",
    "A cada pergunta você avança mais. Pronto para continuar? 🌟📖",
    "Estamos ajustando a próxima questão com base no seu progresso. Vamos para ela? 🚀📚",
    "Uma nova pergunta feita especialmente para você está a caminho. Vamos seguir? 🌱📘",
    "Seu aprendizado está evoluindo! Preparando a próxima questão no seu nível. Quer continuar? 💡📚",
    "Mais uma pergunta, adaptada ao que você já aprendeu. Pronto para continuar? 🚀📖",
    "Sua próxima pergunta está chegando, ajustada ao seu progresso! Quer seguir em frente? 😊📚",
    "Uma nova questão está pronta para você! Preparado para mais conhecimento? 🌟📘"
];

const AdaptiveQuestionItemsComponent = ({
                                            current,
                                            handleEliminate,
                                            handleToggle,
                                            handleListItemClick,
                                            disableItens,
                                            eliminatedItems,
                                            alternativeLetters,
                                            classesGeneral,
                                            handleSubmitAnswer,
                                            nextQuestion,
                                            handleBack,
                                            finshEvaluation
                                        }) => {

    const [feedbackQuestion, setFeedbackQuestion] = useState(null);
    const [dialogNextQuestion, setDialogNextQuestion] = useState(false);
    const classes = useStylesLocal();
    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const [showModalSuitable, setShowModalSuitable] = useState(false);

    const handleCloseModalSuitable = () => setShowModalSuitable(false);
    const handleShowModalSuitable = () => setShowModalSuitable(true);
    async function loadFeedbackQuestion(){
        try {

            const response = await api.get('evaluation/adaptive/feedback-question/' + current.id);

            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);

                }
            } else if(response.status === 200){
                setFeedbackQuestion(response.data);

            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if(current.finished_answer === 1){
            loadFeedbackQuestion();
        } else {
            setFeedbackQuestion(null);
        }
    }, [current]);


    return (
        <>
        {
            feedbackQuestion ?
                <div>
                    <Box display="flex" justifyContent="space-between" alignItems="center"
                         style={{marginTop: '30px', marginBottom: '10px'}}>
                        <Box>
                            <div
                                style={{
                                    color: '#5c5c5c',
                                    marginLeft: '25px',
                                    fontFamily: 'Verdana, sans-serif',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    lineHeight: '1.5',
                                    letterSpacing: '0.5px',
                                    padding: '10px 0',
                                }}>
                                {'Veja o feedback da última questão respondida.'}
                            </div>

                        </Box>
                    </Box>
                    <Divider style={{padding: '3px', marginTop: '10px', marginBottom: '15px'}}
                             className={classesGeneral.paperTitle}/>
                    <Box>

                    {feedbackQuestion.correct === 1 ? <p className={classes.paperRightFont}
                                                         style={{
                                                             backgroundColor : 'white',
                                                             marginLeft: '25px',
                                                             color: '#4CAF50',
                                                             padding: '5px 5px',
                                                             borderRadius: '8px',
                                                             fontSize: '14px',
                                                             fontWeight: 'bold',
                                                             textAlign: 'center',
                                                             cursor: 'pointer',
                                                             transition: 'background-color 0.3s ease',
                                                             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                                             maxWidth: '200px',
                                                             marginRight: '20px'
                                                         }}>{'Você acertou esta questão.'}</p> :
                            <p className={classes.paperWrongFont}
                               style={{
                                   backgroundColor : 'white',
                                   marginLeft: '25px',
                                   color: '#ff4d4f',
                                   padding: '5px 5px',
                                   borderRadius: '8px',
                                   fontSize: '14px',
                                   fontWeight: 'bold',
                                   textAlign: 'center',
                                   cursor: 'pointer',
                                   transition: 'background-color 0.3s ease',
                                   boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                   maxWidth: '200px',
                                   marginRight: '20px'
                               }}>{'Você errou esta questão.'}</p>}
                    </Box>
                    <div style={{margin: '10px'}}>
                        {feedbackQuestion.objects.length > 0 && (
                            <Box display="flex" style={{marginBottom: '30px'}}>
                                <div className={classesGeneral.paperTitleText} style={{marginLeft: '20px'}}>
                                    {'Conteúdo(s) da questão:'}
                                </div>
                                <div className={classesGeneral.paperTitleTextBold} style={{marginLeft: '5px'}}>
                                    {feedbackQuestion.objects.map(item => (
                                        ReactHtmlParser(item.object.description) + '. '
                                    ))}
                                </div>
                            </Box>)

                        }
                        <div style={{marginLeft: '15px'}}>
                            {<RenderCodeLatex text={feedbackQuestion.question.base_text}/>}
                        </div>
                        <div style={{marginLeft: '10px', marginTop: '10px'}}>
                            {<RenderCodeLatex text={feedbackQuestion.question.stem}/>}
                        </div>
                        <div style={{marginTop: '15px'}}>
                            {feedbackQuestion.question.items.map((item, i) => (
                                item.correct_item == 1 ?
                                    <Box display="flex" flexDirection="row" style={{width: '100%'}}>
                                        <Box style={{marginTop: '15px', marginRight: '5px'}} sx={{flexShrink: 1}}>
                                            <Chip label={alternativeLetters[i]} style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                background: feedbackQuestion.answer === item.id && item.correct_item === 1 ? "#e2f2e7" : "#e1f5fe"
                                            }} size="small"/>
                                        </Box>
                                        <Box sx={{width: '100%'}}>
                                            <Paper
                                                className={clsx(classes.paper, feedbackQuestion.answer === item.id && item.correct_item === 1 ? classes.paperCorrect : classes.paper)}
                                                elevation={3} variant="outlined">
                                                {<RenderCodeLatex text={item.description}/>}
                                            </Paper>
                                        </Box>
                                    </Box>
                                    :
                                    <Box display="flex" flexDirection="row" style={{width: '100%'}}>
                                        <Box style={{marginTop: '15px', marginRight: '5px'}}>
                                            <Chip label={alternativeLetters[i]} style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                background: feedbackQuestion.answer == item.id && item.correct_item == 0 ? "#ef9a9a" : "#e1f5fe"
                                            }} size="small"/>
                                        </Box>
                                        <Box sx={{width: '100%'}}>
                                            <Paper
                                                className={clsx(classes.paper, feedbackQuestion.answer == item.id && item.correct_item == 0 ? classes.paperWrong : classes.paper)}
                                                variant="outlined">
                                                {<RenderCodeLatex text={item.description}/>}
                                            </Paper>
                                        </Box>
                                    </Box>
                            ))}
                            {feedbackQuestion.question.items.map((item, i) => (
                                item.correct_item === 1 && feedbackQuestion.answer != item.id ?
                                    <div>
                                        <Box display="flex" style={{marginTop: '20px'}}>
                                            <div className={classesGeneral.paperTitleTextBold}>
                                                {'Resposta correta:'}
                                            </div>
                                        </Box>
                                        <Box display="flex" flexDirection="row"
                                             style={{width: '100%', marginTop: '10px'}}>
                                            <Box style={{marginTop: '15px', marginRight: '5px'}} sx={{flexShrink: 1}}>
                                                <Chip label={alternativeLetters[i]} style={{
                                                    fontSize: '14px',
                                                    fontWeight: 'bold',
                                                    background: "#e1f5fe"
                                                }} size="small"/>
                                            </Box>
                                            <Box sx={{width: '100%'}}>
                                                <Paper className={clsx(classes.paper, classes.paper)} elevation={3}
                                                       variant="outlined">
                                                    {ReactHtmlParser(item.description)}
                                                </Paper>
                                            </Box>
                                        </Box>
                                    </div>
                                    :
                                    null
                            ))}
                            <Box display="flex" justifyContent="center" marginTop="20px" >
                                <div
                                    style={{
                                        marginRight: '3px',
                                        marginTop: '30px',
                                        marginBottom: '30px',
                                        fontSize: '15px',
                                        backgroundColor: '#4caf50',
                                        color: 'white',
                                        padding: '8px 15px',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        textAlign: 'center',
                                        fontWeight: 'bold',
                                        transition: 'background-color 0.3s ease',
                                    }}
                                    onClick={() => setDialogNextQuestion(true)}
                                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#52b202')} // cor um pouco mais escura ao passar o mouse
                                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#4caf50')}>
                                    {'Próxima pergunta'}
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <Box display="flex" justifyContent="space-between">
                        <Box display="flex" sx={{flexGrow: 1}} justifyContent="flex-start">
                            <div style={{
                                color: '#757575',
                                fontFamily: 'Verdana',
                                fontSize: '14px',
                                marginTop: '7px'
                            }}>
                                {'Texto Base/Enunciado:  '}
                            </div>
                        </Box>
                    </Box>
                    <Divider style={{padding: '3px', marginTop: '10px', marginBottom: '10px'}}
                             className={classesGeneral.paperTitle}/>
                    {/* Base text */}
                    <div style={{marginLeft: '15px'}}>
                        {current && current.question_without_correct.base_text && (
                            <RenderCodeLatex
                                text={current.question_without_correct.base_text}
                            />
                        )}
                    </div>

                    {/* Stem */}
                    <div style={{marginLeft: '10px', marginTop: '10px'}}>
                        {current && (
                            <RenderCodeLatex
                                text={current.question_without_correct.stem}
                            />
                        )}
                    </div>

                    {/* Question items */}
                    <div style={{marginTop: '15px'}}>
                        {current && current.question_without_correct.question_items_without_correct.map((item, i) => (
                            <div key={item.id}>
                                <Box display="flex" flexDirection="row" style={{width: '100%'}}>
                                    {/* Botão de eliminar (tesoura) */}
                                    {current.question_without_correct.answer !== item.id && (
                                        <IconButton
                                            aria-label="scissors"
                                            style={{marginRight: '10px'}}
                                            size="small"
                                            onClick={() => handleEliminate(item.id)}
                                        >
                                            <i className="fa-solid fa-scissors"></i>
                                        </IconButton>
                                    )}

                                    {/* Lista de alternativas */}
                                    <Box sx={{width: '100%'}}>
                                        <List
                                            className={classes.lineItemQuestion}
                                            component="nav"
                                            aria-label="secondary mailbox folder"
                                            onClick={handleToggle(item.id)}
                                        >
                                            <ListItem
                                                selected={current.answer === item.id}
                                                button
                                                onClick={(event) => handleListItemClick(event, current.id, item.id)}
                                                style={{
                                                    background: disableItens(current, item.id) ? '#ffcdd2' : '#f5f5f5',
                                                    opacity: eliminatedItems.has(item.id) ? 0.5 : 1, // Reduz a opacidade para itens eliminados
                                                }}
                                                disabled={disableItens(current, item.id)}
                                            >
                                                {/* Letra alternativa */}
                                                <div
                                                    style={{marginRight: '10px', fontSize: '14px', fontWeight: 'bold'}}>
                                                    {alternativeLetters[i]})
                                                </div>

                                                {/* Descrição da alternativa */}
                                                <div>
                                                    {item.description && <RenderCodeLatex text={item.description}/>}
                                                </div>
                                            </ListItem>
                                        </List>
                                    </Box>
                                </Box>
                            </div>
                        ))}
                    </div>
                    {/* Botão para enviar resposta */}
                    <Box display="flex" justifyContent="center" marginTop="20px" >
                        <div
                            style={{
                                marginRight: '3px',
                                fontSize: '15px',
                                backgroundColor: '#f44336', // vermelho suave
                                color: 'white',
                                padding: '8px 15px',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                textAlign: 'center',
                                fontWeight: 'bold',
                                transition: 'background-color 0.3s ease',
                                marginTop: '30px',
                                marginBottom: '30px'
                            }}
                            onClick={handleShowModalSuitable}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#e53935')} // cor um pouco mais escura ao passar o mouse
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#f44336')}>
                            Enviar resposta
                        </div>
                    </Box>
                    {/* MODAL COM PERGUNTA DE ADEQUAÇÃO*/}
                    <Modal show={showModalSuitable} onHide={handleCloseModalSuitable} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Avaliação de Adequação</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Esta questão foi adequada ao seu nível de habilidade {current.question_without_correct.knowledge_objects ?
                            current.question_without_correct.knowledge_objects.length > 0 ?
                            'em '+current.question_without_correct.knowledge_objects[0].description : '' : ''}?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={(event) => {
                                handleCloseModalSuitable();
                                handleSubmitAnswer(event, current.id, 1);
                                }
                            }>
                                Sim
                            </Button>
                            <Button
                                onClick={(event) => {
                                    handleCloseModalSuitable();
                                    handleSubmitAnswer(event, current.id, 0);
                                    }
                                }
                                style={{ backgroundColor: '#f28b82', borderColor: '#f28b82' }} // Vermelho claro
                            >
                                Não
                            </Button>
                            <Button
                                onClick={(event) => {
                                    handleCloseModalSuitable();
                                    handleSubmitAnswer(event, current.id, -1);
                                    }
                                }
                                style={{ backgroundColor: '#e0e0e0', borderColor: '#e0e0e0', color: 'black' }}
                            >
                                Prefiro Não Responder
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
        }
            <DialogQuestione handleClose={() => setDialogNextQuestion(false)}
                             open={dialogNextQuestion}
                             onClickAgree={() => {
                                 nextQuestion();  // Execute a função nextQuestion
                                 setDialogNextQuestion(false);  // Feche o diálogo
                             }}
                             onClickDisagree={() => setDialogNextQuestion(false)}
                             mesage={
                                 <div className={classesGeneral.messageDialog}>
                                     {messages[Math.floor(Math.random() * messages.length)]}
                                 </div>
                             }
                             title=
                                 {<div className={classesGeneral.titleDialog}>
                                     {'Próxima pergunta'}
                                 </div>}/>

        </>
    );
};

export default AdaptiveQuestionItemsComponent;
