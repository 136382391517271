import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import '@fortawesome/fontawesome-free/css/all.min.css';

// Estilos usando makeStyles
const useStylesLocal = makeStyles((theme) => ({
    instructorButton: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        width: '60px',
        height: '60px',
        borderRadius: '50%',
        backgroundColor: '#f1c40f', // Amarelo chamativo
        color: '#2c3e50',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        transition: 'width 0.3s, height 0.3s',
        fontSize: '24px',
        textAlign: 'center',
    },
    expanded: {
        width: '300px',
        height: 'auto',
        padding: '20px',
        borderRadius: '10px',
    },
    instructorContent: {
        textAlign: 'center',
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        top: '-7px',
        right: '10px',
        color: '#2c3e50',
        zIndex: 1100,
    },
    tipText: {
        fontSize: '16px',
        margin: '0 0 10px 0',
    },
    nextButton: {
        backgroundColor: '#2c3e50',
        color: 'white',
        border: 'none',
        padding: '3px 8px',
        marginTop: '5px',
        cursor: 'pointer',
        borderRadius: '5px',
        fontSize: '16px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s',
        '&:hover': {
            backgroundColor: '#34495e',
        },
    },
}));

function Instructor({ expanded, onToggle }) {
    const classes = useStylesLocal(); // Usando estilos locais
    const tips = [
        'Dica 1: Se você sair desta tela enquanto estiver realizando a avaliação, isso será registrado como uma atividade suspeita e informado ao professor.',
        'Dica 2: Para relatar algum problema com a questão, clique em "Deseja contestar esta questão?".',
        'Dica 3: Use a <i class="fa-solid fa-scissors"></i> ao lado das opções para marcar aquelas que você tem certeza de que estão incorretas. Depois, escolha a opção correta.',
        'Dica 4: Se a opção "Avançar automaticamente" estiver selecionada, você avançará para a próxima questão ao clicar em uma alternativa.',
        'Dica 5: Você só poderá "Entregar simulado" após responder todas as questões do simulado.',
        'Dica 6: Consulte a seção "Falta responder" para ver quais questões ainda não foram respondidas.',
    ];

    const [currentTipIndex, setCurrentTipIndex] = useState(0);

    const nextTip = (e) => {
        e.stopPropagation(); // Evita que o clique no botão "Próxima Dica" feche o instrutor
        setCurrentTipIndex((prevIndex) => (prevIndex + 1) % tips.length);
    };

    const closeInstructor = (e) => {
        e.stopPropagation(); // Para evitar fechar ao clicar no botão
        onToggle(false); // Chama a função passada como prop para fechar o instrutor
    };

    const toggleExpand = () => {
        onToggle(!expanded); // Chama a função passada como prop para alternar a expansão
    };

    return (
        <div
            className={`${classes.instructorButton} ${expanded ? classes.expanded : ''}`}
            onClick={toggleExpand}
        >
            {expanded ? (
                <div>
                    <IconButton className={classes.closeButton} onClick={closeInstructor}>
                        <CloseIcon />
                    </IconButton>
                    <div className={classes.instructorContent}>
                        <div className={classes.tipText} dangerouslySetInnerHTML={{__html: tips[currentTipIndex]}}/>
                        <button className={classes.nextButton} onClick={nextTip}>Próxima Dica</button>
                    </div>
                </div>
            ) : (
                <span>?</span>
            )}
        </div>
    );
}

export default Instructor;
