import React from 'react';
import { Box, Breadcrumbs, Link, Typography } from '@material-ui/core';
import CharmHome from '@material-ui/icons/Home';

const BreadcrumbsComponent = ({ items }) => {
    return (
        <Box display="flex">
            <Breadcrumbs aria-label="breadcrumb">
                {items.map((item, index) => (
                    item.href ? (
                        <Link color="inherit" href={item.href} key={index}>
                            <Box display="flex">
                                {item.icon && (
                                    <Box style={{ marginRight: '5px' }}>
                                        {item.icon}
                                    </Box>
                                )}
                                <Box>{item.label}</Box>
                            </Box>
                        </Link>
                    ) : (
                        <Typography color="textPrimary" key={index}>
                            {item.label}
                        </Typography>
                    )
                ))}
            </Breadcrumbs>
        </Box>
    );
};

export default BreadcrumbsComponent;
