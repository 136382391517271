import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {Button, Grid, Typography} from '@material-ui/core';
import api from "../../../../../../services/api";
import {withRouter} from "react-router-dom";
import {toast} from "react-toastify";
import {DialogQuestione} from "../../../../../../components";

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  comboboxSearch: {
    width: '60%'
  },
  spacer: {
    flexGrow: 1
  },
  buttonSeacrh: {
    marginLeft: theme.spacing(1),
  },
  searchInput: {
    marginRight: theme.spacing(1)
  },
  title: {
    fontWeight: 'bold',
    marginTop: theme.spacing(2)
  }
}));

const ObjectDecompositionToolbar = props => {
  const { className,  history, onGenerateByAI, ...rest } = props;
  const { codigoObject } = props.match.params;
  const [courses, setCourses] = useState([{'id': '0', 'description': 'Todos os cursos'}]);
  const [regulations, setRegulations] = useState([{'id': '0', 'description': 'Todas as portarias'}]);
  const [object, setObject] = useState(null);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();

  const onClickOpenDialog = () => {
    setOpen(true);
  }

  const onClickCloseDialog = () => {
    setOpen(false);
  }

  const onClickAgreeAI = () => {
      onGenerateByAI();
      onClickCloseDialog();
  }

  const onClickNewObjectDecomposition = e => {
    history.push('/object-decomposition-details/'+codigoObject);
  }

  async function findAObject(id){
    try {
      const response = await api.get('object/show/'+id);

      if (response.status === 202) {
        if(response.data.message){
          toast.error(response.data.message);
        }
      } else {
        setObject(response.data[0]);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if(codigoObject){
      findAObject(codigoObject);
    } else {
      toast.error('Conteúdo não encontrado.');
    }

  }, []);

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}>
      <div className={classes.row}>
        {object && object.description &&
            <div style={{ marginTop: '20px', width: '100%', maxWidth: '800px' }}>
                <Typography variant="h3" className={classes.title}>{'Assuntos do conteúdo '+object.description}</Typography>
                <Typography variant="h4" >{'Curso: '+object.course.description}</Typography>
              </div>
        }
        <span className={classes.spacer} />
        <Grid
            container
            direction="column"
            spacing={1}
            justifyContent="flex-end"
            alignItems="flex-end"
            style={{ marginTop: '15px' }}>>
          <Grid item>
            <Button
                color="primary"
                variant="contained"
                onClick={onClickNewObjectDecomposition}>
              Novo Assunto
            </Button>
          </Grid>
          <Grid item>
            <Button
                color="primary"
                variant="contained"
                onClick={onClickOpenDialog}>
              Gerar Assuntos por IA
            </Button>
          </Grid>
        </Grid>
      </div>
      <div className={classes.row}>

      </div>
      {object && object.description &&
      <DialogQuestione handleClose={onClickCloseDialog}
                       open={open}
                       onClickAgree={onClickAgreeAI}
                       onClickDisagree={onClickCloseDialog}
                       mesage={'Deseja gerar os assuntos do conteúdo '+object.description+' usando Inteligência Artificial?'}
                       title={'Gerar assuntos usando Inteligência Artificial'}/>}
    </div>
  );
};

ObjectDecompositionToolbar.propTypes = {
  className: PropTypes.string,
  history: PropTypes.object,
};

export default withRouter(ObjectDecompositionToolbar);
