import React from 'react';
import {
    Breadcrumbs,
    Typography,
    Link,
    Grid,
    List,
    ListItem,
    ListItemText,
} from '@material-ui/core';

const PrivacyPolicy = () => {
    return (
        <div className="container">


            <Grid container spacing={2} className="row" style={{marginTop: '30px'}}>
                <Grid item sm={8}>
                    <div className="q-mural">
                        <div className="q-featured" id="intro">
                            <Typography paragraph>
                                Esta Política de Privacidade tem como objetivo fornecer uma compreensão mais clara sobre como
                                lidamos com seus dados pessoais. De acordo com a Lei 13.709 - a Lei Geral de Proteção de Dados (LGPD),
                                "tratamento" refere-se a qualquer operação realizada com dados pessoais. Isso inclui atividades
                                como coleta, produção, recepção, classificação, utilização, acesso, reprodução, transmissão,
                                distribuição, processamento, arquivamento, armazenamento, eliminação, avaliação ou controle
                                da informação, bem como modificação, comunicação, transferência, difusão ou extração.
                            </Typography>

                            <Typography paragraph>
                                Esta Política de Privacidade oferece uma visão clara de como o Questione coleta, utiliza e protege
                                suas informações e dados pessoais, conforme suas interações conosco.
                            </Typography>

                            <Typography paragraph>
                                Apresentamos a seguir os tipos de dados que podemos coletar e receber diretamente de você,
                                como utilizamos e compartilhamos suas informações, as medidas de segurança que implementamos
                                e quais são seus direitos em relação aos seus dados pessoais.
                            </Typography>

                            <ListItem>
                                <Typography variant="body1" component="span" style={{fontWeight: 'bold'}}>
                                    1 - Dados Pessoais que Coletamos
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1">
                                    Conforme sua interação com o Questione, podemos coletar informações e Dados Pessoais, que incluem:
                                    Informações de Cadastro (nome, sobrenome, foto, email e senha) e Informações técnicas (informações
                                    sobre o computador ou dispositivo móvel utilizado para acessar o website ou aplicativo, incluindo
                                    o endereço IP utilizado para a conexão à internet e a localização geográfica).
                                </Typography>
                            </ListItem>

                            <ListItem>
                                <Typography variant="body1" component="span" style={{fontWeight: 'bold'}}>
                                    2 - Como coletamos os dados
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant="body1">
                                    Coletamos suas informações diretamente de você por meio de:
                                    Criação e/ou atualização de cadastro ou perfil em uma conta do Questione no website ou aplicativo;
                                    Participação em pesquisas realizadas pelo Questione; e Informações coletadas por meio de cookies.
                                </Typography>
                            </ListItem>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default PrivacyPolicy;
