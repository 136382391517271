// src/components/AdaptiveEvaluationModal.js

import React, {useEffect, useState} from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem } from '@material-ui/core';
import api from "../../../../services/api";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Form, Modal} from "react-bootstrap";
import {toast} from "react-toastify";
import ShareIcon from "@material-ui/icons/Share";

const AdaptiveEvaluationModal = ({ open, onClose, class_id, handleSubmit }) => {
    const [bank, setBank] = useState('');
    const [description, setDescription] = useState('');
    const [questionsBanks, setQuestionsBanks] = useState([]);

    async function loadQuestionsBank(page = 1) {
        try {
            let url = `questions-bank?page=${page}`;
            const response = await api.get(url);

            if (response.status === 200) {
                setQuestionsBanks(response.data.data);
            } else {
                setQuestionsBanks([]);
            }
        } catch (error) {
            console.error("Erro ao carregar bancos de questões:", error);
            setQuestionsBanks([]);
        }
    }

    async function newApplication(){

        try {
            const fk_class_id = class_id;
            const fk_bank_id = bank;
            const adaptive_assessment = true;
            const data = {
                description: description + " (Adaptativo)",
                fk_bank_id,
                fk_class_id,
                adaptive_assessment
            }
            const response = await api.post('evaluation/add-application', data);

            if (response.status === 200) {
                toast.success('Novo simulado adaptativo cadastrado.');
                handleSubmit();
            } else {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            }

        } catch (error) {

        }

    }


    useEffect(() => {
        if(open) {
            setBank('');
            setDescription('');
            loadQuestionsBank();
        }
    }, [open]);


    const isFormValid = description !== '' && bank !== '';

    return (
        <Modal show={open} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Novo Simulado Adaptativo</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div style={{
                    padding: '10px',
                    backgroundColor: '#f0f0f0',
                    borderRadius: '4px',
                    color: '#555',
                    fontSize: '14px',
                    lineHeight: '1.6',
                    marginBottom: '16px',
                    fontStyle: 'italic'
                }}>{'O simulado adaptativo foi projetado para avaliar o conhecimento de um aluno em tempo real, ' +
                    'adaptando o nível de dificuldade das questões com base nas respostas anteriores do aluno. ' +
                    'Após cada resposta, os alunos recebem feedback imediato, permitindo que entendam seus erros ' +
                    'e aprendam com eles.'}</div>
                <Form.Group controlId="description">
                    <Form.Label>Descrição</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Digite a descrição"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}  // Atualiza a descrição
                    />
                </Form.Group>
                <Form.Group controlId="bank">
                    <Form.Label>Banco de Questões</Form.Label>
                    <Form.Control
                        as="select"
                        value={bank}
                        onChange={(e) => setBank(e.target.value)}  // Atualiza o banco de questões
                    >
                        <option value="" disabled>Selecione o Banco de Questões</option>
                        {questionsBanks &&
                            questionsBanks.map((qb) => (
                                <option key={qb.id} value={qb.id}>
                                    {qb.description}
                                </option>
                            ))}
                    </Form.Control>
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    Cancelar
                </Button>
                <Button variant="primary"
                        onClick={newApplication}
                        disabled={!isFormValid}>
                    Enviar
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default AdaptiveEvaluationModal;
