import React from 'react';
import {
    BrowserRouter,
    Switch,
    Redirect
} from 'react-router-dom';
import { RouteWithLayout } from './../components';
import { Main as MainLayout, Minimal as MinimalLayout } from './../layouts';
import {
    SignUp,
    SignIn,
    UserTable,
    RedefinePassword,
    ResetPassword,
    CourseTable,
    SkillTable,
    ObjectTable,
    PageNotFound,
    RequestUserTable,
    AccountDetails,
    Dashboard,
    EvaluationTable,
    EvaluationArchiveTable,
    EvaluationDetails,
    EvaluationQuestions,
    SkillDetails,
    ObjectDetails,
    RequestUserDetails,
    CourseDetails,
    UserRequestCourseTable,
    EvaluationApplicationTable,
    DoEvaluation,
    EvaluationApplicationDetails,
    EvaluationApplicationResults,
    StartEvaluation, EvaluationsResultStudent, EvaluationsResultStudentDetails,
    UserRequestCourseDetails,
    Unauthorized,
    TypeOfEvaluationTable,
    TypeOfEvaluationDetails,
    EvaluationPracticeTable,
    EvaluationPracticeArchiveTable,
    EvaluationPracticeDetails,
    GenerateEvaluationQuestions,
    GenerateEvaluation,
    EvaluationPracticeApplicationTable,
    EvaluationPracticeApplicationDetails,
    EvaluationPracticeApplicationResults,
} from '../pages';
import QuestionTable from "../pages/Professor/Question/QuestionTable";
import QuestionDetails from "../pages/Professor/Question/QuestionDetails";

import StudentClassListProfessor from '../pages/StudentClass/Professor/StudentClass';
import StudentClassDetailsProfessor from '../pages/StudentClass/Professor/StudentClassDetails';

import StudentClassListStudent from '../pages/StudentClass/Student/StudentClass';

import StudentClassContent from '../pages/StudentClass/StudentClassContent';
import StudentClassEvaluationDetails from '../pages/StudentClass/StudentClassContent/Evaluation/EvaluationDetails';

import StudentClassEvaluationApplicationDetails from '../pages/StudentClass/StudentClassContent/Application/EvaluationApplicationDetails';
import StudentClassEvaluationApplicationResults from '../pages/StudentClass/StudentClassContent/Application/EvaluationApplicationResults';
import RegulationTable from "../pages/Administrator/Regulation/RegulationTable";
import RegulationDetails from "../pages/Administrator/Regulation/RegulationDetails";
import Mapping from "../pages/Administrator/Mapping/MappingTable";
import AreaTable from "../pages/Administrator/Area/AreaTable";
import AreaDetails from "../pages/Administrator/Area/AreaDetails";
import ObjectSuggestionTable from "../pages/Administrator/ObjectSuggestion";
import ObjectDecompositionTable from "../pages/Administrator/ObjectDecomposition/ObjectDecompositionTable";
import ObjectDecompositionDetails from "../pages/Administrator/ObjectDecomposition/ObjectDecompositionDetails";
import QuestionBankDetails from "../pages/Professor/QuestionsBank/QuestionsBankDetails";
import QuestionsBankTable from "../pages/Professor/QuestionsBank/QuestionsBankTable";
import QuestionsBankItensTable from "../pages/Professor/QuestionsBank/QuestionsBankItens/QuestionsBankItensTable";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import EvaluationApplicationAdaptiveResults
    from "../pages/StudentClass/StudentClassContent/Application/EvaluationApplicationAdaptiveResults";

const Routes = () => (
    <BrowserRouter>
        <Switch>
            <Redirect
                exact
                from="/"
                to="/home"
            />
            {/*ROTAS COMUNS*/}
            <RouteWithLayout
                component={Dashboard}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/home" />
            <RouteWithLayout
                component={SignUp}
                exact
                layout={MinimalLayout}
                needToBeLogged={false}
                path="/sign-up" />
            <RouteWithLayout
                component={PrivacyPolicy}
                exact
                layout={MinimalLayout}
                needToBeLogged={false}
                path="/privacy-policy" />
            <RouteWithLayout
                component={SignIn}
                exact
                layout={MinimalLayout}
                needToBeLogged={false}
                path="/sign-in" />
            <RouteWithLayout
                component={RedefinePassword}
                exact
                layout={MinimalLayout}
                needToBeLogged={false}
                path="/redefine-password" />
            <RouteWithLayout
                component={ResetPassword}
                exact
                layout={MinimalLayout}
                needToBeLogged={false}
                path="/reset-password/:token" />
            <RouteWithLayout
                component={AccountDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/account" />
            <RouteWithLayout
                component={UserRequestCourseTable}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/requests" />
            <RouteWithLayout
                component={UserRequestCourseDetails}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/new-request" />
            <RouteWithLayout
                component={StudentClassListStudent}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/student-class/student" />
            {/* ROTAS DO ALUNO */}
            <RouteWithLayout
                component={EvaluationPracticeTable}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={-1}
                path="/evaluation-practice" />
            <RouteWithLayout
                component={EvaluationPracticeArchiveTable}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={-1}
                path="/evaluation-practice-archive" />
            <RouteWithLayout
                component={EvaluationPracticeDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/evaluation-practice-details/:codigoEvaluation?" />
            <RouteWithLayout
                component={GenerateEvaluation}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/generate-evaluation/:codigoEvaluation?" />
            <RouteWithLayout
                component={GenerateEvaluationQuestions}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/generate-evaluation/:codigoEvaluation/questions" />
            
            <RouteWithLayout
                component={EvaluationPracticeApplicationTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/evaluation-practice/applications-evaluation/:idApplication" />
            <RouteWithLayout
                component={EvaluationPracticeApplicationDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/evaluation-practice/:codigoEvaluation/applications-evaluation/details/:idApplication" />
            <RouteWithLayout
                component={EvaluationPracticeApplicationResults}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/evaluation-practice/applications-evaluation/results/:idApplication" />

            <RouteWithLayout
                component={StartEvaluation}
                exact
                layout={MinimalLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/student/start-evaluation" />
            <RouteWithLayout
                component={EvaluationsResultStudent}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/student/result-evaluations" />
            <RouteWithLayout
                component={EvaluationsResultStudentDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/student/result-evaluations/details/:idHead" />
            <RouteWithLayout
                component={DoEvaluation}
                exact
                layout={MinimalLayout}
                needToBeLogged={true}
                typeUser={-1}
                path="/code/:codeAplication" />s
        {/*ROTAS DO ADMINISTRADOR*/}
            <RouteWithLayout
                component={AreaTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/areas" />
            <RouteWithLayout
                component={AreaDetails}
                exact
                layout={MainLayout}
                typeUser={1}
                needToBeLogged={true}
                path="/area-details/:codigoArea?" />
            <RouteWithLayout
                component={CourseTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/courses" />
            <RouteWithLayout
                component={CourseDetails}
                exact
                layout={MainLayout}
                typeUser={1}
                needToBeLogged={true}
                path="/course-details/:codigoCourse?" />
            <RouteWithLayout
                component={TypeOfEvaluationTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/type-of-evaluation" />
            <RouteWithLayout
                component={TypeOfEvaluationDetails}
                exact
                layout={MainLayout}
                typeUser={1}
                needToBeLogged={true}
                path="/type-of-evaluation-details/:typeOfEvaluationCode?" />
            <RouteWithLayout
                component={RegulationTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/regulations" />
            <RouteWithLayout
                component={RegulationDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/regulation-details" />
            <RouteWithLayout
                component={RegulationDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/regulation-details/:codigoRegulation?" />
            <RouteWithLayout
                component={SkillTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/skills" />
            <RouteWithLayout
                component={SkillDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/skill-details/:codigoSkill?" />
            <RouteWithLayout
                component={ObjectDecompositionTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/object-decomposition/:codigoObject?" />
            <RouteWithLayout
                component={ObjectDecompositionDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/object-decomposition-details/:codigoObject/:codigoDecomposition?"  />
            <RouteWithLayout
                component={ObjectTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/objects" />
            <RouteWithLayout
                component={ObjectDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/object-details/:codigoObject?" />
            <RouteWithLayout
                component={Mapping}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/mapping" />
            <RouteWithLayout
                component={ObjectSuggestionTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={1}
                path="/object-suggestion" />
            <RouteWithLayout
                component={UserTable}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={1}
                path="/users" />
            <RouteWithLayout
                component={RequestUserTable}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={1}
                path="/users/requests" />
            <RouteWithLayout
                component={RequestUserDetails}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={1}
                path="/users/requests/:codigoCourseProfessor" />
            {/*ROTAS DO PROFESSOR*/}
            <RouteWithLayout
                component={EvaluationTable}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={2}
                path="/evaluations" />
            <RouteWithLayout
                component={EvaluationArchiveTable}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={2}
                path="/evaluations-archive" />
            <RouteWithLayout
                component={EvaluationQuestions}
                exact
                needToBeLogged={true}
                layout={MainLayout}
                typeUser={2}
                path="/evaluation-questions/:evaluationId" />
            <RouteWithLayout
                component={EvaluationDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/evaluation-details/:codigoEvaluation?" />
            <RouteWithLayout
                component={EvaluationApplicationTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/applications-evaluation" />
            <RouteWithLayout
                component={EvaluationApplicationDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/applications-evaluation/details/:idApplication" />
            <RouteWithLayout
                component={EvaluationApplicationResults}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/ant-applications-evaluation/results/:idApplication"
                goToDestination />
            <RouteWithLayout
                component={QuestionTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/questions" />
            <RouteWithLayout
                component={QuestionsBankTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/questions-bank" />
            <RouteWithLayout
                component={QuestionBankDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/question-bank/:codigoQuestionBank?" />
            <RouteWithLayout
                component={QuestionsBankItensTable}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/question-bank-itens/:idBank" />
            <RouteWithLayout
                component={QuestionDetails}
                exact
                layout={MainLayout}
                needToBeLogged={true}
                typeUser={2}
                path="/question-details/:idQuestion?" />
            <RouteWithLayout
                component={StudentClassDetailsProfessor}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/student-class-details/:studentClassId/professor" />
            <RouteWithLayout
                component={StudentClassDetailsProfessor}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/student-class-details/professor" />
            {/* <RouteWithLayout
                component={StudentClassDetails}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/student-class-details" /> */}



            <RouteWithLayout
                component={StudentClassEvaluationApplicationDetails}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/student-class/:studentClassId/applications-evaluation/details/:idApplication" />
            <RouteWithLayout
                component={StudentClassEvaluationApplicationResults}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/student-class/:studentClassId/applications-evaluation/results/:idApplication" />

            <RouteWithLayout
                component={StudentClassEvaluationApplicationResults}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/applications-evaluation/results/:idApplication" />

            <RouteWithLayout
                component={EvaluationApplicationAdaptiveResults}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/student-class/:studentClassId/applications-evaluation/adaptive/results/:idApplication" />

            <RouteWithLayout
                component={EvaluationApplicationAdaptiveResults}
                exact
                needToBeLogged={true}
                typeUser={2}
                layout={MainLayout}
                path="/applications-evaluation/adaptive/results/:idApplication" />

            <RouteWithLayout
                component={StudentClassListProfessor}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/student-class/professor" />
            <RouteWithLayout
                component={StudentClassContent}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/student-class/:id" />
            <RouteWithLayout
                component={StudentClassEvaluationDetails}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/student-class/:id/evaluation-details/:codigoEvaluation?" />
            {/* <RouteWithLayout
                component={StudentClassContent}
                exact
                needToBeLogged={true}
                typeUser={-1}
                layout={MainLayout}
                path="/student-class/:id/" /> */}

            <RouteWithLayout
                component={Unauthorized}
                exact
                layout={MinimalLayout}
                needToBeLogged={false}
                typeUser={2}
                path="/unauthorized" />
            <RouteWithLayout
                path="*"
                layout={MinimalLayout}
                needToBeLogged={false}
                component={PageNotFound} />
            
        </Switch>
    </BrowserRouter>
);

export default Routes;
