import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardHeader,
    CardContent,
    LinearProgress, Chip, Box, Hidden, Button, IconButton, Accordion, AccordionSummary, AccordionDetails, Typography,
} from '@material-ui/core';
import {withRouter} from "react-router-dom";
import api from "../../services/api";
import moment from "moment";
import EvaluationQuestionCard
    from "../../pages/Professor/Evaluation/EvaluationQuestions/components/EvaluationQuestionCard";
import Tab from "@material-ui/core/Tab";
import useStyles from "../../style/style";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import QuestionList from "../QuestionList";
import Dialog from "@material-ui/core/Dialog";
import { toast } from 'react-toastify';
import {DialogQuestione} from "../index";
import GeneratePDF from "../GeneratePDF";
import QuestionPaper from "../GeneratePDF/QuestionPaper";
import generateQuestionPaperPDF from "../GeneratePDF/QuestionPaper";
import CloseIcon from "@material-ui/icons/Close";
import {truncateText} from "../../utils/truncateText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TooltipQuestione from "../TooltipQuestione/TooltipQuestione";
import ContentList from "../../pages/Professor/QuestionsBank/ContentList/ContentList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStylesLocal = makeStyles(theme => ({
    root: {

    },
    head: {
        paddingBottom: 0,
        paddingTop: 10
    },
    chipGreen:{
      backgroundColor: '#4db6ac',
      color: '#ffffff',
        marginRight: 2
    },
    chipRed:{
        backgroundColor: '#f44336',
        color: '#ffffff',
        marginRight: 2
    },
    chipblue: {
        margin: 3,
        backgroundColor: '#2196f3',
        color: '#fff',
    },
    spacer: {
    flexGrow: 1
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    paper: {
        display: 'flex',
        marginBottom: 10,
        '& > *': {
            margin: theme.spacing(2),
        },
    },
    paperWrong: {
        backgroundColor: '#ef9a9a',
        color: '#212121',
        margin: 3,
        padding: 8
    },
    paperRight: {
        backgroundColor: '#80cbc4',
        color: '#212121',
        margin: 3,
        padding: 8
    },
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
        fontWeight: 'bold',
        color: '#ffffff'
    },
    labelRank: {
      textAlign: 'right'
    },
    colorLabelDifficulty: {
        color: '#3f51b5',
        fontSize: 16,
        fontWeight: 'bold'
    },
    colorLabelDifficultyWithoutAnswer:{
        color: '#f44336',
        fontSize: 16,
        fontWeight: 'bold'
    }
}));


function LinkTab(props) {
    return (
        <Tab
            component="a"
            onClick={(event) => {
                event.preventDefault();
            }}
            {...props}
        />
    );
};

const EvaluationQuestions = props => {
    const { className, history, evaluationId, hideDescription, ...rest } = props;

    const classes = useStylesLocal();
    const classesGeneral = useStyles();

    const [questions, setQuestions] = useState(null);
    const [hasApplication, setHasApplication] = useState(null);
    const [evaluation, setEvaluation] = useState('');
    const [expanded, setExpanded] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);
    const [openAddQuestion, setOpenAddQuestion] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [openConfirmAddQuestion, setOpenConfirmAddQuestion] = useState(false);
    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const handleCloseOpenConfirmAddQuestion = () => {
        setOpenConfirmAddQuestion(false);
    };
    const handleClickOpenAddQuestion = () => {
        setOpenAddQuestion(true);
    };

    const handleCloseAddQuestion = () => {
        setOpenAddQuestion(false);
        if(selectedQuestions.length > 0 ){
            setOpenConfirmAddQuestion(true);
        }
    };

    async function addQuestion (questionId) {
        try {
            let url = '/evaluation/addquestion';
            const fk_question_id = questionId;
            const fk_evaluation_id = evaluation.id;
            const data = {
                fk_question_id, fk_evaluation_id
            }

            const response = await api.post(url, data);

            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            } else {
                toast.success('Questão '+questionId+' adicionada na avaliação.');
                loadQuestionsEvaluation();
            }
        } catch (error) {

        }

    }

    async function onClickConfirmAddQuestions(){
        if (selectedQuestions.length > 0) {
            // Loop para adicionar as questões na sequência
            for (const questionId of selectedQuestions) {
                try {
                    await addQuestion(questionId);
                } catch (error) {
                   toast.error(`Erro ao adicionar a questão ${questionId}:`);
                    // Você pode querer lidar com o erro de forma diferente dependendo do seu caso de uso
                }
            }
        }
        setSelectedQuestions([]);
        handleCloseOpenConfirmAddQuestion(false);
    }

    const onClickNoConfirmAddQuestions = () =>{
        setSelectedQuestions([]);
        handleCloseOpenConfirmAddQuestion(false);
    }

    // Função que é chamada quando as questões selecionadas mudam
    const handleQuestionsSelected = (questions) => {
        setSelectedQuestions(questions);
        handleCloseOpenConfirmAddQuestion();
    };

    async function loadQuestionsEvaluation(){
        try {
            const response = await api.get('evaluation/show/questions/'+evaluationId);
            if (response.status === 200) {
                setQuestions(response.data.evaluation_questions);
                setHasApplication(response.data.has_application);
                setEvaluation(response.data.evaluation);
            } else {
                setQuestions([]);
            }
        } catch (error) {

        }
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        if(evaluationId){
            loadQuestionsEvaluation(evaluationId);
        } else {
            setQuestions([]);
        }
    }, [refresh]);

  return (
      <div className={classes.content}>
          {evaluation != null &&
              <div>
                  <Card style={{marginTop: '5px'}}>
                      <CardHeader
                          avatar={
                              <div>
                                  { evaluation &&
                                      <div>
                                          { !hideDescription &&
                                          <div className={classesGeneral.subtitleList} style={{fontWeight: 'bold'}}>
                                              {'Descrição: '+truncateText(evaluation.description, 20, isSmallScreen)}
                                          </div>}
                                          <div className={classesGeneral.subtitleList}>
                                              {"Total de questões: "}
                                              {questions ? questions.length : null}
                                          </div>
                                          {evaluation.totalQuestionsWithoutContent > 0 &&
                                              (<div className={classesGeneral.subtitleList} style={{fontWeight: 'bold'}}>
                                                  {"Total de questões sem conteúdo: "}
                                                  {evaluation ? evaluation.totalQuestionsWithoutContent : null}
                                              </div>)}
                                          <div className={classesGeneral.subtitleList}>
                                              {"Criada por: " + truncateText(evaluation.user.name, 9, isSmallScreen) + " em " + moment(evaluation.created_at).format('DD/MM/YYYY')+"."}
                                          </div>
                                          { !hasApplication &&
                                              <div className={classesGeneral.subtitleList}>
                                                  {!isSmallScreen &&
                                                      <Button color="primary" onClick={handleClickOpenAddQuestion}>
                                                          Adicionar Questões
                                                      </Button>}

                                                  <Dialog
                                                      open={openAddQuestion}
                                                      onClose={handleCloseAddQuestion}
                                                      fullWidth
                                                      maxWidth="md">
                                                      <DialogTitle>
                                                          <Box display="flex" justifyContent="space-between" alignItems="center">
                                                              <div className={classesGeneral.subtitles} style={{ fontSize: 15 }}>
                                                                  Lista de Questões
                                                              </div>
                                                              <IconButton
                                                                  color="secondary"
                                                                  onClick={handleCloseAddQuestion}
                                                                  style={{ marginLeft: 'auto' }}
                                                                  aria-label="fechar">
                                                                  <CloseIcon />
                                                              </IconButton>

                                                          </Box>
                                                    </DialogTitle>
                                                      <DialogContent>
                                                          <QuestionList
                                                              enableSearchQuestionBank={true}
                                                              onQuestionsSelected = {handleQuestionsSelected}
                                                              onClose={handleCloseAddQuestion}/>
                                                      </DialogContent>
                                                  </Dialog>
                                                  <DialogQuestione handleClose={handleCloseOpenConfirmAddQuestion}
                                                                   open={openConfirmAddQuestion}
                                                                   onClickAgree={onClickConfirmAddQuestions}
                                                                   onClickDisagree={onClickNoConfirmAddQuestions}
                                                                   mesage={'Deseja adicionar a(s) questão(ões) '+selectedQuestions+"?"}
                                                                   title={'Adicionar Questões a Avaliaçao'}/>
                                              </div>}
                                      </div>}

                              </div>
                          }
                      />
                  </Card>
                  <Card>
                      <CardContent>
                          {questions == null ?
                              <LinearProgress color="secondary" />
                              :
                              questions.length == 0 ?
                                  <div className={classesGeneral.textRedInfo}>
                                      {'Esta avaliação não possui questões.'}
                                  </div>
                              :
                              <div>
                                  {evaluation.totalQuestionsByContent && evaluation.totalQuestionsByContent.length > 0 ?
                                      <div style={{ width: '100%', marginBottom: '20px' }}>
                                          <Accordion style={{ width: '100%' }}>
                                              <AccordionSummary
                                                  expandIcon={<ExpandMoreIcon />}
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                              >
                                                  <TooltipQuestione description={'Algumas questões podem estar associadas a mais de um conteúdo. Nesses casos, a soma total das questões por conteúdo pode exceder o número real de questões disponíveis no banco, já que cada questão será contabilizada em todos os conteúdos aos quais está vinculada.'} position={'top'} content={
                                                      <Typography>Clique para visualizar os conteúdos das questões
                                                          <i className="fa-solid fa-circle-info"
                                                              style={{
                                                                  marginLeft: '4px',
                                                                  fontSize: '14px'
                                                              }}></i>
                                                      </Typography>
                                                  }/>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                  <ContentList questionBank={evaluation} showDifficulty={true}/>
                                              </AccordionDetails>
                                          </Accordion>
                                      </div>
                                      :
                                      null}
                                  {/*questions && <GeneratePDF questions={questions}/>*/}
                                  {questions.map((data, i) => (
                                      <Box display="flex" style={{marginBottom: '20px'}}>
                                          <Hidden xsDown>
                                              <Chip label={(i + 1)}
                                                    style={{fontSize: '14px',
                                                        fontWeight: 'bold',
                                                        margin: '8px'}} color="secondary" size="medium"/>
                                          </Hidden>
                                          <EvaluationQuestionCard
                                              question={data.question}
                                              id_evaluation={evaluationId}
                                              setQuestions={setQuestions}
                                              hasApplication={hasApplication}
                                              setRefresh={setRefresh}
                                          />
                                      </Box>

                                  ))}

                              </div>
                          }
                      </CardContent>
                  </Card>
              </div>
          }
      </div>
  );
};

EvaluationQuestions.propTypes = {
    className: PropTypes.string,
    hideDescription: PropTypes.bool,

};

export default withRouter(EvaluationQuestions);
