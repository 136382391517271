import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tab,
  Tabs,
  Box,
  Typography,
  AppBar,
  Tooltip,
  LinearProgress,
  Breadcrumbs,
  Link,
  Chip,
  Hidden,
  Button,
  TableContainer,
  Paper,
  IconButton, Collapse, FormControlLabel, Checkbox, Grid
} from '@material-ui/core';
import api from "../../../../../services/api";
import PerfectScrollbar from "react-perfect-scrollbar";
import {Done, Close, Block, ExpandLess, ExpandMore} from "@material-ui/icons";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import EvaluationApplicationResultsSkillObjects from "./EvaluationApplicationResultsSkillObjects";
import { toast } from 'react-toastify';
import { removeDestionationPath } from '../../../../../services/navigation';
import useStyles from "../../../../../style/style";
import {CharmHome} from "../../../../../icons/Icons";
import EvaluationQuestionCard
  from "../../../../Professor/Evaluation/EvaluationQuestions/components/EvaluationQuestionCard";
import {formatStudentName} from "../../../../../common/formatStudentName";
import moment from 'moment';
import 'moment/locale/pt-br';
import OverviewQuestionModal from "./OverviewQuestionModal/OverviewQuestionModal";
import CustomSelect from "../../../../../components/CustomSelect";
import {Alert} from "@material-ui/lab";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import QuestionText from "../../../../../components/QuestionText";
import QuestionSkill from "../../../../Professor/Question/QuestionDetails/QuestionSkill";
import RadarChart from "./EvaluationApplicationResultsStudents/RadarChart/RadarChart";
import EvaluationApplicationResultsStudents from "./EvaluationApplicationResultsStudents";
import LineChartAdaptive
  from "./EvaluationApplicationAdaptiveResultsOverViewQuestion/LineChartAdaptive/LineChartAdaptive";
import {getLevel} from "../../../../../helpers/LevelStudent";

moment.locale('pt-br');

const useStylesLocal = makeStyles(() => ({
  root: {
    margin: 10,
  },
  content: {
    padding: 0
  },
  headStudent: {
    width: '100px ',
    height: '115px',
    backgroundColor: '#FFF',
    color: '#393A68',
    paddingLeft: '12px',
    boxSizing: 'border-box',
    border: '1px solid #f2f2f2',
    lineHeight: '40px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial'
  },
  headPercentage: {
    width: '30px',
    height: '90px',
    backgroundColor: '#FFF',
    color: '#393A68',
    paddingLeft: '12px',
    boxSizing: 'border-box',
    border: '1px solid #f2f2f2',
    lineHeight: '40px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial'
  },
  bodyStudent: {
    maxWidth: '170px',
    width: '100px',
    height: '82px',
    backgroundColor: '#FFF',
    color: '#393A68',
    paddingLeft: '6px',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    border: '1px solid #f2f2f2',
    lineHeight: '20px',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial',
  },
  bodyPercentage: {
    width: '20%',
    height: '82px',
    textAlign: 'center',
    backgroundColor: '#FFF',
    color: '#393A68',
    boxSizing: 'border-box',
    fontWeight: 'bold',
    border: '1px solid #f2f2f2',
    lineHeight: '15px',
    fontSize: '12px',
    whiteSpace: 'nowrap',
    overflow: 'auto',
    textOverflow: 'ellipsis',
    fontFamily: 'Open Sans, sans-serif, Helvetica, Arial'
  },
  headQuestion: {
    width: '90px',
    backgroundColor: '#FFF',
    color: '#393A68',
    textAlign: 'center',
    height: '115px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  percentageRed: {
    backgroundColor: '#F14D76',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageOrange: {
    backgroundColor: '#F5A623',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageGreen: {
    backgroundColor: '#5DE2A5',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    color: '#fff',
    borderRadius: 4
  },
  percentageNull: {
    backgroundColor: '#90a4ae',
    color: '#fff',
    display: 'block',
    margin: '8px',
    padding: '0 4px',
    textAlign: 'center',
    borderRadius: 4
  },
  answerCorrect: {
    width: '90.0px',
    backgroundColor: '#5DE2A5',
    //display: 'inline-block',
    color: '#ffffff',
    textAlign: 'center',
    height: '82px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '25px',
  },
  answerIncorrect: {
    width: '90.0px',
    backgroundColor: '#F14D76',
    //display: 'inline-block',
    color: '#ffffff',
    textAlign: 'center',
    height: '82px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '25px',
  },
  answerNull: {
    width: '90.0px',
    backgroundColor: '#cfd8dc',
    //display: 'inline-block',
    color: '#393A68',
    textAlign: 'center',
    height: '82px',
    boxSizing: 'border-box',
    border: '1px solid #F2F2F2',
    minWidth: '80px',
    padding: '12px',
    fontWeight: 'bold',
    fontSize: '14px'
  },
  paperWrong: {
    width: '88%',
    backgroundColor: '#ef9a9a',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  paperRight: {
    width: '88%',
    backgroundColor: '#80cbc4',
    color: '#212121',
    margin: 3,
    padding: 8
  },
  labelStudent: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
      <div
          role="tabpanel"
          hidden={value !== index}
          id={`nav-tabpanel-${index}`}
          aria-labelledby={`nav-tab-${index}`}
          {...other}
      >
        {value === index && (
            <Box p={3}>
              <Typography>{children}</Typography>
            </Box>
        )}
      </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
      <Tab
          component="a"
          onClick={(event) => {
            event.preventDefault();
          }}
          {...props}
      />
  );
}

const TooltipCustomized = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const getEloColor = (elo) => {
  if (elo < 100) {
    return '#ff0000'; // Alerta máximo (vermelho)
  } else if (elo < 200) {
    return '#ff4d4d'; // Alerta elevado
  } else if (elo < 300) {
    return '#ff944d'; // Alerta moderado
  } else if (elo < 400) {
    return '#ffd24d'; // Alerta leve (amarelo escuro)
  } else if (elo < 500) {
    return '#ffef62'; // Alerta suave (amarelo claro)
  } else if (elo < 600) {
    return '#33eb91'; // Neutro (amarelo esverdeado)
  } else if (elo < 700) {
    return '#33eb91'; // Sucesso leve (verde claro)
  } else if (elo < 800) {
    return '#33eb91'; // Sucesso moderado
  } else {
    return '#33eb91'; // Sucesso máximo (verde)
  }
};

const optionsOrderStudents = [
  {
    id: 1,
    description: "Ordem alfabética"
  },
  {
    id: 2,
    description: "Maior habilidade primeiro"
  },
  {
    id: 3,
    description: "Menor habilidade primeiro"
  },
  {
    id: 4,
    description: "Maior quantidade de acertos nesta turma"
  },
  {
    id: 5,
    description: "Menor quantidade de acertos nesta turma"
  },
  {
    id: 6,
    description: "Maior quantidade de questões respondidas nesta turma"
  },
  {
    id: 7,
    description: "Menor quantidade de questões respondidas nesta turma"
  },
];

const EvaluationApplicationAdaptiveResults = props => {
  removeDestionationPath();

  const { className, history, location, ...rest } = props;
  
  const { idApplication, studentClassId } = props.match.params;
  
  const [ overviewQuestionsHead, setOverviewQuestionsHead ] = useState([]);

  const classes = useStylesLocal();
  const classesGeneral = useStyles();

  const [application, setApplication] = useState(null);
  const [students, setStudents] = useState(null);

  const [orderStudentSelect, setOrderStudentSelect] = useState(1);
  const [filterSelect, setFilterSelect] = useState(0);
  const [optionsFilterQuestions, setOptionsFilterQuestions] = useState([]);
  const [records, setRecords] = useState([]);


  async function findResults(id){
    try {
      const response = await api.get('/evaluation/adaptive/report/student/'+id);

      if (response.status === 200) {
        setStudents(response.data);
        setRecords(response.data)
        if(response.data[0]){
          setApplication(response.data[0].application)
        }


      } else if (response.status === 202) {
        toast.error(response.data.message);
      }

    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(idApplication){
      findResults(idApplication);
    }

  }, []);

  useEffect(() => {

    if (Array.isArray(students)) {
      const array = Array.from(
          new Map(
              students
                  .flatMap(student => student.objects) // "Achata" o array de objetos
                  .filter(obj => obj && obj.object && obj.object.id) // Verifica se o objeto e o id existem
                  .map(obj => [obj.object.id, obj.object]) // Mapeia para [id, objeto]
          ).values()
      );
      setOptionsFilterQuestions([{id: 0, description: 'Todos'}, ...array]);
    }

  }, [students]);


  useEffect(() => {
    if(students && students.length > 0){
      const studentsCopy = [...students];
      switch (Number(orderStudentSelect)) {
        case 1:
          studentsCopy.sort((a, b) => a.name.localeCompare(b.name));
          break;
        case 2:
          studentsCopy.sort((a, b) =>  b.elo_mean - a.elo_mean);
          break;
        case 3:
          studentsCopy.sort((a, b) => a.elo_mean - b.elo_mean);
          break;
        case 4:
          studentsCopy.sort((a, b) =>  b.total_questions_correct - a.total_questions_correct);
          break;
        case 5:
          studentsCopy.sort((a, b) => a.total_questions_correct - b.total_questions_correct);
          break;
        case 6:
          studentsCopy.sort((a, b) =>  b.total_questions_answered - a.total_questions_answered);
          break;
        case 7:
          studentsCopy.sort((a, b) => a.total_questions_answered - b.total_questions_answered);
          break;

      }

      setStudents(studentsCopy);
    }

  }, [orderStudentSelect]);

  useEffect(() => {
    if(Number(filterSelect) === 0 ){
      setStudents(records);
    } else if(filterSelect > 0 ){
      setStudents(records);
      const array = students;
      array.forEach(student => {
        student.objects = student.objects.filter(obj => obj.object.id === Number(filterSelect));
      });
      setStudents(array);

    }

  }, [filterSelect]);


  const handleChangeStudentQuestion = (event) => {
    setOrderStudentSelect(event.target.value);
  };

  const handleChangeFilterQuestion = (event) => {
    setFilterSelect(event.target.value);
  };


  return (
      <div className={classesGeneral.root}>
        {studentClassId &&
            <Box display="flex">
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/">
                <Box display="flex">
                  <Box style={{marginTop: '2px', marginRight: '5px'}}>
                    <CharmHome />
                  </Box>
                  <Box>
                    Início
                  </Box>
                </Box>
              </Link>
              <Link color="inherit" onClick={() => history.push('/student-class/professor')}>
                Turmas
              </Link>
              <Link color="inherit" onClick={() => history.goBack()}>
                Turma
              </Link>
              <div color="inherit">
                {application ? 'Resultado do simulado '+ application.description : 'Resultado do simulado'}
              </div>

            </Breadcrumbs>
          </Box>}
        <Card>

          <CardHeader
              subheader={<div className={classesGeneral.subtitleList}>{'O resultado do simulado permite visualizar os acertos e erros dos estudantes de uma forma geral, por questão e por conteúdo.'}</div>}
              title={<div className={classesGeneral.titleList}>{'Resultado do simulado '}</div>}/>
          <Divider />
          <Card className={classes.root}>
            {application!= null ?
              <CardContent>
                {/*<Typography variant="h5" color="textPrimary" component="p">
                  {'Código da aplicação: '+overviewQuestionsHead.idApplication +'.'}
            </Typography>*/}

                <div>
                  <div className={classesGeneral.subtitleList} style={{fontWeight: 'bold'}}>
                    {'Simulado: ' + application.description + '.'}
                  </div>
                  <div className={classesGeneral.subtitleList}>
                    {application.class ? 'Professor(a): ' + application.user.name + '.' : 'Professor(a): ' + localStorage.getItem("@Questione-name-user") + '.'}
                  </div>
                  <div className={classesGeneral.subtitleList}>
                    {application.class.description != null ?
                        'Turma: ' + application.class.id_class + ' - ' + application.class.description + '.' : null}
                  </div>
                  {application.bank ?
                      <div className={classesGeneral.paperTitleTextBold} style={{marginTop: '10px', color: '#b26a00'}}>
                        {'Simulado adaptativo do Banco de Questões: '+application.bank.description+'.'}</div> : null}
                  {/*<RadarChart data={data} studentData={studentData}  content={"Gráfico radar"} />*/}

                </div>
                {students == null ? null :
                    !students[0] ?
                        <span className={classes.percentageRed}>SEM RESULTADO</span>
                        : null}
              </CardContent> : null}
          </Card>
          {students == null || students.length == 0 ? (
              <LinearProgress color="secondary" />
          ) : students.length > 0 ? (
              <Grid container spacing={2}>
                <Box
                    display="flex"
                    flexDirection="row" // Change this to row for side-by-side layout
                    alignItems="center" // Align items vertically centered
                    sx={{
                      width: '300px',
                      marginRight: '10px',
                      margin: '10px',
                      marginLeft: '10px',
                      padding: '10px',
                    }}
                >
                  <div style={{ marginRight: '10px' }}> {/* Add spacing between the selects */}
                    <label htmlFor="select-order-student" className="form-label">
                      Ordem dos alunos
                      <i className="fa-solid fa-sort" style={{ marginLeft: '10px' }}></i>
                    </label>
                    <select
                        id="select-order-student"
                        className="form-select"
                        value={orderStudentSelect}
                        onChange={handleChangeStudentQuestion}
                        aria-describedby="select-order-student"
                        style={{ width: '200px'}}
                    >
                      {optionsOrderStudents &&
                          optionsOrderStudents.map((type) => (
                              <option key={type.id || type} value={type.id || type}>
                                {type.description || type}
                              </option>
                          ))}
                    </select>
                  </div>
                  <div>
                    <label htmlFor="type-of-evaluation" className="form-label">
                      Filtrar conteúdo
                      <i className="fa-solid fa-filter" style={{ marginLeft: '10px' }}></i>
                    </label>
                    <select
                        id="select-order"
                        className="form-select"
                        value={filterSelect}
                        onChange={handleChangeFilterQuestion}
                        aria-describedby="select-order"
                        style={{ width: '200px' }}
                    >
                      {optionsFilterQuestions &&
                          optionsFilterQuestions.map((type) => (
                              <option key={type.id} value={type.id}>
                                {type.description ? type.description : type}
                              </option>
                          ))}
                    </select>
                  </div>
                </Box>
                
                <Grid container spacing={2}>

                  {students.map((student) => (
                      <Grid key={student.idStudent} item xs={12} sm={6} md={6}>
                        <Paper
                            style={{
                              height: '400px',
                              width: '100%',
                              paddingTop: '10px',
                              paddingLeft: '10px',
                              paddingRight: '10px',
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                            }}
                        >
                          {console.log(student.elo_mean)}
                          {/* Cabeçalho: nome à esquerda e porcentagem à direita */}
                          <Box
                              display="flex"
                              justifyContent="space-between"
                              alignItems="center"
                              style={{
                                padding: '15px',
                                background: getEloColor(student.elo_mean)
                              }}
                          >
                            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
                              {student.name} {/* Exibindo o nome do estudante */}
                            </div>
                            {/*student.total_questions_answered >= 15 ? getLevel(student.elo_mean) : null*/}
                            <div style={{ fontSize: '20px', fontWeight: 'bold' }}>
                              {student.total_questions_correct} / {student.total_questions_answered}
                            </div>
                          </Box>
                            <LineChartAdaptive data={Number(filterSelect) === 0 ? student.objects : student.objects[0] ? student.objects[0].content_decomposition : student.objects} content="Evolução da Habilidade por Conteúdo"
                                               width={100} height={400} filterSelect={filterSelect}
                                               highlightCount={student.total_questions_answered}
                                               questions={student.questions}
                                               student={student.name}
                                                />

                          {/* Informações adicionais */}
                          <Box padding="15px">
                            <div style={{fontSize: '13px'}}>
                              Acertou {Math.round(student.percentage_correct)}% das questões.
                            </div>
                          </Box>

                        </Paper>
                      </Grid>
                  ))}
                </Grid>
              </Grid>
          ) : null}
       </Card>
      </div>
  );
};

EvaluationApplicationAdaptiveResults.propTypes = {
  className: PropTypes.string,
};

export default EvaluationApplicationAdaptiveResults;
