import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Switch,
    Collapse,
    List,
    ListItem,
    FormControlLabel,
    Typography,
    Box,
    Grid,
    ListItemIcon,
    ListItemText,
    Checkbox
} from '@material-ui/core';
import TooltipQuestione from "../../../../../components/TooltipQuestione";
import ShareIcon from "@material-ui/icons/Share";
import api from "../../../../../services/api";
import {toast} from "react-toastify";

export default function DialogStudentsAssigned({
                                                  open,
                                                  handleClose,
                                                  saveData,
                                                   application = null,
                                              }) {
    const [studentsAssigned, setStudentsAssigned] = useState(application.students_assigned);
    const [studentsClass, setStudentsClass] = useState([]);
    //const [studentsSelected, setStudentsSelected] = useState(application.students);

    async function loadStudents() {
        try {
            let url = 'class/professor/show/'+application.fk_class_id;
            const response = await api.get(url);
            if (response.status === 200) {

                const studentsSelected = JSON.parse(application.students || '[]');

                const usersArray = response.data.class_student_all.map(student => ({
                    hasAccess: studentsSelected.includes(student.user?.id),
                    id: student.user?.id,
                    name: student.user?.name
                })).filter(user => user.id !== undefined && user.name !== undefined)
                    .sort((a, b) => a.name.localeCompare(b.name));

                setStudentsClass(usersArray);

            } else if(response.data.message){
                toast.error(response.data.message);
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        loadStudents();
    }, []);

    useEffect(() => {
        setStudentsAssigned(application.students_assigned);
    }, [application]);

    const handleChangeShareEvaluation = (event) => {
        setStudentsAssigned(event.target.checked);
    };

    // Função para salvar os dados
    const handleSave = () => {
        const students = studentsClass
            .filter(user => user.hasAccess) // Filter for users with access
            .map(user => user.id);

        const data = {
            students_assigned: studentsAssigned,
            students: JSON.stringify(students)
        };
        saveData(data); // Envia os dados para a função de salvar
        handleClose();  // Fecha o diálogo
    };

    const handleCheckboxChange = (id) => {
        setStudentsClass(prevState =>
            prevState.map(user =>
                user.id === id ? { ...user, hasAccess: !user.hasAccess } : user
            )
        );
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>
                <Typography variant="subtitle1" color="textPrimary">
                    {"Configurações de Acesso ao Simulado: "+application.description}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={studentsAssigned}
                                onChange={handleChangeShareEvaluation}
                                name="share_evaluation"
                                color="primary"
                            />
                        }
                        label="Clique para restringir o acesso a este simulado"
                    />
                    <Collapse in={studentsAssigned} timeout="auto" unmountOnExit>
                        <div style={{
                            padding: '10px',
                            backgroundColor: '#f0f0f0',
                            borderRadius: '4px',
                            color: '#555',
                            fontSize: '14px',
                            lineHeight: '1.6',
                            marginBottom: '16px',
                            fontStyle: 'italic'
                        }}>{'Somente os estudantes selecionados nesta tela terão acesso a este simulado.'}</div>
                        {/* Add a button to select all students */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={studentsClass.every(user => user.hasAccess)}
                                    onChange={(event) => {
                                        const newHasAccess = event.target.checked; // Get the checkbox state
                                        setStudentsClass(prevStudents =>
                                            prevStudents.map(user => ({
                                                ...user,
                                                hasAccess: newHasAccess // Set hasAccess based on checkbox state
                                            }))
                                        );
                                    }}
                                    inputProps={{'aria-label': 'controlled'}}
                                />
                            }
                            label="Todos"
                        />
                        <List component="div" disablePadding>
                            {studentsClass && studentsClass.map(user => (
                                <ListItem key={user.id}>
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={user.hasAccess}
                                            onChange={() => handleCheckboxChange(user.id)}
                                            inputProps={{'aria-label': 'controlled'}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={`${user.name}`}/>
                                </ListItem>
                            ))}
                        </List>
                    </Collapse>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="secondary">
                    Cancelar
                </Button>
                <Button onClick={handleSave} color="primary">
                    Salvar
                </Button>
            </DialogActions>
        </Dialog>
    );
}
