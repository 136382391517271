// utils/formatName.js
export const formatStudentName = (fullName) => {
  return fullName;

  // Divide o nome completo em partes
  const nameParts = fullName.split(' ');



  // Verifica se há pelo menos 2 partes para pegar o primeiro e segundo nomes
  if (nameParts.length < 3) {
    // Se houver menos de dois nomes, retorna o nome original
    return fullName;
  }

  // Pega o primeiro e o segundo nomes
  const firstName = nameParts[0];
  const secondName = nameParts[1];

  // Pega as iniciais dos sobrenomes a partir do terceiro nome
  const initials = nameParts.slice(2).map(name => name.charAt(0).toUpperCase()).join('. ');

  // Retorna o formato desejado: Primeiro e Segundo nomes com as iniciais dos restantes
  return initials ? `${firstName} ${secondName} ${initials}.` : `${firstName} ${secondName}`;
};
