export const QUESTION_SEARCH_TYPE = '@Questione-search-type';
export const QUESTION_SEARCH_COURSE = '@Questione-search-course';
export const QUESTION_SEARCH_OBJECT = '@Questione-search-object';
export const QUESTION_SEARCH_TYPE_EVALUATION = '@Questione-search-type-evaluation';
export const QUESTION_SEARCH_YEAR = '@Questione-search-year-question';
export const QUESTION_SEARCH_PAGE = '@Questione-search-page';

export const QUESTION_TOTAL_PER_PAGE = '@Questione-total-per-page';

export const searchQuestions = (type, course, object, type_evaluation, year, total_per_page) => {
    localStorage.setItem(QUESTION_SEARCH_TYPE, type);
    localStorage.setItem(QUESTION_SEARCH_COURSE, course);
    localStorage.setItem(QUESTION_SEARCH_OBJECT, object);
    localStorage.setItem(QUESTION_SEARCH_TYPE_EVALUATION, type_evaluation);
    localStorage.setItem(QUESTION_SEARCH_YEAR, year);
    localStorage.setItem(QUESTION_TOTAL_PER_PAGE, total_per_page);
};

export const searchQuestionsPage = (page) => {
    localStorage.setItem(QUESTION_SEARCH_PAGE, page);
};
