import React, { useRef, useState } from 'react';
import Webcam from 'react-webcam';
import { Button, useMediaQuery } from '@material-ui/core';

const WebcamCapture = () => {
    const webcamRef = useRef(null);
    const [capturedImage, setCapturedImage] = useState(null); // Estado para armazenar a imagem capturada
    const [cameraActive, setCameraActive] = useState(true);
    const isSmallScreen = useMediaQuery('(max-width:600px)');


    const width = 2480;
    const height = 3510;

    const videoConstraints = {
        width: 540,
        facingMode: "environment"
    };

    const capture = React.useCallback(async () => {
        const imageSrc = webcamRef.current.getScreenshot(); // Captura a imagem
        setCapturedImage(imageSrc); // Armazena a imagem no estado
        //setCameraActive(false); // Desativa a câmera após a captura
    }, [webcamRef]);

    return (
        <div style={{ textAlign: 'center' }}>
            {/* Exibe a webcam se a câmera estiver ativa */}
            {cameraActive && (
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/png"
                    videoConstraints={videoConstraints}
                    width={width}
                    height={height}
                    style={{
                        width: '60%', // Ajusta a largura do componente ao container
                        height: 'auto', // Mantém a altura proporcional à largura
                        maxWidth: `${width}px`, // Define a largura máxima
                        maxHeight: `${height}px`, // Define a altura máxima
                        objectFit: 'cover', // Ajusta a imagem para cobrir a área sem distorção
                        marginBottom: '10px',
                    }}
                />
            )}

            {/* Botão para capturar a imagem */}
            {cameraActive && (
                <Button
                    variant="contained"
                    color="primary"
                    onClick={capture}
                    style={{ padding: '10px 20px', fontSize: '16px', height: '35px' }}
                >
                    {isSmallScreen ? 'Capturar Cartão' : 'Capturar Cartão-Resposta'}
                </Button>
            )}

            {/* Exibe a imagem capturada */}
            {capturedImage && (
                <div style={{ marginTop: '20px' }}>
                    <h3>Imagem Capturada</h3>
                    <img
                        src={capturedImage}
                        alt="Imagem Capturada"
                    />
                    {/* Botão para tentar novamente */}
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => setCameraActive(true)} // Reativa a câmera para nova captura
                        style={{ marginTop: '10px', padding: '10px 20px', fontSize: '16px' }}
                    >
                        {isSmallScreen ? 'Capturar Novamente' : 'Capturar Outra Imagem'}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default WebcamCapture;
