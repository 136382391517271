import React, { useEffect, useRef, useState } from 'react';
import 'katex/dist/katex.min.css';
import katex from 'katex';
import CloseIcon from "@material-ui/icons/Close";
import {Dialog, DialogContent, DialogTitle, IconButton} from "@material-ui/core";

// Função para remover espaços em branco e &nbsp; dentro dos cifrões
function removeSpacesAndNbspInsideDollar(text) {
    return text.replace(/\$([^\$]+)\$/g, (match, p1) => {
        // Remove espaços em branco e &nbsp; dentro dos cifrões
        let cleaned = p1.replace(/\s+/g, '').replace(/&nbsp;/g, '');
        return `$${cleaned}$`;
    });
}
//

const RenderCodeLatex = ({ text }) => {
    const containerRef = useRef(null);
    let textWithoutSpaces = removeSpacesAndNbspInsideDollar(text);

    const [open, setOpen] = useState(false);
    const [imageSrc, setImageSrc] = useState('');

    const handleClickImage = (src) => {
        setImageSrc(src);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const renderLatex = () => {
            if (containerRef.current) {
                const elements = containerRef.current.querySelectorAll('.latex-block, .latex-inline');
                elements.forEach((element) => {
                    const latex = element.innerHTML.trim();
                    try {
                        if (element.classList.contains('latex-inline')) {
                            element.innerHTML = katex.renderToString(latex, { throwOnError: false });
                        } else if (element.classList.contains('latex-block')) {
                            element.innerHTML = katex.renderToString(latex, { throwOnError: false, displayMode: true });
                        }

                    } catch (err) {
                        console.error('KaTeX rendering error:', err);
                    }
                });

                // Detect images and add links
                /*const images = containerRef.current.querySelectorAll('img');
                images.forEach((img) => {
                    // Create a link element
                    const link = document.createElement('a');
                    link.href = img.src;
                    link.target = '_blank';
                    link.rel = 'noopener noreferrer';

                    // Create a text node for the link
                    const linkText = document.createTextNode(' [Abrir imagem]');

                    // Append the link text next to the image
                    link.appendChild(linkText);
                    img.parentNode.insertBefore(link, img.nextSibling);
                });*/
                // Detect images and add click handler
                const images = containerRef.current.querySelectorAll('img');
                images.forEach((img) => {
                    img.style.cursor = 'pointer'; // Indicate clickable image
                    img.addEventListener('click', () => handleClickImage(img.src));
                });
            }
        };

        renderLatex();
    }, [textWithoutSpaces]);

    // Função para preparar LaTeX para renderização
    const prepare = (text) => {
        if (!text) return '';

        const latexBlockRegex = /\$\$([\s\S]*?)\$\$/g;
        const latexInlineRegex = /\$([\s\S]*?)\$/g;

        let result = text;

        // Renderizar LaTeX em bloco
        result = result.replace(latexBlockRegex, (match, p1) => {
            return `<div class="latex-block">${p1.trim()}</div>`;
        });

        // Renderizar LaTeX inline
        result = result.replace(latexInlineRegex, (match, p1) => {
            return `<span class="latex-inline">${p1.trim()}</span>`;
        });


        return result;
    };

    return (
        <div>
            <div style={{ padding: '6px' }} ref={containerRef} dangerouslySetInnerHTML={{ __html: prepare(textWithoutSpaces) }} />

            {/* Modal for displaying image */}
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth>
                <DialogTitle>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <img src={imageSrc} alt="Expanded view" style={{ width: '100%', height: 'auto' }} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default RenderCodeLatex;