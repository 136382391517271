import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Table,
  TableBody,
  LinearProgress,
  TablePagination, Grid
} from '@material-ui/core';
import api from '../../../../services/api';
import UsersToolbar from "./components/QuestionToolbar";
import PropTypes from "prop-types";
import QuestionCard from "../../../../components/QuestionCard/QuestionCard";
import {QUESTION_SEARCH_SKILL, searchQuestions, searchQuestionsPage} from "../../../../services/seacrhQuestions";
import useStyles from "../../../../style/style";
import AlertMessage from "../../../../components/Messages/AlertMessage";

const QuestionTable = props => {
  const { className, history } = props;

  const [questions, setQuestions] = useState(null);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState([]);
  const [refresh, setRefresh] = React.useState(0);
  const [page, setPage] = useState(0);

  //filtro de questões
  const [courseSelect, setCourseSelect] = useState(0);
  const [objectSelect, setObjectSelect] = useState(0);
  const [typeEvaluationSelect, setTypeEvaluationSelect] = useState(0);
  const [yearSelect, setYearSelect] = useState(0);
  const [filterByUser, setFilterByUser] = useState(0);
  const [questionsPerPageSelect, setQuestionsPerPageSelect] = useState(0);
  const [objectDecompositionSelect, setObjectDecompositionSelect] = useState(0);
  const [unfinishedQuestionsSelect, setUnfinishedQuestionsSelect] = useState(0);

  async function loadQuestions(page){
    try {
      let url = 'question?page='+page;

      if(unfinishedQuestionsSelect){
        url += '&user=N';
      } else if(filterByUser){
        url += '&user=S';
      } else {
        url += '&user=T';
      }

      if(questionsPerPageSelect > 0){
        url += '&totalPerPage='+questionsPerPageSelect;
      }
      if(courseSelect > 0){
        url += '&fk_course_id='+courseSelect;

        if(objectSelect > 0){
          url += '&fk_object_id='+objectSelect;
        }

        if(yearSelect > 0){
          url += '&year='+yearSelect;
        }

        if(typeEvaluationSelect > 0){
          url += '&fk_type_of_evaluation_id='+typeEvaluationSelect;
        }

        if(objectDecompositionSelect > 0){
          url += '&fk_object_decomposition_id='+objectDecompositionSelect;
        }

      }

      const response = await api.get(url);
      if(response.status === 200) {
        setTotal(response.data.total);
        setRowsPerPage(response.data.per_page);
        setQuestions(response.data.data);
      } else {
        setQuestions([]);
      }
    } catch (error) {
      //toast.error( 'Erro de conexão.');
    }
  }

  useEffect(() => {

    loadQuestions(page+1);
  }, [refresh]);

  const updateSearch = (e) => {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    setPage(0);
    loadQuestions(1);
    searchQuestionsPage(0);
  }, [
    courseSelect,
    objectSelect,
    objectDecompositionSelect,
    typeEvaluationSelect,
    yearSelect,
    filterByUser,
    questionsPerPageSelect,
    unfinishedQuestionsSelect]);

  const onClickSearch = (
      questionBankSelect,
      courseSelect,
      objectSelect,
      objectDecompositionSelect,
      typeEvaluationSelect,
      yearSelect,
      filterByUser,
      questionsPerPageSelect,
      unfinishedQuestionsSelect)=> {

    setPage(0);
    setCourseSelect(courseSelect);
    setObjectSelect(objectSelect);
    setObjectDecompositionSelect(objectDecompositionSelect);
    setTypeEvaluationSelect(typeEvaluationSelect);
    setYearSelect(Number(yearSelect));
    setQuestionsPerPageSelect(questionsPerPageSelect);
    setFilterByUser(filterByUser);
    setUnfinishedQuestionsSelect(unfinishedQuestionsSelect);
  }


  const handlePageChange = (event, newPage) => {
    loadQuestions(newPage+1);
    setPage(newPage);
    searchQuestionsPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
      <div className={classes.root}>
          <UsersToolbar
              searchText={searchText}
              onClickSearch={onClickSearch}/>
        <div className={classes.content}>

          {questions == null ?
              <LinearProgress color="secondary"    />
              : questions.length === 0 ?
                  <AlertMessage message="0 questões encontradas." />
                  :
                  <div>
                      <TablePagination
                          component="div"
                          count={total}
                          onChangePage={handlePageChange}
                          page={page}
                          rowsPerPage={rowsPerPage}
                          rowsPerPageOptions={[rowsPerPage]}/>
                      <Grid
                          container
                          spacing={1}>
                        <Grid
                            item
                            md={12}
                            xs={12}>
                          <Table>
                            <TableBody>
                              {questions.map(question => (
                                  <div style={{marginBottom: '20px'}}>
                                    <QuestionCard
                                        question={question}
                                        setRefresh={setRefresh}
                                        refresh={refresh}
                                        id_course={searchText[0].fk_course_id}/>
                                  </div>
                              ))}
                            </TableBody>
                          </Table>
                        </Grid>
                      </Grid>
                    <TablePagination
                        component="div"
                        count={total}
                        onChangePage={handlePageChange}
                        onChangeRowsPerPage={handleRowsPerPageChange}
                        page={page}
                        rowsPerPage={rowsPerPage}
                        rowsPerPageOptions={[rowsPerPage]}/>
                  </div>}

        </div>
      </div>
  );
};

QuestionTable.propTypes = {
  history: PropTypes.object
};

export default QuestionTable;
