import React, { useState } from 'react';
import TooltipQuestione from "../../../../components/TooltipQuestione";

const DifficultyIcons = ({ veryEasy, easy, medium, hard, veryHard }) => {
    const iconStyle = { opacity: 0.3 }; // Estilo para ícones desabilitados (quando valor é 0)

    return (
        <div style={{ marginTop: '5px', fontSize: '14px', display: 'flex', gap: '10px' }}>
            {/* Condicional para exibir o ícone apenas se o valor for maior que 0 */}
            <span title="Muito fácil" style={veryEasy > 0 ? {} : iconStyle}>
                ✅ {veryEasy > 0 ? veryEasy : 0} {/* Ícone de questão muito fácil */}
            </span>
            <span title="Fácil" style={easy > 0 ? {} : iconStyle}>
                🙂 {easy > 0 ? easy : 0} {/* Ícone de questão fácil */}
            </span>
            <span title="Médio" style={medium > 0 ? {} : iconStyle}>
                😐 {medium > 0 ? medium : 0} {/* Ícone de questão média */}
            </span>
            <span title="Difícil" style={hard > 0 ? {} : iconStyle}>
                😟 {hard > 0 ? hard : 0} {/* Ícone de questão difícil */}
            </span>
            <span title="Muito difícil" style={veryHard > 0 ? {} : iconStyle}>
                😫 {veryHard > 0 ? veryHard : 0} {/* Ícone de questão muito difícil */}
            </span>
        </div>
    );
};


const ContentList = ({ questionBank, showDifficulty = true }) => {
    const [openIndexes, setOpenIndexes] = useState([]);

    // Função para alternar a visibilidade de decomposições
    const toggleDecompositions = (index) => {
        if (openIndexes.includes(index)) {
            setOpenIndexes(openIndexes.filter(i => i !== index)); // Fechar decomposições
        } else {
            setOpenIndexes([...openIndexes, index]); // Abrir decomposições
        }
    };

    const containerStyle = {
        width: '100%',
        height: '100%', // Ajuste para ocupar a altura total do contêiner
        overflowY: 'auto', // Para rolagem vertical quando o conteúdo for muito grande
    };

    const tableStyle = {
        width: '100%', // A tabela ocupa 100% da largura disponível
        borderCollapse: 'collapse',
    };

    const cellStyle = {
        padding: '10px',
        borderBottom: '1px solid #ddd',
        width: '100%',
    };

    return (
        <div style={containerStyle}>
            <table style={tableStyle}>
                <tbody>
                {questionBank.totalQuestionsByContent.map((content, index) => {
                    // Verificar se há decomposições válidas (decomposition_description não é nulo)
                    const hasDecompositions = content.decompositions.length > 0 && content.decompositions[0].decomposition_description !== null;

                    return (
                        <React.Fragment key={index}>
                            {/* Renderizar o conteúdo principal com o ícone de + ou - se houver decomposições válidas */}
                            <tr onClick={() => hasDecompositions && toggleDecompositions(index)} style={{ cursor: hasDecompositions ? 'pointer' : 'default', fontWeight: 'bold' }}>
                                <td style={cellStyle}>
                                    {hasDecompositions && (openIndexes.includes(index) ? '−' : '+')} {/* Mostrar + ou - se houver decomposições válidas */}
                                    {content.total === 1 ? `${content.description} (${content.total} questão)` :
                                        `${content.description} (${content.total} questões)`}
                                    {showDifficulty &&
                                        (<TooltipQuestione
                                        description={
                                            <div>
                                                <strong>Ícones de Dificuldade da questão:</strong>
                                                <ul>
                                                    <li>✅ Muito fácil: {content.very_easy_difficulty} questão(s)</li>
                                                    <li>🙂 Fácil: {content.easy_difficulty} questão(s)</li>
                                                    <li>😐 Médio: {content.medium_difficulty} questão(s)</li>
                                                    <li>😟 Difícil: {content.hard_difficulty} questão(s)</li>
                                                    <li>😫 Muito difícil: {content.very_hard_difficulty} questão(s)</li>
                                                </ul>
                                            </div>
                                        }
                                        position={'top-start'} content={
                                            <DifficultyIcons
                                                veryEasy={content.very_easy_difficulty}
                                                easy={content.easy_difficulty}
                                                medium={content.medium_difficulty}
                                                hard={content.hard_difficulty}
                                                veryHard={content.very_hard_difficulty}
                                            />
                                            }/>)}

                                </td>
                            </tr>


                            {/* Renderizar decomposições apenas se a "pasta" estiver "aberta" e houver decomposições válidas */}
                            {openIndexes.includes(index) && hasDecompositions && content.decompositions.map((decomposition, subIndex) => (
                                decomposition.decomposition_description && (
                                    <tr key={`${index}-${subIndex}`} style={{ backgroundColor: '#f9f9f9' }}>
                                        <td style={{ ...cellStyle, paddingLeft: '20px', color: 'gray' }}>
                                            {/* Sub-item de decomposição */}
                                            {`${decomposition.decomposition_description} (${decomposition.total_decompositions} ${decomposition.total_decompositions === 1 ? 'questão' : 'questões'})`}
                                        </td>
                                    </tr>
                                )
                            ))}
                        </React.Fragment>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export default ContentList;
