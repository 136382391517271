import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { uniqueId } from 'lodash';
import {
    MenuItem,
    TextField,
    Button, Grid, Tooltip, Divider, FormControlLabel, Checkbox
} from "@material-ui/core";
import api from "../../../../../services/api";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import Save from "@material-ui/icons/Save";
import { toast } from 'react-toastify';
import CustomSelect from "../../../../../components/CustomSelect";
import EmailDialog from "../../../../../components/EmailDialog/EmailDialog";
import ExplanatoryMessage from "../../../../../components/ExplanatoryMessage/ExplanatoryMessage";

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    btRemove: {
        color: '#f44336',
        marginRight: 2
    }
});

const QuestionSkill = props => {
    const { className, idQuestion, history, tabValueChange, showArea= true, ...rest } = props;
    const [courses, setCourses] = useState([{ 'id': '0', 'description': 'Todas as áreas' }]);
    const [objects, setObjects] = useState([{ 'id': '0', 'description': 'Todos os conteúdos' }]);
    const [skills, setSkills] = useState([{ 'id': '0', 'description': 'Todas as competências' }]);
    const [courseSelect, setCourseSelect] = useState(0);
    const [objectSelect, setObjectSelect] = useState([]);
    const [skillSelect, setSkillSelect] = useState([]);
    const [inputObjects, setInputObjects] = useState([
        { idItem: 0, objectSelected: 0, decomposition: [], selectedIds: [] }
    ]);
    const [btAddObject, setBtAddObject] = useState(false);
    const [btRemoveObject, setBtRemoveObject] = useState(false);
    const [question, setQuestion] = useState(false);
    const [objectsDelete, setObjectsDelete] = useState([]);
    const [objectLoading, setObjectLoading] = useState(false);

    const [showDialogEmail, setShowDialogEmail] = useState(false);

    const handleShowEmail = () => setShowDialogEmail(true);
    const handleCloseEmail = () => setShowDialogEmail(false);

    const classes = useStyles();

    async function loadQuestion() {
        try {
            const response = await api.get('question/show/' + idQuestion);
            if (response.status === 200) {
                setQuestion(response.data[0]);
            }
        } catch (error) {
        }
    }

    async function loadCourses() {
        try {
            const response = await api.get('all/courses-user');
            setCourses([{ 'id': '0', 'description': 'Todos as áreas' }, ...response.data]);
        } catch (error) {

        }
    }

    // Função para verificar quais IDs estão presentes na lista de decomposições
    const findMatchingDecompositions = (decompositionsQuestionIds, decompositions) => {
        const decompositionIdsSet = new Set(decompositions.map(item => item.id));
        const matchingIds = decompositionsQuestionIds.filter(id => decompositionIdsSet.has(id));

        return matchingIds;
    };

    async function loadObjectsSelectQuestion() {
        try {
            setObjectLoading(true);

            const response = await api.get('question/object-question/' + idQuestion);

            if (response.status === 200) {
                const values = [];

                if (response.data.itens.length > 0) {
                    const decomposition_question_ids = response.data.decomposition_question_ids;

                    response.data.itens.forEach(function logArrayElements(element, index, array) {
                        if (element.object.fk_regulation_id == courseSelect) {
                            values.push({
                                idItem: response.data.itens[index].id,
                                objectSelected: response.data.itens[index].fk_knowledge_object,
                                decomposition: response.data.itens[index].object.decomposition,
                                selectedIds: findMatchingDecompositions(decomposition_question_ids, response.data.itens[index].object.decomposition),
                            });
                        } else {
                            deleteObject(element.id);
                        }

                    });

                    if (values[0]) {
                        setInputObjects(values);
                    } else {
                        setInputObjects([{ idItem: 0, objectSelected: 0, decomposition: [], selectedIds: [] }]);
                    }
                }
            }

            setObjectLoading(false);
        } catch (error) {
            setObjectLoading(false);
        }
    }

    async function loadObjects() {
        try {
            setObjectLoading(true);

            const response = await api.get('all/objects-by-regulation?fk_regulation_id=' + courseSelect);

            setObjects([{ 'id': '0', 'description': 'Todos os conteúdos' }, ...response.data]);
            //setObjectSelect(0);
            loadObjectsSelectQuestion();
            setObjectLoading(false);
        } catch (error) {
            setObjectLoading(false);
        }
    }

    async function loadSkills() {
        try {
            const response = await api.get('all/skills-by-regulation?fk_regulation_id=' + courseSelect);
            if (response.status == 200) {
                setSkills([{ 'id': '0', 'description': 'Todas as competências' }, ...response.data]);
                setSkillSelect(0);
                if (question.fk_skill_id != null) {
                    response.data.forEach(function logArrayElements(element, index, array) {
                        if (element.id == question.fk_skill_id) {
                            setSkillSelect(question.fk_skill_id);
                        }
                    });
                }
            }


        } catch (error) {

        }
    }

    useEffect(() => {
        const length = inputObjects.length;
        if (length == 0) {
            setBtAddObject(true);
            setBtRemoveObject(false);
        } else if (length == 3) {
            setBtAddObject(false);
            setBtRemoveObject(true);
        } else {
            setBtAddObject(true);
            setBtRemoveObject(true);
        }

    }, [inputObjects]);

    useEffect(() => {
        if (idQuestion) {
            loadQuestion();
        } else {
            setObjects([{ 'id': '0', 'description': 'Todos os conteúdos' }]);
            setObjectSelect(0);
            setSkills([{ 'id': '0', 'description': 'Todas as competências' }]);
            setSkillSelect(0);
        }
    }, []);

    useEffect(() => {
        loadCourses();

    }, [question]);

    useEffect(() => {
        verifyToSave();
    }, [tabValueChange]);

    /*useEffect(() => {

        return () => {

            onClickSkill();
        }

    }, []);*/

    useEffect(() => {
        setInputObjects([{ idItem: 0, objectSelected: 0, decomposition: [], selectedIds: [] }]);
        loadSkills();
        loadObjects();
    }, [courseSelect]);

    useEffect(() => {


    }, [inputObjects]);

    useEffect(() => {
        if(question.fk_regulation_id != null){
            setCourseSelect(question.fk_regulation_id);
        } else if (question.skill != null) {
            setCourseSelect(question.skill.fk_regulation_id);
        } else if(question.fk_course_id) {
            let id_regulation = 0;
            courses.forEach(
                function (element) {
                    if(element.id == question.fk_course_id) {

                        if (element.regulations) {
                            id_regulation = element.regulations[0].id;
                            return false;
                        }
                    }

                }
            );
            setCourseSelect(id_regulation);
        } else{
            setCourseSelect(0);
        }

    }, [courses]);


    const handleAddObjects = () => {
        const values = [...inputObjects];
        const length = inputObjects.length;
        //o máximo são três objetos de conhecimento
        if (length == 3) {
            return;
        }

        values.push({ idItem: `knowledge-object-${uniqueId()}${Date.now()}`, objectSelected: 0 });
        setInputObjects(values);
    };

    const handleRemoveObjects = (idItem) => {
        const length = inputObjects.length;
        //se só tiver um elemento ele retorna pois não pode excluir
        if (length == 0) {
            return;
        }
        setObjectsDelete((lastObjectsDelete) => {
            lastObjectsDelete.push({ idItem });
            return lastObjectsDelete;
        });
        setInputObjects((lastObjects) => lastObjects.filter((object) => object.idItem !== idItem));

    };

    const handleInputChangeObject = (event, index) => {

        const values = [...inputObjects];

        values[index].objectSelected = event.target.value;
        const selectedObject = objects.find((option) => option.id === event.target.value);
        values[index].decomposition = selectedObject.decomposition;
        setInputObjects(values);
    };

    async function saveSkill() {
        try {
            const fk_regulation_id = courseSelect;
            let fk_skill_id = skillSelect;
            if (skillSelect == 0) {
                fk_skill_id = null;
            }
            const data = {
                fk_regulation_id, fk_skill_id
            }
            const response = await api.put('question/update-course-skill/' + idQuestion, data);
            if (response.status === 200) {
                toast.success('Área de conhecimento atualizada.');
            }
        } catch (error) {

        }
    }

    async function deleteObject(idObject) {
        try {
            setObjectLoading(true);
            const response = await api.delete('question/deleteobject/' + idObject);
            if (response.status === 200 || response.status === 201) {

            }
            setObjectLoading(false);
        } catch (error) {
            setObjectLoading(false);
        }
    }

    async function saveObject(element, index) {
        try {
            setObjectLoading(true);

            const fk_question_id = question.id;
            const fk_knowledge_object = element.objectSelected;
            const data = {
                fk_question_id, fk_knowledge_object
            }

            let response = {};
            if (element.idItem !== 0 && `${element.idItem}`.indexOf('knowledge-object-') === -1) {
                response = await api.put('question/update-object/' + element.idItem, data);
            } else {
                response = await api.post('question/addobject', data);
            }

            if (response.status === 200) {
                inputObjects[index].idItem = response.data[0].id;
                setInputObjects(inputObjects);
                //toast.success( 'Objetos de conhecimento atualizados.');
            }

            setObjectLoading(false);
        } catch (error) {
            setObjectLoading(false);
        }
    }

    async function saveDecompositions(decomposition) {
        try {
            setObjectLoading(true);
            const decomposition_ids = decomposition;
            const fk_question_id = question.id;
            const data = {
                decomposition_ids, fk_question_id
            }


            const response = await api.post('question/decomposition', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });


            if (response.status === 200) {
                setInputObjects(inputObjects);
                //toast.success( 'Objetos de conhecimento atualizados.');
            }

            setObjectLoading(false);
        } catch (error) {
            setObjectLoading(false);
        }
    }

    const verifyToSave = () => {
        if (courseSelect == 0) {
            return 'Informe a área.';
        } else {
            saveSkill();
            objectsDelete.forEach(function logArrayElements(element, index, array) {
                deleteObject(element.idItem);
            });

            if (inputObjects.length > 0) {
                const reduced = [];

                let decompositions = [];
                inputObjects.forEach((item) => {
                    if(item.selectedIds) {
                        item.selectedIds.forEach((idDecomposition) => {
                            decompositions.push(idDecomposition);
                        });
                    }

                    var duplicated  = reduced.findIndex(redItem => {
                        return item.objectSelected === redItem.objectSelected;
                    }) > -1;

                    if(!duplicated) {
                        reduced.push(item);
                    }
                });
                if(decompositions.length > 0 ){
                    saveDecompositions(decompositions);
                }

                reduced.forEach(function logArrayElements(element, index, array) {
                    if (element.objectSelected != 0) {
                        saveObject(element, index);
                    } else {
                        deleteObject(element);
                    }
                });
            }
            return true;
        }

    }

    const onClickSkill = () => {
        let messageSaveItens = verifyToSave();
        if(messageSaveItens != true)
            toast.error(messageSaveItens);

    }

    const handleChangeCourse = (event) => {
        setCourseSelect(event.target.value);
    }

    const handleCheckboxChange = (event, index) => {
        const { value, checked } = event.target;
        const selectedId = parseInt(value, 10);

        setInputObjects((prevInputObjects) => {
            // Copiar o estado atual
            const updatedInputObjects = [...prevInputObjects];

            // Verificar se o item no índice existe
            if (!updatedInputObjects[index]) {
                return prevInputObjects; // Retorna o estado anterior se o índice não existir
            }

            // Garantir que selectedIds é um array
            if (!Array.isArray(updatedInputObjects[index].selectedIds)) {
                updatedInputObjects[index].selectedIds = [];
            }

            // Atualizar o array de IDs selecionados para o índice especificado
            if (checked) {
                // Adicionar o ID se o checkbox foi marcado
                if (updatedInputObjects[index].selectedIds.indexOf(selectedId) === -1) {
                    updatedInputObjects[index].selectedIds.push(selectedId);
                }
            } else {
                // Remover o ID se o checkbox foi desmarcado
                updatedInputObjects[index].selectedIds = updatedInputObjects[index].selectedIds.filter(
                    (id) => id !== selectedId
                );
            }


            return updatedInputObjects;
        });
    };

    return (
        <div>
            <div style={{ padding: "15px" }}>
                { showArea === false ?
                    null :
                <TextField
                    key="area"
                    select
                    label="Selecione a área"
                    value={courseSelect}
                    onChange={handleChangeCourse}
                    variant="outlined"
                    margin="dense"
                    style={{ width: "100%" }}>
                    {courses.map((option) => (
                        option.id == 0 ?
                            <MenuItem key={option.id} value={option.id}>
                                {option.description}
                            </MenuItem>
                        :
                        option.regulations && option.regulations.map((regulation) => (
                                <MenuItem key={regulation.id} value={regulation.id}>
                                    {option.description}
                                </MenuItem>
                            )

                        )

                    ))}
                </TextField>}
            </div>

            {/*<div style={{ padding: "15px" }}>
                <TextField
                    id="filled-select-currency"
                    select
                    label="Selecione a competência"
                    value={skillSelect}
                    onChange={onChangeSkill}
                    variant="outlined"
                    margin="dense"
                    style={{ width: "100%" }}>
                    {skills.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.description}
                        </MenuItem>
                    ))}
                </TextField>
            </div>*/}

            <div style={{padding: "15px"}}>
                { showArea === true ?
                    <ExplanatoryMessage
                        message={
                            <>
                                {'Você pode associar tópicos ao conteúdo para realizar análises mais detalhadas sobre o desempenho dos alunos. Se o conteúdo não apresentar tópicos ou se precisar adicionar algum, '}
                                <a
                                    href="#"
                                    onClick={handleShowEmail}
                                    style={{ color: '#007bff', textDecoration: 'underline', cursor: 'pointer' }}
                                >
                                    entre em contato com o administrador.
                                </a>
                            </>
                        }
                    />: null}
                <EmailDialog show={showDialogEmail} areas={courses} handleClose={handleCloseEmail}/>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Tooltip title="A questão deverá ter no máximo 03 conteúdos.">
                        {btAddObject == true ?
                            <Button size="small" color="primary" onClick={handleAddObjects}>Adicionar
                                Conteúdo</Button> :
                            <Button size="small" color="primary" disabled>Adicionar Conteúdo</Button>
                        }
                    </Tooltip>
                    {/* {btRemoveObject == true ?
                       <Button style={{marginLeft: "10px"}} className={clsx(classes.btRemove, className)} variant="outlined" onClick={() => handleRemoveObjects()}>Remover Objeto</Button> :
                       <Button style={{marginLeft: "10px"}} className={clsx(classes.btRemove, className)} variant="outlined" disabled>Remover Objeto</Button>
                   } */}
                </Grid>
            </div>

            {inputObjects.map((inputField, index) => (
                <div>
                    <div style={{padding: "15px", display: 'flex', alignItems: 'center'}}>
                        <TextField
                            id={"obj" + index}
                            select
                            label="Selecione o conteúdo"
                            value={inputObjects[index].objectSelected}
                            onChange={(event) => handleInputChangeObject(event, index)}
                            variant="outlined"
                            margin="dense"
                            style={{width: "100%"}}
                            name={'obj' + index}>
                            {objects.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.description}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Button style={{ marginLeft: "10px", marginTop: '2px', maxHeight: '38px' }} className={clsx(classes.btRemove, className)} variant="outlined" onClick={() => handleRemoveObjects(inputField.idItem)}>Remover</Button>
                    </div>
                    <div style={{ padding: "15px", display: 'flex', alignItems: 'center'}}>
                        {inputObjects[index].objectSelected > 0 && inputObjects[index].decomposition && inputObjects[index].decomposition.length > 0 ?
                            <div style={{border: '1px solid #1E90FF', padding: '10px'}}>
                                <div style={{fontWeight: 'bold', color: 'blue', marginBottom: '10px'}}>
                                    {'Selecione tópicos para esta questão:'}
                                </div>
                                <Grid container spacing={2}>
                                    {inputObjects[index].decomposition.map((decomp, idx) => (
                                        <Grid item xs={3} key={idx}>
                                            {/* xs={3} significa que cada item ocupará 3/12 da largura, ou seja, 4 colunas por linha */}
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        id={`decomposition-${idx}`}
                                                        name={`decomposition-${idx}`}
                                                        value={decomp.id}
                                                        onChange={(event) => handleCheckboxChange(event, index)}
                                                        checked={inputObjects[index].selectedIds ? inputObjects[index].selectedIds.includes(decomp.id) : null}

                                                    />
                                                }
                                                label={decomp.description}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </div>
                            :
                            <div style={{fontWeight: 'bold'}}>{'Nenhum tópico cadastrado para este conteúdo.'}</div>}
                    </div>
                </div>

            ))}
            <div style={{marginTop: '16px'}}>
                <Divider/><br/>
            </div>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center" style={{padding: "15px"}}>
                <Button
                    color="primary"
                    variant="outlined"
                    className={classes.button}
                    disabled={objectLoading}
                    onClick={onClickSkill}>
                    Salvar área de conhecimento
                </Button>

            </Grid>
            {/*<Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center">
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={onClickSkill}
                    endIcon={<Save />}
                    style={{ marginTop: '20px' }}
                    disabled={objectLoading}>
                    Salvar
               </Button>
            </Grid>*/}
        </div>

    );
}

QuestionSkill.propTypes = {
    className: PropTypes.string,
    idQuestion: PropTypes.number,
};

export default withRouter(QuestionSkill);
