import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField, IconButton
} from '@material-ui/core';
import api from "../../../../services/api";
import { toast } from 'react-toastify';
import validate from "validate.js";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

const schema = {
  description: {
    presence: { allowEmpty: false,  message: 'A descrição é obrigatória.'},
    length: {
      minimum: 5,
      maximum: 300,
      message: 'A descrição deve conter no mínimo 5 e no máximo 300 caracteres.'
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {}
}));

const ObjectDecompositionDetails = props => {
  const { className, history, ...rest } = props;
  const [regulations, setRegulations] = useState([{'id': '0', 'description': '- Escolha uma portaria -'}]);
  const { codigoObject, codigoDecomposition } = props.match.params;

  const classes = useStyles();

  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  async function loadRegulations(){
    try {
      const response = await api.get('regulation/all');
      if(response.status === 200) {
        setRegulations([...regulations, ...response.data]);
      }
    } catch (error) {

    }
  }

  async function saveObjectDetails(){
    try {
      const fk_knowledge_objects_id = codigoObject;
      const description = formState.values.description;
      const id = formState.values.id;
      const data = {
        fk_knowledge_objects_id, description
      }
      let response= {};
      let acao = "";
      if(!id) {
         response = await api.post('content-decomposition', data);
         acao = "cadastrado";
      } else {
         response = await api.put('content-decomposition/'+id, data);
        acao = "atualizado";
      }
      if (response.status === 200) {
        toast.success('Assunto '+acao+'.');
        history.push('/object-decomposition/'+codigoObject);
      } else {
        if(response.data.message){
          toast.error(response.data.message);
        } else if(response.data.errors[0].description){
          toast.error(response.data.errors[0].description);
        } if(response.data.errors[0].fk_regulation_id){
          toast.error(response.data.errors[0].fk_regulation_id);
        }
      }

    } catch (error) {

    }
  }

  async function findAObjectDecomposition(id){
    try {
      const response = await api.get('content-decomposition/show/'+id);

      if (response.status === 200) {
        setFormState(formState => ({
          values: {
            'description': response.data.description,
            'id': response.data.id
          },
          touched: {
            ...formState.touched,
          }
        }));

      } else {
        if(response.data.message){
          toast.error(response.data.message);
        }
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    if(codigoDecomposition){
      findAObjectDecomposition(codigoDecomposition);
    }

  }, []);

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState(formState => ({
      ...formState,
      isValid: (errors || formState.values.fk_regulation_id==0) ? false : true,
      errors: errors || {}
    }));
  }, [formState.values]);

  const handleChange = event => {
    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    });
  };

  const hasError = field =>
      formState.touched[field] && formState.errors[field] ? true : false;

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        onSubmit={saveObjectDetails}>
        <div className={classes.contentHeader}>
          <IconButton onClick={handleBack}>
            <ArrowBackIcon />
          </IconButton>
        </div>
        <CardHeader
          subheader=""
          title="Assunto"/>
        <Divider />
        <CardContent>
          <Grid
            container
            spacing={3}>
            <Grid
              item
              md={6}
              xs={12}>
              <TextField
                fullWidth
                error={hasError('description')}
                helperText={
                  hasError('description') ? formState.errors.description[0] : null
                }
                label="Descrição"
                margin="dense"
                name="description"
                onChange={handleChange}
                value={formState.values.description || ''}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={saveObjectDetails}
            disabled={!formState.isValid}>
            Salvar
          </Button>
        </CardActions>
      </form>
    </Card>
  );
};

ObjectDecompositionDetails.propTypes = {
  className: PropTypes.string,
};

export default ObjectDecompositionDetails;
