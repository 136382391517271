import React, { useEffect, useState } from 'react';

import api from "../../../services/api";
import {
    Card,
    CardHeader,
    Tabs,
    Tab,
    Box,
    Typography,
    IconButton,
    Container,
    Grid,
    Link,
    AppBar,
    Toolbar,
    Dialog,
    Breadcrumbs,
    Button,
    LinearProgress,
    TablePagination,
    List,
    Divider,
    ListItem,
    ListItemText,
    Hidden,
    DialogTitle,
    TextField, useMediaQuery, Menu, MenuItem,
} from '@material-ui/core';

import People from './People';
import ApplicationTable from './Application/EvaluationApplicationTable';
import ApplicationListStudent from '../Student/ApplicationListStudent/ApplicationListStudent';
import {makeStyles} from "@material-ui/styles";
import useStyles from "./../../../style/style";
import CloseIcon from "@material-ui/icons/Close";
import GamificationPanel from "../../../components/GamificationPanel";
import {CharmHome} from "../../../icons/Icons";
import moment from "moment";
import EvaluationQuestions from "../../../components/EvaluationQuestions/EvaluationQuestions";
import {toast} from "react-toastify";
import AddIcon from '@material-ui/icons/Add';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ResultsGeneral from "../Professor/ResultsAplication/ResultsGeneral/components/ResultsGeneral";
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import AdaptiveEvaluationModal from "./AdaptiveEvaluationModal/AdaptiveEvaluationModal";

const useStylesLocal = makeStyles(theme => ({
    root: {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    content: {
        padding: 0,
        marginTop: theme.spacing(1)
    },
    chipgreen:{
        margin: 3,
        backgroundColor: '#009688',
        color: '#ffebee',
    },
    appBar: {
        position: 'relative',
        background: '#3a7cf7',
    },
}));


const StudentClassContent = props => {

    const { className, history, location, ...rest } = props;

    const studentClassId = location.pathname.replace('/student-class/', '');
    const level_user = localStorage.getItem("@Questione-acess-level-user");

    let initialTabValue = 0;

    if (localStorage.getItem('@questione/student-class-tab')) {
        initialTabValue = localStorage.getItem('@questione/student-class-tab');
    } else {
        if (level_user === '0') {
            initialTabValue = 3;
        }
    }
    
    const [refresh, setRefresh] = React.useState(1);
    const [totalQuestionsToGenerateTest, setTotalQuestionsToGenerateTest] = React.useState(0);
    const [tabValue, setTabValue] = useState(parseInt(initialTabValue));

    const classes = useStylesLocal();
    const classesGeneral = useStyles();

    const [classProfessor, setClassProfessor] = useState(null);
    const [openDialogPeople, setOpenDialogPeople] = React.useState(false);

    const [evaluationSelected, setEvaluationSelected] = useState(null);
    const [openNewApplication, setOpenNewApplication] = React.useState(false);
    const [opcaoNewApplicationMyEvaluations, setOpcaoNewApplicationMyEvaluations] = React.useState( true);
    const [openExpressSimulation, setOpenExpressSimulation] = React.useState(false);
    const [evaluations, setEvaluations] = useState(null);
    const [totalEvaluations, setTotalEvaluations] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const [tabIndex, setTabIndex] = useState(0);

    const isSmallScreen = useMediaQuery('(max-width:600px)');

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget); // Define o elemento onde o menu será ancorado
    };

    const handleClose = () => {
        setAnchorEl(null); // Fecha o menu
    };

    const handleAdaptive = () => {
        setOpenModalAdaptive(true);
        handleClose(); // Fecha o menu após selecionar
    };
    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    const [openModalAdaptive, setOpenModalAdaptive] = useState(false);  // Controla o modal

    const handleCloseModalAdaptive = () => {
        setOpenModalAdaptive(false);  // Fecha o modal
    };

    const handleSubmitAdaptive = () => {
        setOpenModalAdaptive(false);  // Fecha o modal após o envio
        window.location.reload();
    };

    const loadEvaluations = async (page) => {
        try {
            let status = 1;
            const response = await api.get(`evaluation`, {
                params: {
                    status,
                    page
                },
            });

            if (response.status == 200) {
                setTotalEvaluations(response.data.total);
                setEvaluations(response.data.data);
            } else {
                setEvaluations([]);
            }

        } catch (e) {

        }
    };
    const handlePageChangeEvaluations = (event, page) => {
        loadEvaluations(page + 1, 1)
        setPage(page);
    };

    const handleRowsPerPageChange = event => {
        setRowsPerPage(event.target.value);
    };

    const handleCloseDialogPeople = () => {
        setOpenDialogPeople(false);
    }

    async function loadClassProfessor(){
        try {
          let url = `class/student/show/${studentClassId}`;
          const response = await api.get(url);
          
          if(response.status == 200) {  
            setClassProfessor(response.data);
          } else {
            setClassProfessor([]);
          }
          
        } catch (error) {

        }
      }


    const a11yProps = (index) => {
        return {
            id: `nav-tab-${index}`,
            'aria-controls': `nav-tabpanel-${index}`,
        };
    }

    const TabPanel = (props) => {
        const { children, value, index, ...other } = props;
        
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`nav-tabpanel-${index}`}
                aria-labelledby={`nav-tab-${index}`}
                {...other}>
                {value === index && (
                    <Box p={3}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    const LinkTab = (props) => {
        return (
            <Tab
                component="a"
                onClick={(event) => {
                    event.preventDefault();
                }}
                {...props}
            />
        );
    }

    const handleChangeTab = (event, newValue) => {
        localStorage.setItem('@questione/student-class-tab', newValue);
        setTabValue(newValue);
    };

    useEffect(() => {
        if(level_user === '2') {
            loadEvaluations(1);
        }
        loadClassProfessor();
    }, []);

    useEffect(() => {

    }, [opcaoNewApplicationMyEvaluations]);

    const handleNewApplicationExit = () => {
        setOpenNewApplication(false);
        setEvaluationSelected(null);
    }

    const handleExpressSimulationExit = () => {
        setOpenExpressSimulation(false);
    }

    const handleNewTraditionalApplication = (opcao) => {
        setOpcaoNewApplicationMyEvaluations(opcao);
        setOpenNewApplication(true);
        handleClose();
    };

    const handleExpressSimulation = () => {
        setOpenExpressSimulation(true);
    };

    async function expressTestGenerator(){

        try {

            const fk_class_id = studentClassId;
            const data = {
                totalQuestionsToGenerateTest, fk_class_id
            }
            const response = await api.post('adaptive/', data);
            if (response.status === 202) {
                if(response.data.message){
                    toast.error(response.data.message);
                }
            } else {
                toast.success(response.data);
                setOpenExpressSimulation(false);
                window.location.reload();
            }
            setTotalQuestionsToGenerateTest(0);


        } catch (error) {

        }

    }

    async function newApplication(){
        if(evaluationSelected != null){
            try {

                const fk_evaluation_id = evaluationSelected.id;
                const description = evaluationSelected.description + ' (Simulado de '+new Date().toLocaleDateString('pt-BR')+')';
                const fk_class_id = studentClassId;
                const data = {
                    description, fk_evaluation_id, fk_class_id
                }
                const response = await api.post('evaluation/add-application', data);

                if (response.status === 202) {
                    if(response.data.message){
                        toast.error(response.data.message);
                    }
                    setOpenNewApplication(false);
                    setEvaluationSelected(null);
                } else {
                    toast.success('Nova aplicação cadastrada.');
                    setOpenNewApplication(false);
                    setEvaluationSelected(null);
                    window.location.reload();
                }

            } catch (error) {

            }

        }
    }

    const handleChangeTotalQuestions = (event) => {
        setTotalQuestionsToGenerateTest(event.target.value);
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength && isSmallScreen) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    const [code, setCode] = useState(null);

    const handleInputChange = (event) => {
        setCode(event.target.value);

    };

    async function handleSearch() {
        // Verifica se o código é válido
        if (!code || code.trim() === '') {
            toast.error('Informe o código de compartilhamento.');
            return;
        }

        try {
            const data = { share_code: code };

            const response = await api.get('evaluation/get-share-code', { params: data });

            if (response.status === 202) {
                if (response.data.message) {
                    toast.error(response.data.message);
                }
            } else if (response.status === 200) {
                setEvaluationSelected(response.data[0]);
                toast.success('Avaliação encontrada com sucesso!');
            } else {
                // Exibe uma mensagem de erro genérico para status não esperado
                toast.error('Erro inesperado. Por favor, tente novamente.');
            }


        } catch (error) {
            // Exibe uma mensagem de erro para falhas na requisição
            toast.error('Erro ao buscar a avaliação. Por favor, tente novamente.');
            console.error('Erro na requisição:', error);
        }
        setCode(null);
    }


    return (
        <div className={classesGeneral.root}>
            <Box display="flex">
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" href="/">
                        <Box display="flex">
                            <Box style={{marginTop: '2px', marginRight: '5px'}}>
                                <CharmHome/>
                            </Box>
                            <Box>
                                Início
                            </Box>
                        </Box>
                    </Link>
                    {localStorage.getItem('@Questione-acess-level-user') === "2" ?
                        <Link color="inherit" onClick={() => history.push('/student-class/professor')}>
                            {'Turmas'}
                        </Link> :
                        <Link color="inherit" onClick={() => history.push('/student-class/student')}>
                            {'Minhas turmas'}
                        </Link>}

                    <div color="inherit">
                        Turma {classProfessor && classProfessor.id_class}
                    </div>
                </Breadcrumbs>
            </Box>
            <Card className={classes.root}>
                <CardHeader
                    title={
                        <div>
                            <Grid container direction="row" xs={12}>
                                <Grid item xs={12} sm={12} md={7} style={{marginBottom: '20px'}}>
                                    <Box display="flex" alignItems="row">
                                        <Hidden smDown>
                                            <Box>
                                                {/* classProfessor
                                                    && (classProfessor.gamified_class === 1 &&
                                                        <img
                                                            style={{marginTop: '20px', marginRight: '10px'}}
                                                            alt="Logo"
                                                            src="/images/videogame.png" width='100px'/>
                                                    )*/}

                                            </Box>
                                        </Hidden>
                                        <Box>
                                            { classProfessor
                                                &&
                                                <div>
                                                    <div className={classesGeneral.titleList}>
                                                        {"Turma: " + truncateText(classProfessor.description, 10)}
                                                    </div>
                                                    <div className={classesGeneral.paperTitleTextBold}
                                                         style={{fontSize: '15px', marginTop: '30px'}}>
                                                        <i className="fa-solid fa-chalkboard-user"
                                                           style={{marginRight: '10px'}}></i>
                                                        {'Professor(a): ' + truncateText(classProfessor.user.name, 13)}
                                                    </div>
                                                    <div className={classesGeneral.paperTitleTextBold}>
                                                        <i className="fa-regular fa-share-from-square" style={{marginRight: '10px'}}></i>
                                                        {"Código: " + classProfessor.id_class}
                                                    </div>
                                                    {classProfessor.gamified_class === 1 &&
                                                        <div className={classesGeneral.textGreeInfo}
                                                             style={{fontSize: '15px'}}>
                                                            {'Turma gamificada'}
                                                        </div>
                                                    }
                                                    {classProfessor.status === 2 &&
                                                        <div className={classesGeneral.textRedInfo}
                                                             style={{marginTop: '5px', fontSize: '15px'}}>
                                                            {'Turma arquivada'}
                                                        </div>
                                                    }
                                                </div>
                                            }
                                            {/* localStorage.getItem('@Questione-acess-level-user') === "2" &&
                                                <Button style={{marginTop:'20px', marginRight: '10px'}} color="primary" variant='outlined' onClick={handleNewApplication}>Adicionar Simulado</Button>*/}
                                            {/* localStorage.getItem('@Questione-acess-level-user') === "2" &&
                                                <Button style={{marginTop:'20px'}} color="primary" variant='outlined' onClick={handleExpressSimulation}>Simulado Expresso</Button>*/}
                                        </Box>
                                    </Box>
                                </Grid>
                                { classProfessor && (classProfessor.gamified_class === 1
                                        && localStorage.getItem('@Questione-acess-level-user') === "0") &&
                                <Grid item xs={12} sm={12} md={5}>
                                        <GamificationPanel gamified_class={classProfessor.gamified_class} classId={studentClassId}/>
                                </Grid> }
                            </Grid>
                            <Dialog fullScreen={opcaoNewApplicationMyEvaluations || evaluationSelected ? true : false}
                                    width={'500px'}
                                    height={opcaoNewApplicationMyEvaluations ? '80vh' : '30vh'}
                                    onClose={handleNewApplicationExit}
                                    aria-labelledby="responsive-dialog-title" open={openNewApplication}>
                                <AppBar className={classes.appBar}>
                                    <Toolbar>
                                        <IconButton edge="start" color="inherit" onClick={handleNewApplicationExit} aria-label="close">
                                            <CloseIcon />
                                        </IconButton>
                                        <div className={classesGeneral.titleList} style={{color: '#FFF', marginBottom: '15px'}}>
                                            {evaluationSelected != null ? 'Crie um simulado a partir da avaliação '+evaluationSelected.id : opcaoNewApplicationMyEvaluations ? 'Selecione a avaliação' : 'Informe o código da avaliação'}
                                        </div>

                                    </Toolbar>
                                </AppBar>

                                {/* não foi selecionada a avaliação*/}
                                {evaluationSelected == null && evaluations == null ?
                                    <LinearProgress color="secondary" />
                                    :
                                    evaluationSelected == null && opcaoNewApplicationMyEvaluations === true &&
                                    <div style={{margin: '10px'}}>
                                        <TablePagination
                                            component="div"
                                            count={totalEvaluations}
                                            onChangePage={handlePageChangeEvaluations}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[10]}
                                        />
                                        <div style={{textAlign: 'center',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#333',
                                            backgroundColor: '#e3f2fd',  // Azul claro
                                            padding: '10px',
                                            borderRadius: '4px'}}>
                                            Selecione na sua lista de avaliações:
                                        </div>
                                        <List>
                                            {evaluations.map(evaluation => (
                                                <div>
                                                    <Divider/>
                                                    <ListItem button onClick={() => setEvaluationSelected(evaluation)}>
                                                        <ListItemText id={evaluation.id} primary={
                                                            <div className={classesGeneral.paperTitleTextBold}
                                                                 style={{marginLeft: '10px'}}>
                                                                {evaluation.description}
                                                            </div>}

                                                                      secondary={
                                                                          <div
                                                                              className={classesGeneral.paperTitleText}>
                                                                              {'Esta avaliação foi criada em: ' + moment(evaluation.created_at).format('DD/MM/YYYY') + '.'}
                                                                          </div>}/>
                                                    </ListItem>
                                                    <Divider/>
                                                </div>

                                            ))}

                                        </List>
                                        <TablePagination
                                            component="div"
                                            count={totalEvaluations}
                                            onChangePage={handlePageChangeEvaluations}
                                            onChangeRowsPerPage={handleRowsPerPageChange}
                                            page={page}
                                            rowsPerPage={rowsPerPage}
                                            rowsPerPageOptions={[10]}
                                        />
                                    </div>}
                                {evaluationSelected == null && opcaoNewApplicationMyEvaluations === false ?
                                    <div>
                                        {/* Seção de Busca */}
                                        <div style={{
                                            marginTop: '20px',
                                            fontSize: '16px',
                                            fontWeight: 'bold',
                                            color: '#333',
                                            //backgroundColor: '#e3f2fd',  // Azul claro
                                            padding: '10px',
                                            borderRadius: '4px'
                                        }}>

                                            <Grid container spacing={2} alignItems="center" justifyContent="center"
                                                  style={{marginTop: '10px'}}>
                                                <Grid item xs={12} md={6}>
                                                    <TextField
                                                        label="Código da avaliação"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={code}
                                                        onChange={handleInputChange}
                                                        InputProps={{
                                                            style: {
                                                                backgroundColor: '#f5f5f5',
                                                            }
                                                        }}
                                                        InputLabelProps={{
                                                            style: {
                                                                color: '#888'
                                                            }
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        fullWidth
                                                        onClick={handleSearch}
                                                        startIcon={<FontAwesomeIcon icon={faSearch}/>}>
                                                        Buscar
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div> :  null
                                }

                                {(evaluationSelected != null && level_user === '2') &&
                                    <div style={{margin: '10px'}}>
                                    <Box display={'flex'} justifyContent='center' style={{marginTop: '20px', marginBottom: '30px'}}>
                                            <Button variant="outlined" color="primary" size="medium" onClick={newApplication}>
                                                Criar um Simulado a partir da avaliação {evaluationSelected.id}
                                            </Button>
                                        </Box>
                                        <EvaluationQuestions evaluationId={evaluationSelected.id}/>
                                    </div>
                                }
                            </Dialog>
                            <Dialog onClose={handleExpressSimulationExit} aria-labelledby="customized-dialog-title" open={openExpressSimulation}>
                                <DialogTitle id="customized-dialog-title" >
                                    Simulado Expresso
                                </DialogTitle>
                                <DialogContent dividers>
                                    <Typography gutterBottom>
                                        "Simulado Expresso" é uma abordagem que permite a criação rápida, eficiente e direta de simulados.
                                        O simulado resultante é configurado com um número total de questões determinado pelo professor e
                                        inclui perguntas previamente utilizadas em avaliações do Enade para o curso específico cadastrado
                                        na turma.
                                    </Typography>
                                    <Typography gutterBottom>
                                        O algoritmo de seleção das questões considera a média das dificuldades e o total médio de conteúdos
                                        das últimas provas, buscando equilibrar esses dois critérios para gerar a avaliação final.
                                    </Typography>
                                    <Typography gutterBottom>
                                        A cada execução do algoritmo, as questões podem ser alteradas, pois o algoritmo emprega critérios
                                        randômicos para tomar algumas decisões.
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        label="Total de questões"
                                        margin="dense"
                                        name="description"
                                        variant="outlined"
                                        onChange={handleChangeTotalQuestions}
                                        value={totalQuestionsToGenerateTest}
                                        className={classes.fieldsDialog}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button autoFocus onClick={expressTestGenerator} color="primary">
                                        Gerar simulado
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                    }
                />
            </Card>

            <Container className={classes.root}>
                <Tabs
                    value={tabIndex}
                    onChange={handleTabChange}
                    centered
                    indicatorColor="primary"
                    textColor="primary">
                    {level_user === '2' && <Tab label="Simulados" />}
                    {level_user === '0' && <Tab label="Simulados" />}
                    {level_user === '2' && <Tab label="Visão Geral" />}
                    <Tab label="Pessoas" />
                </Tabs>

                <Box p={3}>
                    {tabIndex === 0 && level_user === '2' && (
                        <div>
                            {localStorage.getItem('@Questione-acess-level-user') === "2" &&
                                <div>
                                    <Button
                                        style={{marginTop: '10px'}}
                                        color="secondary"
                                        variant='contained'
                                        onClick={handleClick} // Abre o menu ao clicar
                                        startIcon={<AddIcon/>}
                                    >
                                        Adicionar
                                    </Button>
                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)} // O menu é exibido se houver um âncora
                                        onClose={handleClose} // Fecha o menu se clicar fora
                                    >
                                        <MenuItem onClick={() => handleNewTraditionalApplication(true)}>A partir das minhas avaliações</MenuItem>
                                        <MenuItem onClick={() => handleNewTraditionalApplication(false)}>A partir de um código</MenuItem>
                                        {parseInt(localStorage.getItem("@Questione-id-user")) === 2 && <MenuItem onClick={handleAdaptive}>Criar um Simulado Adaptativo</MenuItem>}
                                    </Menu>

                                    <AdaptiveEvaluationModal
                                        open={openModalAdaptive}
                                        onClose={handleCloseModalAdaptive}
                                        handleSubmit={handleSubmitAdaptive}
                                        class_id={studentClassId}
                                    />
                                </div>}
                            <ApplicationTable studentClassId={studentClassId}/>
                        </div>
                    )}
                    {tabIndex === 0 && level_user === '0' && (
                        <div>
                            <ApplicationListStudent studentClassId={studentClassId}/>
                        </div>
                    )}

                    {tabIndex == 1 && level_user === '2' && (
                        <ResultsGeneral studentClassId={studentClassId}/>
                    )}

                    {tabIndex == 1 && level_user === '0' && (
                        <People idClass={classProfessor.id}/>
                    )}

                    {tabIndex == 2 && level_user === '2' && (
                        <People idClass={classProfessor.id}/>
                    )}
                </Box>
            </Container>
        </div>
    );
}

export default StudentClassContent;
