import React from 'react';
import { Box, Grid, Chip } from '@material-ui/core';
import useStyles from "../../style/style";

;

// Estilos gerais (substitua por seus estilos reais)
const useStylesGeneral = {
    paperTitleTextBold: {
        fontWeight: 'bold',
        fontSize: '16px',
    },
    paperTitleText: {
        fontSize: '14px',
    },
};

const alternativeLetters = ['a', 'b', 'c', 'd', 'e'];
const QuestionAnswers = ({ question }) => {
    const classesGeneral = useStyles();

    if (question.question_items.length > 0) {
        const firstItem = question.question_items[0];

        if (firstItem.total_answer_item >= 0) {
            return (
                <div>
                    <Box display="flex" style={{ marginTop: '20px' }}>
                        <div className={classesGeneral.paperTitleTextBold}>
                            {'Respostas:'}
                        </div>
                    </Box>
                    <Grid container spacing={1}>
                        {question.question_items.map((item, i) => (
                            <div>
                                <Grid item xs={12} sm={12} md={12} lg={12} key={i} style={{marginRight: '10px'}}>
                                    {item.correct_item == 1 ?
                                        <div>
                                            <Box display="flex" flexDirection="row"  style={{ width: '100%' }}>
                                                <Box display='flex' style={{marginTop: '15px', marginRight: '5px'}} sx={{ flexShrink: 1 }}>
                                                    <Chip label={alternativeLetters[i]} style={{fontSize: '14px', fontWeight: item.total_answer_item > 0 && 'bold', background:"#e2f2e7"}} size="small"/>
                                                    <div className={classesGeneral.paperTitleText} style={{fontWeight: item.total_answer_item > 0 && 'bold', marginLeft: '10px'}}>
                                                        {item.total_answer_item+' ('+item.percentage_answer+'%)'}
                                                    </div>
                                                </Box>
                                            </Box>
                                        </div>
                                        :
                                        <div>
                                            <Box display="flex" flexDirection="row" style={{ width: '100%' }}>
                                                <Box display='flex' style={{marginTop: '15px', marginRight: '5px'}}>
                                                    <Chip label={alternativeLetters[i]} style={{fontSize: '14px', fontWeight: item.total_answer_item > 0 && 'bold', background:"#ef9a9a"}} size="small"/>
                                                    <div className={classesGeneral.paperTitleText} style={{fontWeight: item.total_answer_item > 0 && 'bold', marginLeft: '10px'}}>
                                                        {item.total_answer_item+' ('+item.percentage_answer+'%)'}
                                                    </div>
                                                </Box>
                                            </Box>
                                        </div>}
                                </Grid>
                            </div>
                        ))}
                    </Grid>
                </div>
            );
        }
    }

    return null;
};

export default QuestionAnswers;
