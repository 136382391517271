import React, {useEffect, useState} from 'react';
import {
  Table,
  TableBody,
  LinearProgress,
  TablePagination, Grid
} from '@material-ui/core';
import api from '../../../../services/api';
import PropTypes from "prop-types";
import useStyles from "../../../../style/style";
import QuestionsBankCard from "../QuestionsBankCard";
import QuestionsBankItensToolbar from "./QuestionsBankItensToolbar";
import QuestionCard from "../../../../components/QuestionCard/QuestionCard";
import AlertMessage from "../../../../components/Messages/AlertMessage";

const QuestionsBankTable = props => {
  const { className, history } = props;

  const { idBank } = props.match.params;

  const [questionsBank, setQuestionsBank] = useState(null);

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(8);
  const [total, setTotal] = useState(0);
  const [searchText, setSearchText] = useState([]);
  const [refresh, setRefresh] = React.useState(0);
  const [page, setPage] = useState(0);

  //filtro de questões
  const [courseSelect, setCourseSelect] = useState(0);
  const [objectSelect, setObjectSelect] = useState(0);
  const [typeEvaluationSelect, setTypeEvaluationSelect] = useState(0);
  const [yearSelect, setYearSelect] = useState(0);
  const [filterByUser, setFilterByUser] = useState(0);
  const [questionsPerPageSelect, setQuestionsPerPageSelect] = useState(0);
  const [objectDecompositionSelect, setObjectDecompositionSelect] = useState(0);


  async function loadQuestionsItensBank(page){
    try {
      let url = 'questions-bank/questions/'+idBank+'?page='+page;

      if(filterByUser){
        url += '&user=S';
      } else {
        url += '&user=T';
      }

      if(courseSelect && courseSelect > 0){
        url += '&fk_course_id='+courseSelect;
      }

      if(objectSelect && objectSelect > 0){
        url += '&fk_object_id='+objectSelect;
      }

      if(typeEvaluationSelect && typeEvaluationSelect > 0){
        url += '&fk_type_of_evaluation_id='+typeEvaluationSelect;

      }

      if(yearSelect && yearSelect > 0){
        url += '&year='+yearSelect;
      }

      if(questionsPerPageSelect > 0){
        url += '&totalPerPage='+questionsPerPageSelect;
      }

      if(idBank > 0){
        url += '&fk_bank_id='+idBank;
      }

      if(objectDecompositionSelect > 0){
        url += '&fk_object_decomposition_id='+objectDecompositionSelect;
      }

      const response = await api.get(url);
      if(response.status == 200) {
        setTotal(response.data.total);
        setRowsPerPage(response.data.per_page);
        setQuestionsBank(response.data.data);
      } else {
        setQuestionsBank([]);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    loadQuestionsItensBank(1);
  }, [refresh]);

  const updateSearch = (e) => {
    setSearchText(e.target.value);
  }

  useEffect(() => {
    loadQuestionsItensBank(1);

  }, [
      courseSelect,
      objectSelect,
      objectDecompositionSelect,
      typeEvaluationSelect,
      yearSelect,
      filterByUser,
      questionsPerPageSelect]);

  const onClickSearch = (
      questionBankSelect,
      courseSelect,
      objectSelect,
      objectDecompositionSelect,
      typeEvaluationSelect,
      yearSelect,
      filterByUser,
      questionsPerPageSelect)=> {

    setPage(0);
    setCourseSelect(courseSelect);
    setObjectSelect(objectSelect);
    setObjectDecompositionSelect(objectDecompositionSelect);
    setTypeEvaluationSelect(typeEvaluationSelect);
    setYearSelect(yearSelect);
    setQuestionsPerPageSelect(questionsPerPageSelect);
    setFilterByUser(filterByUser);
  }

  const handlePageChange = (event, newPage) => {
    loadQuestionsItensBank(newPage+1, idBank);
    setPage(newPage);
    //searchQuestionsPage(newPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  return (
      <div className={classes.root}>
        <QuestionsBankItensToolbar
              searchText={searchText}
              onClickSearch={onClickSearch}
              idBank={idBank}
              setRefresh={setRefresh}/>
        <div className={classes.content}>

          <TablePagination
              component="div"
              count={total}
              onChangePage={handlePageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}/>

          {questionsBank == null ?
              <LinearProgress color="secondary"/>
              : questionsBank.length == 0 ?
                  <AlertMessage message="Nenhum resultado foi encontrado para sua consulta."/>
                  :
                  <Grid
                      container
                      spacing={1}>
                    <Grid
                        item
                        md={12}
                        xs={12}>
                      <Table>
                        <TableBody>
                          {questionsBank.map(question => (
                              <div style={{marginBottom: '20px'}}>
                                <QuestionCard
                                    question={question}
                                    setRefresh={setRefresh}
                                    refresh={refresh}
                                    id_course={0}
                                    idBank={idBank}/>
                              </div>
                          ))}
                        </TableBody>
                      </Table>
                    </Grid>
                  </Grid>}
          <TablePagination
              component="div"
              count={total}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              page={page}
              rowsPerPage={rowsPerPage}
              rowsPerPageOptions={[rowsPerPage]}/>
        </div>
      </div>
  );
};

QuestionsBankTable.propTypes = {
  history: PropTypes.object
};

export default QuestionsBankTable;
