import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Estilos usando makeStyles
const useStyles = makeStyles(() => ({
    notification: {
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        height: '60px',
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: '#f1c40f', // Amarelo brilhante
        color: '#2c3e50', // Texto escuro para contraste
        padding: '10px 10px',
        borderRadius: '25px', // Bordas arredondadas
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        zIndex: 1000,
        opacity: 0,
        transition: 'opacity 0.5s, transform 0.5s',
        fontSize: '16px',
        fontWeight: 'bold',
        textAlign: 'center',
        border: '1px solid #000',
    },
    notificationVisible: {
        opacity: 1,
        transform: 'translateY(0)', // Move para dentro da tela
    },
}));

function Notification({ message, setMessage, duration = 2000 }) {
    const classes = useStyles();
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (message) {
            setVisible(true);
            const timer = setTimeout(() => {
                setMessage('');
                setVisible(false);
            }, duration);

            return () => clearTimeout(timer); // Limpa o timer se o componente for desmontado
        }
    }, [message, duration]);

    return (
        <div className={`${classes.notification} ${visible ? classes.notificationVisible : ''}`}>
            {message}
        </div>
    );
}

export default Notification;
